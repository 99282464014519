/* eslint-disable */

import { utility } from "../../../utility/utilityProvider";

//to show item stored in array on collection
export const ArrayCell = (props) => {

  const field = props?.field || "";
  const value = props?.dataItem[field];
  var displayValue = '';

  if(props?.format.trim() == 'length'){
    displayValue =  value ? value.length : 0
  } else if(value == undefined || value.length == 0){
    displayValue = '';
  } else{
    //Creating string works only for description key value
    value?.map((obj, index) => {
      if(obj != undefined){
        displayValue = displayValue + `${obj[props?.format]}` + (index == value?.length -1 ?  "" : ", ");
      }else{
        displayValue = ''
      }
    })
  }
  
  return <td>
    <div style={{height : '12px'}}>
    {utility.subString(displayValue, 30)}
    </div>
  </td>;
};