export const OttAssetColumns = [
    
        {
 
            "name": "SID",
            "type": "hidden",
            "label": "S I D",
            "required": 0,
            "endpoint": null,
            "entityname": "OttAsset",
            "sequence": 99,
            "maxLength": null,
            "format": null,
            "width": null,
            "displaymember": null
        },
        {
            
            "name": "PotraitImageUrl",
            "type": "image",
            "label": "Potrait Image",
            "required": 0,
            "endpoint": null,
            "entityname": "OttAsset",
            "sequence": 1,
            "maxLength": 200,
            "format": null,
            "width": 130,
            "displaymember": null
        },
        {
            
            "name": "LandscapeImageUrl",
            "type": "image",
            "label": "Landscape Image ",
            "required": 0,
            "endpoint": null,
            "entityname": "OttAsset",
            "sequence": 2,
            "maxLength": 200,
            "format": null,
            "width": 210,
            "displaymember": null
        },
        {
            
            "name": "Title",
            "type": "input",
            "label": "Title",
            "required": 0,
            "endpoint": null,
            "entityname": "OttAsset",
            "sequence": 3,
            "maxLength": 200,
            "format": null,
            "width": null,
            "displaymember": null
        },
        {
            
            "name": "Description",
            "type": "input",
            "label": "Description",
            "required": 0,
            "endpoint": null,
            "entityname": "OttAsset",
            "sequence": 9,
            "maxLength": 200,
            "format": null,
            "width": null,
            "displaymember": null
        },
        {
            
            "name": "OttAssetTypeSID",
            "type": "select",
            "label": "Asset Type",
            "required": 0,
            "endpoint": null,
            "entityname": "OttAsset",
            "sequence": 10,
            "maxLength": null,
            "format": null,
            "width": null,
            "displaymember": null
        },
        {
            
            "name": "VideoPlaybackUrl",
            "type": "input",
            "label": "Video Playback Url",
            "required": 0,
            "endpoint": null,
            "entityname": "OttAsset",
            "sequence": 8,
            "maxLength": 200,
            "format": null,
            "width": null,
            "displaymember": null
        },
]

export const OttPosterCollectionSelectionColumns = [
    {
        "name": "Archive",
        "type": "checkbox",
        "label": "Archive",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 1,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "ImageUrl",
        "type": "image",
        "label": "Image Url",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 2,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 3,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Title",
        "type": "input",
        "label": "Title",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 4,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "FilePath",
        "type": "input",
        "label": "File Path",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 4,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
]

export const OttAssetColumnsForDraggableCollection = [
    
    {

        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        
        "name": "Title",
        "type": "input",
        "label": "Title",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 3,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        
        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 9,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
            
        "name": "PotraitImageUrl",
        "type": "image",
        "label": "Potrait Image",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 1,
        "maxLength": 130,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        
        "name": "LandscapeImageUrl",
        "type": "image",
        "label": "Landscape Image ",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 2,
        "maxLength": 210,
        "format": null,
        "width": null,
        "displaymember": null
    },
   
    {
            
        "name": "OttAssetTypeSID",
        "type": "select",
        "label": "Asset Type",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 10,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
]

export const RailsColumns = [
        {
            "name": "SID",
            "type": "hidden",
            "label": "S I D",
            "required": 0,
            "endpoint": null,
            "entityname": "Rails",
            "sequence": 99,
            "maxLength": null,
            "format": null,
            "width": null,
            "displaymember": null
        },
        {
            "name": "Publish",
            "type": "toggle",
            "label": "Publish",
            "required": 1,
            "endpoint": null,
            "entityname": "Rails",
            "sequence": 99,
            "maxLength": null,
            "format": null,
            "width": null,
            "displaymember": null
        },        
        {
            "name": "Title",
            "type": "input",
            "label": "Title",
            "required": 0,
            "endpoint": null,
            "entityname": "Rails",
            "sequence": 1,
            "maxLength": 200,
            "format": null,
            "width": null,
            "displaymember": null
        },
        {
            "name": "DisplayTitle",
            "type": "input",
            "label": "Display Title",
            "required": 0,
            "endpoint": null,
            "entityname": "Rails",
            "sequence": 5,
            "maxLength": 200,
            "format": null,
            "width": null,
            "displaymember": null
        },
        {
            "name": "OttRailTypeSID",
            "type": "select",
            "label": "Type",
            "required": 0,
            "endpoint": null,
            "entityname": "Rails",
            "sequence": 6,
            "maxLength": null,
            "format": null,
            "width": null,
            "displaymember": null
        }
];

export const OttPosterColumns = [
    {
        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Title",
        "type": "input",
        "label": "Title",
        "required": 1,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 1,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 0,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 2,
        "maxLength": 10000,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "FilePath",
        //changed
        "type": "hidden",
        "label": "File Path",
        "required": 1,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 3,
        "maxLength": 1000,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "ImageUrl",
        //changed
        "type": "input",
        "label": "Image Url",
        "required": 1,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 4,
        "maxLength": 1000,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "OttPosterTypeSID",
        "type": "select",
        "label": "Poster Type",
        "required": 0,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 5,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Archive",
        //changed
        "type": "checkbox",
        "label": "Archive",
        "required": 0,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 6,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
];

export const UserColumns =[
    {
    
        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null

    },
    {
        "name": "name",
        "type": "input",
        "label": "Name",
        "required": 1,
        "endpoint": null,
        "entityname": "users",
        "sequence": 1,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null        
    },
    {
        "name": "userName",
        "type": "input",
        "label": "User Name",
        "required": 1,
        "endpoint": null,
        "entityname": "users",
        "sequence": 2,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null        
    },
    {
        "name": "DepartmentSID",
        "type": "select",
        "label": "Department",
        "required": 1,
        "endpoint": null,
        "entityname": "users",
        "sequence": 3,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null        
    },
    {
        "name": "RoleSID",
        "type": "select",
        "label": "Role",
        "required": 1,
        "endpoint": null,
        "entityname": "users",
        "sequence": 4,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null        
    },
    {
        "name": "PhoneNo",
        "type": "input",
        "label": "Phone No",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 5,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null        
    },
    {
        "name": "Email",
        "type": "input",
        "label": "Email",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 6,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null        
    },
    {
        "name": "Address",
        "type": "input",
        "label": "Address",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 7,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null        
    },
    {
        "name": "usersSID",
        "type": "select",
        "label": "Reporting To",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 8,
        "maxLength": null,
        "format": null,
        "width": null,      
        "displaymember": null   
    },
    {
        "name": "Archive",
        "type": "hidden",
        "label": "Archive",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 9,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    
];

export const ContentCategoryColumns =[
    {
    
        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "ContentCategory",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null

    },
    {
        "name":"Description",
        "type":"input",
        "label":"Description",
        "required": 1,
        "endpoint": null,
        "entityname": "ContentCategory",
        "sequence": 1,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name":"ContentCategorySID",
        "type":"select",
        "label":"Parent Content",
        "required": 0,
        "endpoint": null,
        "entityname": "ContentCategory",
        "sequence": 2,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Archive",
        "type": "checkbox",
        "label": "Archive",
        "required": 0,
        "endpoint": null,
        "entityname": "ContentCategory",
        "sequence": 3,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
];

export const MediaEpisodeColumns = [
    {
        "name": "SID",
        "label": "S I D",
        "sequence": 99,
        "required": false,
        "type": "hidden",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Title",
        "label": "Title",
        "sequence": 1,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": 250,
        "format": null,
        "width": null
    },
    {
        "name": "Duration",
        "label": "Duration",
        "sequence": 2,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Content",
        "label": "Content",
        "sequence": 3,
        "required": true,
        "type": "select",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null,
        "endpoint": "Content"
    },
    {
        "name": "AssetId",
        "label": "Asset Id",
        "sequence": 4,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": 20,
        "format": null,
        "width": null
    },
    {
        "name": "EpisodeNumber",
        "label": "Episode Number",
        "sequence": 5,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "MediaCategory",
        "label": "Media Category",
        "sequence": 6,
        "required": true,
        "type": "select",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null,
        "endpoint": "MediaCategory"
    },
    {
        "name": "MediaCategoryType",
        "label": "Media Category Type",
        "sequence": 7,
        "required": true,
        "type": "select",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null,
        "endpoint": "MediaCategoryType"
    },
    {
        "name": "Archive",
        "label": "Archive",
        "sequence": 13,
        "required": false,
        "type": "checkbox",
        "entityName": "MediaEpisode",
        "maxLength": 1,
        "format": null,
        "width": null
    }
]

export const PlaningMediaLibraryColumns = [
    {
        "name": "Title",
        "label": "Title",
        "sequence": 1,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": 250,
        "format": null,
        "width": 200
    },
    {
        "name": "AssetId",
        "label": "Asset Id",
        "sequence": 4,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": 20,
        "format": null,
        "width": 200
    },
    {
        "name": "Duration",
        "label": "Duration",
        "sequence": 2,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": 200
    },
    {
        "name": "EpisodeNumber",
        "label": "Episode Number",
        "sequence": 5,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": 200
    },
    {
        "name": "MediaCategory.Description",
        "label": "Media Category",
        "sequence": 6,
        "required": true,
        "type": "select",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": 200,
        "endpoint": "MediaCategory"
    }
]

export const MediaEpisodeSynopsisColumns = [
    {
        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "MediaEpisodeSynopsis",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "LanguageSID",
        "type": "select",
        "label": "Language",
        "required": 1,
        "endpoint": "Language",
        "entityname": "MediaEpisodeSynopsis",
        "sequence": 2,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Synopsis",
        "type": "input",
        "label": "Synopsis",
        "required": 1,
        "endpoint": null,
        "entityname": "MediaEpisodeSynopsis",
        "sequence": 3,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
]

export const SegmentDetailsColumns = [
    {
        "name": "SegmentTypeSID",
        "label": "Segment Type",
        "sequence": 3,
        "required": true,
        "type": "select",
        "entityName": "mediaepisodesegment",
        "maxLength": null,
        "format": null,
        "width": null,
        "endpoint": "SegmentType"
    },
    {
        "name": "ActualDuration",
        "label": "Actual Duration",
        "sequence": 4,
        "required": true,
        "type": "time",
        "entityName": "mediaepisodesegment",
        "maxLength": null,
        "format": null,
        "width": null
    },
]

export const CampaignColumns = [
    {
        "name": "_id",
        "label": "_id",
        "sequence": 99,
        "required": false,
        "type": "hidden",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Title",
        "label": "Title",
        "sequence": 2,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "CampaignNo",
        "label": "Campaign No",
        "sequence": 1,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
        {
            "name": "TargetSpots",
            "label": "Target Spots",
            "sequence": 8,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        
        {
            "name": "PeriodTo",
            "label": "Period To",
            "sequence": 5,
            "required": false,
            "type": "date",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "OrderDate",
            "label": "Order Date",
            "sequence": 3,
            "required": false,
            "type": "date",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        
        {
            "name": "Agency.Name",
            "label": "Agency",
            "sequence": 11,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "Product.Name",
            "label": "Product",
            "sequence": 15,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "TargetSeconds",
            "label": "Target Seconds",
            "sequence": 9,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        
        {
            "name": "DealType.Description",
            "label": "Deal Type",
            "sequence": 16,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "PeriodFrom",
            "label": "Period From",
            "sequence": 4,
            "required": false,
            "type": "date",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "Commission",
            "label": "Commission",
            "sequence": 10,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null,
        },
        {
            "name": "Brands",
            "label": "Brands",
            "sequence": 14,
            "required": false,
            "type": "array",
            "entityName": "campaign",
            "maxLength": null,
            "format": "Name",
            "width": null
        },
        {
            "name": "Currency.Description",
            "label": "Currency",
            "sequence": 13,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "TargetBudget",
            "label": "Target Budget",
            "sequence": 7,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "Client.Name",
            "label": "Client",
            "sequence": 12,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "CampaignType.Description",
            "label": "Campaign Type",
            "sequence": 6,
            "required": false,
            "type": "input",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        },
        {
            "name": "Channels",
            "label": "Channels",
            "sequence": 17,
            "required": false,
            "type": "array",
            "entityName": "campaign",
            "maxLength": null,
            "format": "FullChannelName",
            "width": null
        },
        {
            "name": "Commercials",
            "label": "Commercials",
            "sequence": 18,
            "required": false,
            "type": "array",
            "entityName": "campaign",
            "maxLength": null,
            "format": null,
            "width": null
        }
]

