/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";

const ModelEditForm = (props) => {

    const blankDataItem = {
        ...props.item,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Description: props.item.Description ?? "",
        Brand: props.item.Brand ?? "",
        Dimensions: props.item.Dimensions ?? "",
        ModelNumber: props.item.ModelNumber ?? "",
        MountingType: props.item.MountingType ?? "",
        Resolution: props.item.Resolution ?? "",
        DisplayShape: props.item.DisplayShape ?? "",
        Brightness: props.item.Brightness ?? "",
        DisplaySize: props.item.DisplaySize ?? "",
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const lang = useTranslation();
    const [brands, setBrands] = useState([]);
    const [resolutions, setResolutions] = useState([]);
    const [displayShapes, setDisplayShapes] = useState([]);

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let brandRes = await API.getDataLookup(ENTITYNAME.DigitalSignBrand, { sort: { Description: 1 } });
        setBrands(brandRes.data);
        let resolutionRes = await API.getDataLookup(ENTITYNAME.Resolution, { sort: { Description: 1 } });
        setResolutions(resolutionRes.data);
        console.log(resolutionRes)
        let displayShapeRes = await API.getDataLookup(ENTITYNAME.DigitalSignDisplayShape, { sort: { Description: 1 } });
        setDisplayShapes(displayShapeRes.data);
    };

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.Brand == undefined || Object.keys(dataItem.Brand).length == 0) {
            toast.error(lang.please_select_brand_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }       
        if (dataItem.Resolution == undefined || Object.keys(dataItem.Resolution).length == 0) {
            toast.error(lang.please_select_resolution_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }        
        return true;
    };

    const handleSubmit = async () => {
        if (!isValid()) return;

        let localData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            Name: dataItem.Name,
            Description: dataItem.Description,
            Brand: {
                _id: dataItem.Brand?._id,
                SID: dataItem.Brand?.SID,
                Description: dataItem.Brand?.Description,
            },
            Dimensions: dataItem.Dimensions,
            ModelNumber: dataItem.ModelNumber,
            MountingType: dataItem.MountingType,
            Resolution: {
                _id: dataItem.Resolution?._id,
                SID: dataItem.Resolution?.SID,
                Description: dataItem.Resolution?.Description,
            },
            DisplayShape: {
                _id: dataItem.DisplayShape?._id,
                SID: dataItem.DisplayShape?.SID,
                Description: dataItem.DisplayShape?.Description,
            },
            Brightness: dataItem.Brightness,
            DisplaySize: dataItem.DisplaySize,
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["Name", "=", dataItem.Name]],
        };
        console.log(localData)

        let res = await API.saveData(ENTITYNAME.DigitalSignModel, localData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.MODEL, data: res.data, message: res.message };
            API.SaveLogs(logData);
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.DigitalSignModel);
            return;
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <>
            <Dialog
                title={props.item.Name ?? lang.create_model_dialog_header}
                onClose={props.cancelEdit}
                width={"60%"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row">
                            <div className="col-12">
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.name_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="Name"
                                            onChange={onChange}
                                            value={dataItem.Name}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.description_label}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="Description"
                                            onChange={onChange}
                                            value={dataItem.Description}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.brand_label} *</label>
                                        <DropDownList
                                            style={{ backgroundColor: "white" }}
                                            data={brands}
                                            name="Brand"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.Brand}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.dimensions_label}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="Dimensions"
                                            onChange={onChange}
                                            value={dataItem.Dimensions}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.model_number_label}</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="ModelNumber"
                                            onChange={onChange}
                                            value={dataItem.ModelNumber}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.mounting_type_label}</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="MountingType"
                                            onChange={onChange}
                                            value={dataItem.MountingType}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.resolution_label} *</label>
                                        <DropDownList
                                            style={{ backgroundColor: "white" }}
                                            data={resolutions}
                                            name="Resolution"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.Resolution}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.display_shape_label}</label>
                                        <DropDownList
                                            style={{ backgroundColor: "white" }}
                                            data={displayShapes}
                                            name="DisplayShape"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.DisplayShape}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.brightness_label}</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="Brightness"
                                            onChange={onChange}
                                            value={dataItem.Brightness}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.display_size_label}</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="DisplaySize"
                                            onChange={onChange}
                                            value={dataItem.DisplaySize}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3 mt-3">
                                        <input
                                            type="checkbox"
                                            name="Archive"
                                            value={dataItem.Archive}
                                            checked={dataItem.Archive}
                                            onChange={onChange}
                                        />
                                        <label htmlFor="TabView" className="pl-2">{lang.archive}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ModelEditForm;