/* eslint-disable */
import React, { useState, useEffect, useRef, memo, forwardRef, useImperativeHandle} from "react";
import * as API from "../../../framework/API/api";
import { ContextMenu } from "primereact/contextmenu";
import { useTranslation } from "../../../locale/useTranslation";
import { ConfirmAlert } from "../../../ConfirmAlert";
import { TreeList, TreeListDraggableRow } from "@progress/kendo-react-treelist";

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const EXPAND_FIELD = "expanded";
const SUB_ITEM_FIELD = "children";
const SELECTED_HEADER = "isHeaderSelected";
const editField = "inEdit";
const ROW_HEIGHT = 40;

const PlaylistMainTree = (props, ref) => {

    const treeListRef = useRef(null);

    const [isPlaylistLoading, _setIsPlaylistLoading] = useState(false);

    const hcm = useRef(); //FOR PLAYLIST RIGHT CLICK ON HEADER: CONTEXT MENU
    const cm = useRef(); //FOR PLAYLIST RIGHT CLICK ON CHILDREN ELEMENT : CONTEXT MENU
    const bc = useRef(); //FOR PLAYLIST break children right Click
    const fg = useRef(); //FOR PLAYLIST filter grid right Click

    const lang = useTranslation();
    const playlistScrollRef = useRef();
    const scrollPosition = useRef(0);

    const [treeListHeight, setTreeListHeight] = useState('100%');

    useEffect(() => {
        if (isPlaylistLoading == false) {
            treeListRef?.current?.element?.addEventListener('scroll', onTreeViewscroll);
        }
        return () => {
            treeListRef?.current?.element?.removeEventListener('scroll', onTreeViewscroll);
        }
    }, [isPlaylistLoading]);

    // on screen RESOLUTION change
    useEffect(() => {
        let divHeight = document.getElementById('content-scroll')?.offsetHeight;
        setTreeListHeight(divHeight + 'px');
        // listen for resize event
        window.addEventListener('resize', () => {
            let divHeight = document.getElementById('content-scroll')?.offsetHeight;
            setTreeListHeight(divHeight + 'px');
        });

        return () => {
            window.removeEventListener('resize', () => {
                let divHeight = document.getElementById('content-scroll')?.offsetHeight;
                setTreeListHeight(divHeight + 'px');
            });
        }
    }, []);

    useEffect(() => {
        loaddata();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            onSearch(e) {
                onSearchLocal(e)
            }
        };
    }, []);

    const onTreeViewscroll = (e) => {
        scrollPosition.current = e.target.scrollTop;
    }

    const loaddata = async (isFromSocket = false) => {
        // Write code loaddata
    };

    const onSearchLocal = (e) => {
        const searchWord = e.target.value.toLowerCase();
        console.log(searchWord);
    }

    const onTreeExpandChange = (event) => {
        const expanded = !event.value;
        const tree = [...playlistDataRef.current];
        const itemId = event.dataItem._id;

        const updatedTree = tree.map((item) => {
            if (item._id === itemId) {
                return {
                    ...item,
                    [EXPAND_FIELD]: expanded,
                };
            } else {
                if (item.children) {
                    item.children = item.children.map((child) => {
                        if (child._id === itemId) {
                            return {
                                ...child,
                                [EXPAND_FIELD]: expanded,
                            };
                        }
                        return child;
                    });
                }
            }
            return item;
        });

        playlistDataRefSetter(updatedTree);
    };

    const GridRowRenderTreeList = (props) => {
        // for row styling
        let newProps = {
            ...props, children: props.children.map((d, i) => {
                return React.cloneElement(d, {
                    ...d.props,
                    style: !props.dataItem[SELECTED_FIELD] ? { ...d.props.dataItem.Style, ...d.props.style } : {},
                }, d.props.children)
            })
        }
        return <TreeListDraggableRow  {...newProps} />
    }

    const onColumnResize = (event) => {
        if (treeListRef) {
            treeListRef.current.element.style.width = `100%`;
        }
    };


    return (
        <div style={{ height: "100%" }}>
            {
                isPlaylistLoading ? <div className="ml-2 mt-2">
                    <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
                </div> :
                    <div id="content-scroll" ref={playlistScrollRef} className="content-scroll" style={{ height: "100%", overflow: "hidden" }} >
                        <TreeList
                            ref={treeListRef}
                            style={{
                                height: treeListHeight,
                                overflow: "auto",
                                width: "100%",
                                userSelect: "none"
                            }}
                            resizable={true}
                            rowHeight={ROW_HEIGHT}
                            dataItemKey={DATA_ITEM_KEY}
                            tableProps={{
                                style: {
                                    tableLayout: "fixed",
                                },
                                onDragOver: (e) => {
                                    e.preventDefault();
                                },
                                onContextMenu: (e) => {
                                    e.preventDefault();
                                    console.log(e);
                                },
                            }}
                            scrollable="virtual"
                            expandField={EXPAND_FIELD}
                            selectedField={SELECTED_FIELD}
                            subItemsField={SUB_ITEM_FIELD}
                            onExpandChange={onTreeExpandChange}
                            columns={[{
                                key: Math.random(),
                                width: '30px',
                                field: '',
                                title: '',
                                resizable: false,
                                cell: (props) => {}
                            }]}
                            row={GridRowRenderTreeList}
                            selectable={{
                                enabled: true,
                                // drag: false,
                                // cell: false,
                                mode: 'single',
                            }}
                            onColumnResize={onColumnResize}
                        />

                        <ContextMenu
                            ref={fg}
                            model={[
                            ]}
                        />
                    </div>
            }
        </div>
    );
};

export default memo(forwardRef(PlaylistMainTree));
