/* eslint-disable */
import React, { useEffect, useState } from "react";
import { TreeView } from "@progress/kendo-react-treeview";
import { useTranslation } from "../../../locale/useTranslation";
import * as API from "../../../framework/API/api"
import { toast } from "react-toastify";

const PlaylistNodes = (props) => {

    const lang = useTranslation();
    const [tree, setTree] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const res = await API.getNodes();
        console.log(res);
        if(res.success){
            setTree(res.data);
        }else{
            console.log(res.message);
            toast.error(res.message);
        }

    }

    const onExpandChange = (event) => {
        event.item.expanded = !event.item.expanded;
        setTree([...tree])
    };

    const onClickNodesItem = (event) => {
        console.log(event.item);
        props?.selectedNodeItem(event.item)
    }

    return (
        <div style={{ margin: '0px 0px 0px 15px', height: '95%', overflow: 'auto' }}>
            <div>
                <h1 className="page-title txt-color-bludeDark">
                    <i
                        className="fa fa-table marlr"
                        style={{ fontSize: "20px", color: "GrayText" }}
                    ></i>
                    <span className="h1">{lang.nodes_lable}</span>
                </h1>
            </div>
            <TreeView
                size={"large"}
                data={tree}
                textField="Name"
                childrenField="children"
                expandIcons={true}
                onExpandChange={onExpandChange}
                onItemClick={onClickNodesItem}
            />
        </div>
    );
}
export default PlaylistNodes;