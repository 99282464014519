/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import moment from "moment";

const BoardEditForm = (props) => {

    const blankDataItem = {
        ...props.item,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Description: props.item.Description ?? "",
        WarrantyStartDate:moment(new Date(props.item.WarrantyStartDate )).format('YYYY-MM-DD') ?? moment(new Date()).format('YYYY-MM-DD'),
        WarrantyEndDate:moment(new Date(props.item.WarrantyEndDate)).format('YYYY-MM-DD') ?? moment(new Date()).format('YYYY-MM-DD'),
        SignBoardID: props.item.SignBoardID ?? "",
        Model: props.item.Model ?? {},
        Node: props.item.Node ?? {},
        SerialNumber: props.item.SerialNumber,
        Archive: props.item.Archive ?? false
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const lang = useTranslation();
    const [model, setModel] = useState([]);
    const [node, setNode] = useState([]);
    const [displayShapes, setDisplayShapes] = useState([]);

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let modelRes = await API.getDataLookup(ENTITYNAME.DigitalSignModel, { sort: { Description: 1 } });
        setModel(modelRes.data);
        let nodeRes = await API.getDataLookup(ENTITYNAME.DigitalSignNode, { sort: { Description: 1 } });
        setNode(nodeRes.data);
    };

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (!utility.isValidDate(dataItem.WarrantyStartDate)) {
            toast.error(`${lang.please_select_start_date_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
          if (!utility.isValidDate(dataItem.WarrantyEndDate)) {
            toast.error(`${lang.please_select_end_date_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
          if (dataItem.WarrantyStartDate >= dataItem.WarrantyEndDate) {
            toast.error(`${lang.end_date_should_be_greater_than_start_date_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
        
        if (dataItem.SignBoardID == undefined || dataItem.SignBoardID == "") {
            toast.error(lang.please_enter_sign_board_id_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.Model == undefined || Object.keys(dataItem.Model).length == 0) {
            toast.error(lang.please_select_model_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.SerialNumber == undefined || dataItem.SerialNumber == "") {
            toast.error(lang.please_enter_serial_number_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
       
        return true;
    };

    const handleSubmit = async () => {
        if (!isValid()) return;
        console.log(dataItem)
        let localData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            Name: dataItem.Name,
            Description: dataItem.Description,
            WarrantyStartDate: new Date(dataItem.WarrantyStartDate).getTime(),
            WarrantyEndDate: new Date(dataItem.WarrantyEndDate).getTime(),
            SignBoardID: dataItem.SignBoardID,
            Model: {
                _id: dataItem.Model?._id,
                SID: dataItem.Model?.SID,
                Name: dataItem.Model?.Name,
            },

            Node: {
                _id: dataItem.Node?._id,
                SID: dataItem.Node?.SID,
                Description: dataItem.Node?.Description,
            },
            SerialNumber: dataItem.SerialNumber,
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["SignBoardID", "=", dataItem.SignBoardID], ["SerialNumber", "=", dataItem.SerialNumber]],
        };
        console.log(localData)

        let res = await API.saveData(ENTITYNAME.DigitalSignBoard, localData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.DIGITAL_SIGN_BOARD, data: res.data, message: res.message };
            API.SaveLogs(logData);
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.DigitalSignBoard);
            return;
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <>
            <Dialog
                title={props.item.Name ?? lang.create_board_dialog_header}
                onClose={props.cancelEdit}
                width={"60%"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row">
                            <div className="col-12">
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.name_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="Name"
                                            onChange={onChange}
                                            value={dataItem.Name}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.description_label}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="Description"
                                            onChange={onChange}
                                            value={dataItem.Description}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="">{lang.warranty_start_date_label} *</label>
                                        <input
                                            type="date"
                                            className="form-control form-control-sm"
                                            name="WarrantyStartDate"
                                            value={dataItem.WarrantyStartDate}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="">{lang.warranty_end_date_label} *</label>
                                        <input
                                            type="date"
                                            className="form-control form-control-sm"
                                            name="WarrantyEndDate"
                                            value={dataItem.WarrantyEndDate}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.sign_board_id_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="SignBoardID"
                                            onChange={onChange}
                                            value={dataItem.SignBoardID}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.model_label} *</label>
                                        <DropDownList
                                            style={{ backgroundColor: "white" }}
                                            data={model}
                                            name="Model"
                                            textField="Name"
                                            dataItemKey="_id"
                                            value={dataItem.Model}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.node_label}</label>
                                        <DropDownList
                                            style={{ backgroundColor: "white" }}
                                            data={node}
                                            name="Node"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.Node}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.serial_number_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="SerialNumber"
                                            onChange={onChange}
                                            value={dataItem.SerialNumber}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3 mt-3">
                                        <input
                                            type="checkbox"
                                            name="Archive"
                                            value={dataItem.Archive}
                                            checked={dataItem.Archive}
                                            onChange={onChange}
                                        />
                                        <label htmlFor="TabView" className="pl-2">{lang.archive}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default BoardEditForm;