/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { utility } from "../../../framework/utility/utilityProvider";
import { CardContent, CardMedia } from "@mui/material";
import { ContextMenu } from "primereact/contextmenu";

const LiveEventCard = (props) => {
  const { item, index } = props;

  return (
    <div className="col-sm-12 col-md-6 col-lg-4">
      <div
        style={{
          cursor: "pointer",
          margin: "10px",
          height: "250px",
          minWidth: "180px",
          maxWidth: "300px",
          backgroundColor: "white",
          border: "none",
        }}
        onContextMenu={() => {}}
        onClick={() => {}}
        className="card"
        key={index}
        title={
          // for card tooltip
          item.MediaEpisode?.Title +
          "\n\n" +
          item.MediaEpisode?.Description +
          "\n\n" +
          item?.TeamA +
          "\n" +
          "vs " +
          "\n" +
          item?.TeamB +
          "\n" +
          utility.convertMilisecondsToStringWithFrames(
            item.MediaEpisode?.Duration
          ) +
          "\n\n"
        }
      >
        <card>
          <CardMedia>
            <img src={item?.PosterUrl} className="card-img-top" alt={item?.Name}
              style={{
                height: "150px",
                objectFit: "cover",
                paddingBottom: "5px",
                position: "absolute",
              }}
            />
          </CardMedia>
          <div
            style={{
              position: "absolute",
              color: "white",
              top: 0,
              fontSize: "15px",
              right: "0%",
              backgroundColor: "#048ad4",
              transform: "translateX(0.2%)",
            }}
          >
            &nbsp;{" "}
            {item?.Type.Description == "Live" && (
              <i
                className="fa-solid fa-circle  fa-2xs"
                style={{ color: "red" }}
              />
            )}{" "}
            {item?.Type.Description} &nbsp;
          </div>
        </card>

        <div
          style={{
            display: "grid",
            alignContent: "space-around",
            height: "100%",
            marginTop: "10vh",
          }}
        >
          <p
            className="card-title"
            style={{ fontSize: "small", fontWeight: "200" }}
          ></p>
          <div
            className="card-subtitle mt-1 text-muted"
            style={{ fontSize: "small" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 13px",
                fontSize: "15px",
                fontWeight: "500",
                color: "black",
              }}
            >
              {item.MediaEpisode?.Title}
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px" }}>{item.MediaEpisode?.Description}</div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 13px",
              }}
            >
              {item?.TeamA + "\n" + "V/s " + "\n" + item?.TeamB}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 13px",
                fontSize: "10px",
              }}
            >
              {utility.convertMilisecondsToDateTimeString(item?.StartDateTime)}{" "}
              |{" "}
              {utility.convertMilisecondsToStringWithFrames(
                item.MediaEpisode?.Duration
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 13px",
                fontSize: "10px",
              }}
            >
              {" "}
              {item?.Venue}
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "5px",
              right: "10px",
              padding: "0 7px",
              borderRadius: "5px",
              cursor: "pointer",
              border: "1px solid black",
            }}
            onClick={(e) => props.onContextMenu(e, item)}
          >
            <i className="fa-solid fa-ellipsis" />
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default LiveEventCard;
