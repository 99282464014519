/* eslint-disable */
import { Splitter } from "@progress/kendo-react-layout";
import React, { useEffect, useRef, useState } from "react";
import { ENTITYNAME, LOCALSTORAGE_KEY } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as API from "../../../framework/API/api";
import { toast } from "react-toastify";
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { utility } from "../../../framework/utility/utilityProvider";
import PlaylistMainTree from "./PlaylistMainTree";
import { PlaylistHeader } from "./PlaylistHeader";
import PlaylistFooter from "./PlaylistFooter";
import { PlaylistCollection } from "./PlaylistCollection";
import PlaylistNodes from "./PlaylistNodes";

const PlaylistWrapper = () => {

  const lang = useTranslation();
  const playlistRef = useRef();

  const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
  const offsetTime = offSetHours * 3600000;

  const defaultTabNumber = "1";
  const defaultShowFilter = utility.getSeassionValue(LOCALSTORAGE_KEY.publishData) === undefined || utility.getSeassionValue(LOCALSTORAGE_KEY.publishData) === null;
  let localStoragePlaylistFilterData = utility.getSeassionValue(LOCALSTORAGE_KEY.publishData);

  const [clientInfo, setClientInfo] = useState({});
  const [showFilter, setShowFilter] = useState(defaultShowFilter);
  const [copyPasteDataItem, setCopyPasteDataItem] = useState(0);
  const [selectedState, setSelectedState] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [expandToggle, setExpandToggle] = useState(false);
  const [selectedPlaylistDate, setSelectedPlaylistDate] = useState(
    localStoragePlaylistFilterData
      ? new Date(
        moment(
          new Date(localStoragePlaylistFilterData.SlotDateTime - offsetTime)
        )
          .utc()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      ).getTime()
      : new Date(
        moment(new Date(new Date()))
          .utc()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      ).getTime()
  );

  const [tabNumber, setTabNumber] = useState(defaultTabNumber);
  const [nodesList, setNodesList] = useState([]);
  const [selectedNode, setSelectedNode] = useState({});
  const [doLoad, setDoLoad] = useState(false);
  const isPlaylistLoading = useRef(false);
  const [outSideFocus, _setOutSideFocus] = useState(false);
  const outSideFocusRef = useRef(outSideFocus);
  const setOutSideFocus = (value) => {
    _setOutSideFocus(value);
    outSideFocusRef.current = value;
  }

  const [statusInfo, setStatusInfo] = useState({
    parentCount: 0,
    commercialCount: 0,
    overRunUnderRunCount: 0,
    playlistStartTime: 0,
    playlistEndTime: 0,
  });

  const [playlistData, setPlaylistData] = useState([]);
  const [playlistTimeInfo, setPlaylistTimeInfo] = useState({
    StartDateTime: 0,
    EndDateTime: 0,
    PreviousScheduleEndDateTime: 0,
  });

  const [selectedMedia, setSelectedMedia] = useState([]);

  //splitter variable and onChange function
  const [horizontalPanes, setHorizontalPanes] = React.useState([
    {
      size: "13%",
      collapsible: true,
    },
    {
      size: "63.5%",
      collapsible: true,
    },
    {
      collapsible: true,
    },
  ]);

  useEffect(() => {
    loadClientInfo();
    loadCombo();
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, []);

  const loadCombo = async () => {
    const nodeRes = await API.getDataLookup(ENTITYNAME.DigitalSignNode, { sort: { Name: 1 } });
    setNodesList(nodeRes.data);
  }


  const handleKeyDown = (e) => {
    if (outSideFocusRef.current) return;

    // refresh F5
    if (e.keyCode == 116 || e.key == "F5") {
      e.preventDefault();
      onPlaylistRefresh();
      console.log(`You pressed refresh`);
      return;
    }

    if (e.ctrlKey && e.key == "h") {
      e.preventDefault();
      playlistRef.current.onShowGlobalReplacement();
      console.log(`You pressed find and replace`);
      return;
    }
    // ctrl +
    console.log(e.key)
    if (e.ctrlKey && e.key == "=") {
      e.preventDefault();
      playlistRef.current.onExpand({ target: { value: true } });
      console.log(`You pressed ctrl +`);
      return;
    }
    // ctrl -
    if (e.ctrlKey && e.key == "-") {
      e.preventDefault();
      playlistRef.current.onExpand({ target: { value: false } });
      console.log(`You pressed ctrl -`);
      return;
    }


  }

  const loadClientInfo = async () => {
    const clientInfoRes = await API.getDataLookup(ENTITYNAME.ClientInfo, { sort: { SID: 1 } });
    setClientInfo(clientInfoRes.data[0]);
  }

  const onHorizontalSplitterChange = (event) => {
    setHorizontalPanes(event.newState);
  };

  const handleFilterDialog = () => {
    console.log("coming Here Filter");
    setShowFilter(!showFilter);
  };

  const handlePopupConfirm = async () => {

    if (!selectedNode || selectedNode.SID < 0) {
      toast.error("Please select Node", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    onPlaylistRefresh();
    handleFilterDialog();
    setExpandToggle(false)
  };

  const onPlaylistRefresh = () => {
    setDoLoad(!doLoad);
  };

  const onDateChange = async (isNext) => {
    if (isPlaylistLoading.current) {
      toast.info("Please wait .. Playlist is already loading", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let oneDayMilisecond = 1 * 24 * 60 * 60 * 1000;
    console.log(oneDayMilisecond);
    var newDate = isNext
      ? selectedPlaylistDate + oneDayMilisecond
      : selectedPlaylistDate - oneDayMilisecond;
    setSelectedPlaylistDate(newDate);
    onPlaylistRefresh();
    setExpandToggle(false);
  };

  const onIsPlaylistLoading = (data) => {
    isPlaylistLoading.current = data;
  };

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const setSelectedStateItem = (item) => {
    setSelectedState(item);
    calculateDurationForFooter(item);
  }

  const selectedNodeItem = (item) => {
    // Set selected node
    setSelectedNode(item);
  }

  return (
    <>
      <div className="ScheduleWrapper">
        <PlaylistHeader
          handleFilterDialog={handleFilterDialog}
          onDateChange={onDateChange}
          clientInfo={clientInfo}
          date={new Date(selectedPlaylistDate)}
          selectedNode={selectedNode}
          nodesList={nodesList}
          onPlaylistRefresh={onPlaylistRefresh}
          statusInfo={statusInfo}
          onSearch={(e) => {
            playlistRef.current.onSearch(e);
          }}
          playlistTimeInfo={playlistTimeInfo}
          playlistData={playlistData}
          selectedState={selectedState}
          setOutSideFocus={setOutSideFocus}
          onHeaderExpandChange={(e) => {
            playlistRef.current.onExpand(e);
          }}
          onCut={() => {
            playlistRef.current.onCut();
          }}
          onCopy={() => {
            playlistRef.current.onCopy();
          }}
          onPaste={() => {
            playlistRef.current.onPaste();
          }}
          onDelete={() => {
            playlistRef.current.onDelete();
          }}
        ></PlaylistHeader>

        <Splitter
          className="horizontalSplitter"
          panes={horizontalPanes}
          onChange={onHorizontalSplitterChange}
          style={{ height: '100%' }}>

          {/* Playlist node view */}
          <PlaylistNodes selectedNodeItem={selectedNodeItem}/>
          {/* Playlist main grid */}
          <PlaylistMainTree
            ref={playlistRef}
            date={new Date(selectedPlaylistDate)}
            statusInfo={statusInfo}
            setStatusMessage={setStatusMessage}
            selectedPlaylistDate={selectedPlaylistDate}
            selectedNodeSID={selectedNode?.SID}
            setSelectedState={setSelectedStateItem}
            selectedNode={selectedNode}
            setCopyPasteDataItem={setCopyPasteDataItem}
            doLoad={doLoad}
            outSideFocus={outSideFocus}
            setStatusInfo={setStatusInfo}
            onIsPlaylistLoading={onIsPlaylistLoading}
            playlistTimeInfo={playlistTimeInfo}
            setPlaylistTimeInfo={setPlaylistTimeInfo}
            setPlaylistData={setPlaylistData}
            setExpandToggle={setExpandToggle}
          />
          {/* Playlist Assets */}
          <Box sx={{ width: "97%", typography: "body1" }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  variant="scrollable"
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <Tab icon={<i className="fas fa-clapperboard"></i>} title={lang.media_label} value="1" style={{ minWidth: '60px' }} />
                  <Tab icon={<i className="fas fa-object-group"></i>} title={lang.digital_sign_overlay_label} value="2" style={{ minWidth: '60px' }} />
                </TabList>
              </Box>
              <TabPanel value={"1"}>
                <PlaylistCollection
                  title={lang.assets_dialog_header}
                  entityname={ENTITYNAME.OttAsset}
                  setOutSideFocus={setOutSideFocus}
                  selectedPlaylistDate={selectedPlaylistDate}
                  setSelectedMedia={setSelectedMedia}
                  page_take={20}
                />
              </TabPanel>
              <TabPanel value={"2"}>
                <PlaylistCollection
                  title={lang.assets_dialog_header}
                  entityname={ENTITYNAME.DigitalSignOverlay}
                  setOutSideFocus={setOutSideFocus}
                  selectedPlaylistDate={selectedPlaylistDate}
                  setSelectedMedia={setSelectedMedia}
                  page_take={20}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Splitter>
      </div>

      <PlaylistFooter
        onPreferenceFilter={() => { }}
        copyPasteDataItem={copyPasteDataItem}
        selectedState={selectedState}
        statusInfo={statusInfo}
        statusMessage={statusMessage}
        expandToggle={expandToggle}
        onHeaderExpandChange={(e) => {
          playlistRef.current.onExpand(e);
        }}
        searchWord={""}
        onPlaylistRefresh={onPlaylistRefresh}
        selectedStateDurationCalculation={()=>{}}
      />

      {showFilter && (
        <Dialog
          title={lang.select_channels_dialog_header}
          onClose={handleFilterDialog}
          width={350}
        >
          <div>
            <label>{lang.node_label} :</label>
          </div>

          <div className="mb-3">
            <DropDownList
              data={nodesList}
              textField="Name"
              dataItemKey="SID"
              value={selectedNode}
              onChange={e=>setSelectedNode(e.target.value)}
            />
          </div>
          <div>
            <label>{lang.date_label}:</label>
          </div>
          <div>
            <Calendar
              inline
              // value={new Date(selectedPlaylistDate)}
              value={new Date(utility.convertMilisecondsToDateTimeString(selectedPlaylistDate))}
              onChange={(event) => {
                let utcTime = utility.getLocalDateTimeToUtcMiliseconds(
                  event.value
                );

                console.log(utcTime);
                setSelectedPlaylistDate(utcTime);
              }}
              style={{ width: '100%' }}
            />
          </div>

          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleFilterDialog}
            >
              {lang.cancel_button_text}
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handlePopupConfirm}
            >
              {lang.confirm_button_text}
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default PlaylistWrapper;
