import { SchedulerItem, SchedulerItemContent } from '@progress/kendo-react-scheduler';
import { useCallback } from 'react';
import { COLUMNSTYPE, LOCALSTORAGE_KEY, MEDIACATEGORIES, MODULE, TX_MODE } from '../../framework/constant/constant';
import { utility } from '../../framework/utility/utilityProvider';
import { PlanningHelper } from './helper/PlanningHelper';
import moment from 'moment';

const CustomPlanningItem = (props) => {

  const { onFocus } = props;

  const columnPreference = PlanningHelper.getColumnPreference();
  const planningPreference = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  const showFormat = planningPreference?.data[0]?.value?.ShowFormat && props?.dataItem?.mediaEpisode?.Formats?.length > 0;
  const showNoSegment = planningPreference?.data[0]?.value?.ShowNoSeg && !props?.dataItem?.Segment_id;
  const showContract = planningPreference?.data[0]?.value?.ShowContract && props?.dataItem?.MediaDealRight_id;
  let showTxMode = false;
  let txModeValue = '';
  const showRecursive = (props?.dataItem?.RecursionParentProgramSchedule_id != undefined && props?.dataItem?.RecursionParentProgramSchedule_id != "") ? true : false;
  const scheduleDate = new Date(props.dataItem?.ScheduleDate).setUTCHours(0, 0, 0, 0);
  const channel = props?.dataItem?.Channel;
  const userData = utility.getValue(LOCALSTORAGE_KEY.userData);
  const modify = utility.canModify(props.lockedPlaylist, MODULE.PLANNING, channel._id, scheduleDate);
  var canModify = modify.canModify;
  const lockedBy = modify.lockedBy;
  var style = {
    ...props.style, letterSpacing: "1px", fontWeight: "bold", ...props.dataItem.Style, border: props.selected ? '2px solid black' : '0px', pointerEvents: canModify ? 'auto' : 'none'
  }

  let uiData = columnPreference.map((item, index) => {
    var value = item.key ? props.dataItem[item.key][item.name] : props.dataItem[item.name];
    if (item.name == 'EpisodeNumber') {
      value = value ? ((props.dataItem?.mediaEpisode?.MediaCategory?.SID ?? 0) == MEDIACATEGORIES.Movie ? 'NA' : 'EP ' + value) : '';
    } else if (item.name == 'SeasonNumber') {
      var temp = props.dataItem?.mediaEpisode?.SeasonNumber
      value = temp ? ((props.dataItem?.mediaEpisode?.MediaCategory?.SID ?? 0) == MEDIACATEGORIES.Movie ? 'NA' : 'S-' + temp) : '';
    } else if (item.name == 'TX Mode') {

      if (props.dataItem?.Segment_id != null && (props.dataItem?.segments?.length > 0 || props.dataItem?.mediaEpisode?.segments?.length > 0)) {

        let currentSegment = props.dataItem?.segment ? props.dataItem?.segment : props.dataItem?.mediaEpisode?.segments.find((x) => x._id == props.dataItem?.Segment_id)

        let temp = currentSegment?.SegmentType?.TXMode ?
        currentSegment?.SegmentType?.TXMode?.Name : utility.getKeyByValue(TX_MODE, currentSegment?.SegmentType?.TXModeSID);

        showTxMode = temp ? true : false;

        value = temp ? temp : 'No Segments';
        txModeValue = value;

      } else {

        value = 'No Segments';
        txModeValue = value;

      }

    } else if (item.type == COLUMNSTYPE.array) {
      // var tempArray = value.map((x) => x[item.format])
      //SHOW ONLY FIRST VALUE ONLY
      value = value && value.length > 0 ? value[0][item.format] : "";
    } else if (item.type == COLUMNSTYPE.time) {
      value = utility.convertMilisecondsToStringWithFrames(value);
    } else if (item.type == COLUMNSTYPE.date) {
      value = utility.convertMilisecondsToStringWithFrames(value);
    } else if (item.name == 'Language') {
      value = props.dataItem?.segment?.Language?.Language?.Description;
    }
    value = value?.toString();
    return value;
  });

  const tooltip = '(' + moment(props?.dataItem?.SlotDateTime).utc().format('HH:mm A') + ' - ' + moment(props?.dataItem?.SlotDateTime + props?.dataItem?.ActualDuration).utc().format('HH:mm A') + ')\n' + props?.dataItem?.Title + '\n' + uiData.join('\n');

  const divId = props?.id;
  let div = document.getElementById(divId)?.querySelector('div');
  if (div) {
    div.title = tooltip
  }

  const handleFocus = useCallback((event) => {
    //  props.onClick(event);
    // Call the default `onFocus` handler
    if (onFocus) {
      props.setFocusedPlanningItemPropRef(props);
      onFocus(event);
    }
  },
    [onFocus]
  );

  return <SchedulerItem {...props}
    style={style}

    onFocus={handleFocus}
    // onBlur={handleBlur}

    onClick={(event) => {
      if (event.syntheticEvent.detail == 2) {
        //dblclick
      } else {
        //singleClick
        props.onClick(event);
        props.onPlannerItemSelect(event, event.target.props.dataItem);
      }

    }}


  >
    {props.children}
    <SchedulerItemContent title={tooltip}>
      {
        uiData.map((item, index) => {
          if(item)return <div key={index + "-" + item._id} className='customItem'>{item}<br /></div>
        })
      }

    </SchedulerItemContent>
    <div style={{
      position: 'absolute', bottom: '0px', right: '0px',
      display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
      alignItems: 'center', fontSize: '10px', padding:(showFormat||showNoSegment||showContract||showRecursive)? '3px':0,
      width: '100%',
      backgroundColor:props?.dataItem?.Style?.backgroundColor?? "white",
      zIndex: 2
    }}>
      {showFormat && <i className="fa fa-cube mr-1" title="format" />}
      {showNoSegment && <i className="fa fa-window-close mr-1" title="no segments" />}
      {showContract && <i className="fa fa-pencil-square-o mr-1" title="contract" />}
      {showRecursive && <i className="fa fa-bars mr-1" title="recursive" />}
      {/* TX MODE ICONS */}
      {showTxMode && txModeValue == utility.getKeyByValue(TX_MODE, TX_MODE.LIVE) ? <i className={"fa fa-circle mr-1 mb-1"} style={{color: 'red'}} title="live" /> : txModeValue == utility.getKeyByValue(TX_MODE, TX_MODE.Repeat) ? <i className={"fa fa-registered mr-1 mb-1"} title="Repeat" /> : txModeValue == utility.getKeyByValue(TX_MODE, TX_MODE.ObVan) ? <span className={"rounded-circle d-inline-flex mr-1 mb-1"} style={{width: '12px', height: '12px', fontSize: '8px', padding: '0px 0px 9px 2px', border: `1px solid ${style?.color ?? 'white'}`}} title="ObVan">O</span> : txModeValue == utility.getKeyByValue(TX_MODE, TX_MODE.TX) ? <span className={"rounded-circle d-inline-flex mr-1 mb-1"} style={{width: '14px', height: '14px', fontSize: '7px', padding: '1px 0px 0px 1px', border: `1px solid ${style?.color ?? 'white'}`}} title="TX Mode">TX</span> : null}
    </div>
  </SchedulerItem>

};

export default CustomPlanningItem;