/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";

const NodeEditForm = (props) => {

    const blankDataItem = {
        ...props.item,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Description: props.item.Description ?? "",
        ParentNode: props.item.ParentNode ?? "",

    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [node, setNode] = useState([]);
    const [gridData, setGridData] = useState([]);
    const lang = useTranslation();
    const [signBoardOpen, setSignBoardOpen] = useState(false);
    const [signBoardGridData, setSignBoardGridData] = useState(false);

    useEffect(() => {
        loadcombo();
        if(props.item.SID > 0)
            {
                OnNodeEdit();
            }

    }, []);

    const loadcombo = async () => {
        let nodeRes = await API.getDataLookup(ENTITYNAME.DigitalSignNode, { sort: { Description: 1 } });
        setNode(nodeRes.data);
        
    };

    const OnNodeEdit = async () => {
        let signBoardRes = await API.getData(ENTITYNAME.DigitalSignBoard, { sort: { Description: 1 }, query: ['_id', '==', props.item._id] });
        setSignBoardGridData(signBoardRes.data);
    };



    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if(dataItem?.Name == dataItem?.ParentNode?.Description){
            toast.error(lang.choose_another_parent_node_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;
    };

    const onAddSignBoard = () => {
        setSignBoardOpen(true);
    }

    const handleSubmit = async () => {
        if (!isValid()) return;
        console.log(dataItem)
        let localData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            Name: dataItem.Name,
            Description: dataItem.Description,
            ParentNode: dataItem.ParentNode ? {
                _id: dataItem.ParentNode?._id,
                SID: dataItem.ParentNode?.SID,
                Description: dataItem.ParentNode?.Description,
            } : null,
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["Name", "=", dataItem.Name]],
        };
        console.log(localData)

        let res = await API.saveData(ENTITYNAME.DigitalSignNode, localData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.DIGITAL_SIGN_NODE, data: res.data, message: res.message };
            API.SaveLogs(logData);
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.DigitalSignNode);
            return;
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const MyCommandCell = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeData(props.dataIndex), () => { })} />
        </div>
    )

    const removeData = (index) => {
    }

    return (
        <>
            <Dialog
                title={props.item.Name ?? lang.create_node_dialog_header}
                onClose={props.cancelEdit}
                width={"60%"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row">
                            <div className="col-12">
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.name_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="Name"
                                            onChange={onChange}
                                            value={dataItem.Name}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.description_label}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="Description"
                                            onChange={onChange}
                                            value={dataItem.Description}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.parent_node_label}</label>
                                        <DropDownList
                                            style={{ backgroundColor: "white" }}
                                            data={node}
                                            name="ParentNode"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.ParentNode}
                                            onChange={onChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-3 mt-2">
                                        <input
                                            type="checkbox"
                                            name="Archive"
                                            value={dataItem.Archive}
                                            checked={dataItem.Archive}
                                            onChange={onChange}
                                        />
                                        <label htmlFor="TabView" className="pl-2">{lang.archive}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div style={{ alignItems: 'center', textAlign: 'right' }}>
                                            <h6 style={{ fontWeight: 600}}>{lang.digital_sign_board_label}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {/* <AddRoundButton title={lang.add_sign_board_button_tooltip} onClick={[]} /> */}

                                        <Grid data={signBoardGridData} style={{ height: "30vh"}}>
                                            <GridColumn cell={MyCommandCell} width={"50px"} locked={true} />
                                            <GridColumn field="Name" title={lang.name_column} editable={false} />
                                            <GridColumn field="Model.Name" title={lang.modal_column} editable={false} />
                                            <GridColumn field="SignBoardID" title={lang.sign_board_id_column} editable={false} />
                                            <GridColumn field="WarrantyDate" title={lang.warranty_date_column} cell={DateOnlyCell} editable={false} />
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default NodeEditForm;