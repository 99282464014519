/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ENTITYNAME, PUBLISHING_SOURCE, MEDIACATEGORIES, OTTROUTETYPE, ASSETTYPE, MODULE, LOGEVENT } from "../../framework/constant/constant";
import * as API from "../../framework/API/api"
import { CardList } from "../../components/CardList";
import AddNewEpisodesForm from "./AddNewEpisodesForm";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SeasonMetaDataEditForm from "./SeasonMetaDataEditForm";
import SeasonDetailForm from "./SeasonDetailForm";
import { FixedSidebarWithIcons } from "../../components/FixedSidebarWithIcons"
import MediaEpisodePublishingEditForm from './MediaEpisodePublishingEditForm';
import { EditPageHeader } from '../../components/EditPageHeader';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import MediaEpisodeAssetEditForm from './MediaEpisodeAssetEditForm';
import moment from 'moment';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import IsShortMediaCollectionSelection from '../../framework/forms/IsShortMediaCollectionSelection';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';

function SeasonEditForm(props) {
  const location = useLocation();
  const { SID } = useParams();
  const navigate = useNavigate();
  const lang = useTranslation();

  const [showAddEpisode, setShowAddEpisode] = useState(false);
  const [episodes, setEpisodes] = useState([]);
  const [archiveEpisode, setArchiveEpisode] = useState(false);
  const [removedMedia, setRemovedMedia] = useState([]);
  const [series, setSeries] = useState([]);
  const [disableSave, setDisableSave] = useState(SID > 0 ? false : true);
  const blankDataItem = {
    SID: SID,
    Title: '',
    Description: '',
    ImageUrl: '',
    Synopsis: '',
    Genres: [],
    //Posters: [],
    //Videos: [],
    Languages: [],
    Keywords: '',
    SeasonNumber: 1,
    PGRating: {}
  }

  const [seasonEntity, setSeasonEntity] = useState(blankDataItem);
  const [tabNumber, setTabNumber] = React.useState("1");
  const [mediaEpisodeEntity, setMediaEpisodeEntity] = useState({});
  const [seasonPublishings, setSeasonPublishings] = useState([]);
  const [seasonMetaData, setSeasonMetaData] = useState({
    Genres: [],
    Languages: [],
    Keywords: '',
    PGRating: {}
  });
  const [openEpisodes, setOpenEpisodes] = useState(true);
  const [mediaCategory, setMediaCategory] = useState([]);
  const [assetDataItem, setAssetDataItem] = useState({});

  const [isLoadedEdit, setIsLoadedEdit] = useState(false);

  //ATTACH MEDIA
  const [mediaOpen, setMediaOpen] = useState(false);
  const [selectedAttachedMedia, setSelectedAttachedMedia] = useState([]);
  const [imagePotraitImageFiles, setPotraitImageFiles] = useState(new Set());
  const [imageLandscapeImageFiles, setLandscapeImageFiles] = useState(new Set());


  const customeModelFields = {
    Title: "Title",
    Description: "Description",
    PosterURL: "ImageUrl",
    ThirdLine: "EpisodeNumber"
  }


  useEffect(() => {
    loadCombo();
    if (SID > 0) {
      loadSeason();
    } else {
      setIsLoadedEdit(true);
    }
  }, []);

  const loadCombo = async () => {
    let res = await API.getDataLookup(ENTITYNAME.Series);
    setSeries(() => res.data);
    let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory);
    setMediaCategory(mediaCategory.data);
  }

  const loadSeason = async () => {
    let res = await API.getEntity(ENTITYNAME.Season, parseInt(SID));
    console.log(res.data);


    if (res.success) {
      setSeasonEntity({ ...blankDataItem, ...res.data });
      setSeasonMetaData(() => ({
        Genres: res.data.Genres,
        Languages: res.data.Languages,
        Keywords: res.data.Keywords,
        PGRating: res.data.PGRating
      }))
      setIsLoadedEdit(true);
      setMediaEpisodeEntity(res.data);

      //load asset details
      if(res.data.IsOttAsset){
        let assetRes = await API.getData(ENTITYNAME.OttAsset, { query: [['Source._id', '=', res.data._id], ['Source.entityName', '=', 'season']] });
        console.log(assetRes);
        if(assetRes.data && assetRes.data.length > 0){[
          setAssetDataItem({...assetRes.data[0],IsOttAsset : res.data.IsOttAsset})
        ]}
      }


      //load attached media
      let attachedMedia = await API.getData(ENTITYNAME.MediaEpisode, { query: ['Media._id', '=', res.data._id.toString()] });
      setSelectedAttachedMedia(attachedMedia.data);

      // fetching deal rights
      let mediaDealRights = await API.getData(ENTITYNAME.MediaDealRights, {
        query: ["media_id", "=", res.data._id.toString()],
      });

      let tempData = [];
      if (mediaDealRights.data.length > 0) {
        tempData = mediaDealRights.data.map((obj, index) => {
          return {
            ...obj,
            index: index
          }
        })
      } else {
        tempData = mediaDealRights.data
      }
      setSeasonPublishings(tempData ?? []);



      //to get linked episodes of seasons
      await loadEpisodes(res.data._id);

    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  function validationCheck(data) {

    for (const key in data) {
      if ((data[key] === "" || data[key] === undefined || data[key] == 0) && (key === "Title" || key === "Description" || key === "SeasonNumber")) {
        toast.error(`${lang.missing_seasone_detail_form_error_message}` + key, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }
    return true;
  }

  const clear = () => {
    setSeasonEntity(blankDataItem);
    setSeasonMetaData({
      Genres: [],
      Languages: [],
      Keywords: "",
      PGRating: {}
    });
  }

  const handleSeasonPublishings = (data) => {
    setSeasonPublishings(data);
  }

  const handleRemovePublishingData = (removedDataItem) => {
    setRemovedMedia(old => [...old, removedDataItem]);
  }

  const onSave = async () => {

    const season_id = seasonEntity._id;

    const data = {
      ...seasonEntity,
      ...seasonMetaData,
      MediaCategory: mediaCategory.find((obj) => obj.SID == MEDIACATEGORIES.Season),
      IsOttAsset: assetDataItem.IsOttAsset ?? false,
    }

    console.log(data)

    if (data._id && data._id.toString().length > 0) {
      delete data._id
    }

    if(assetDataItem.IsOttAsset && !isValidAsset()){
      return;
    }

    if (validationCheck(data)) {
      let res = await API.saveData(ENTITYNAME.Season, data);
      if (!res.success) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

      //save Attach media
      await saveMediaInIsShorts();

      let payload = {
        data: res.data, //season saved entity
        entityName: ENTITYNAME.Season
      }

      await API.updateSeasonEpisodeCount(payload);

      let resMediaDealRights = await API.updateMediaDealRights(res.data, removedMedia, seasonPublishings.filter(x => x.PublishingSource == PUBLISHING_SOURCE.Season), PUBLISHING_SOURCE.Season);

      let assetsData = {};
      if (assetDataItem.IsOttAsset) {
        let assetRes = await onAssetSubmit({ ...res.data, _id: season_id });
        if(assetRes.success){
          assetsData = assetRes.data
        }
      }

      let logData = { event: LOGEVENT.UPDATE_SERIES, module: MODULE.MEDIA_EPISODE_SEASON, data: { ...res.data, MediaDealRights: resMediaDealRights.data, _id: season_id, AttchMedia: selectedAttachedMedia, Assets: assetsData }, message: res.message };
      API.SaveLogs(logData);

      navigate(-1) ? navigate(-1) : navigate(`/home/MediaEpisode/Season`);
      
      clear();

    }
    else {
      return;
    }
  };


  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const setMataData = (data) => {
    console.log(data);
    setSeasonMetaData(data);
  }

  const getMenu = (index, dataItem) => {
    return [
      {
        label: `${lang.options_actionbutton_menutext}`,
        items: [
          { label: `${lang.delete_actionbutton_option}`, icon: 'pi pi-fw pi-trash', command: () => { } },
          { label: `${lang.moveup_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-up ', command: () => { } },
          { label: `${lang.movedown_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-down', command: () => { } }
        ]
      }
    ]
  }

  const onShowHideAddEpisode = () => {
    setShowAddEpisode((old) => !old);
  }

  const handleSetingEpisode = (data) => {
    let temp = [];

    data.map((obj) => {
      temp = [...temp, obj.data];
    })

    console.log(temp);
    setEpisodes((old) => [...old, ...temp]);
  }

  const handleSetSeasonEntity = (data, episodeData) => {
    setSeasonEntity(data);

    let tempData = [];
    if (episodeData.length > 0) {
      episodeData.map((obj) => {
        tempData.push(obj.data);
      })
      setEpisodes(tempData);
    }

    loadEpisodes(data._id, false);

  }

  const handleSetDisableSave = (value) => {
    setDisableSave(value);
  }

  const sideFixMenu = [
    {
      icon: 'play',
      title: `${lang.sidefix_episode_tooltip}`,
      onClick: () => { setOpenEpisodes(old => !old) }
    },
  ]

  const deleteEpisode = async (index, dataItem) => {
    console.log(dataItem);

    setEpisodes((old) => old.filter((obj) => obj.SID != dataItem.SID));
    let res = await API.deleteData(ENTITYNAME.MediaEpisode, dataItem.SID);
    if (!res.success) return;

  }

  const onEpisodeEdit = (index, dataItem) => {
    console.log(dataItem);
    navigate(`/home/MediaEpisode/Episode/${dataItem.SID}`, { state: { quickEdit: false } });
  }

  //asset
  const setAssetEntity = (entity) => {
    setAssetDataItem(entity)
  }

  function isValidAsset() {

    if (Object.keys(assetDataItem.VodTypes).length == 0) {
      toast.error(lang.please_select_vod_type_validation_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((assetDataItem.routeType == OTTROUTETYPE.Page || assetDataItem.routeType.Description == 'Page') && (assetDataItem.page_id == "" || assetDataItem.page_id == undefined)) {
      toast.error(lang.please_select_page, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (assetDataItem.Videos.length == 0) {
      toast.error(lang.please_attach_atleast_one_video_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (assetDataItem.Posters.length == 0) {
      toast.error(lang.please_attach_atleast_one_poster_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const onAssetSubmit = async (seasonEntity) => {

    const saveData = {
      Title: seasonEntity.Title,
      Description: seasonEntity.Title,
      TBA: assetDataItem.TBA ?? false,
      StartDate: assetDataItem.StartDate ? new Date(assetDataItem.StartDate).getTime() : new Date(moment(new Date()).format('YYYY-MM-DD')).getTime(),
      EndDate: assetDataItem.EndDate ? new Date(assetDataItem.EndDate).getTime() : new Date(moment(new Date()).add(1, 'M').format('YYYY-MM-DD')).getTime(),
      OttAssetTypeSID: ASSETTYPE['Media Asset'],
      Provider: assetDataItem.Provider ? (typeof assetDataItem.Provider == "string") ? assetDataItem.Provider : assetDataItem.Provider?._id : "",
      Archive: assetDataItem.Archive ?? false,
      Source: { _id: seasonEntity._id, SID: seasonEntity.SID, Title: seasonEntity.Title, MediaCategory: seasonEntity.MediaCategory, entityName: 'season' },
      routeType: typeof assetDataItem.routeType === 'object' ? assetDataItem.routeType.ID : assetDataItem.routeType,
      page_id: typeof assetDataItem.page_id === 'object' ? assetDataItem.page_id._id : assetDataItem.page_id,
      VodTypes: {
        _id: assetDataItem.VodTypes._id,
        SID: assetDataItem.VodTypes.SID,
        Name: assetDataItem.VodTypes.Name
      },
      Videos: assetDataItem.Videos ?? [],
      Posters: assetDataItem.Posters ?? [],
      RentalRetailPrice : assetDataItem.VodTypes.Name == 'TVOD' ? assetDataItem.RentalRetailPrice : 0,
      RentalWholesalePrice :assetDataItem.VodTypes.Name == 'TVOD' ? assetDataItem.RentalWholesalePrice : 0,
      WebAssets: assetDataItem?.WebAssets ? assetDataItem?.WebAssets?._id : "",
    }

    if (assetDataItem._id != undefined) {
      saveData._id = assetDataItem._id;
      saveData.SID = assetDataItem.SID
    }

    console.log(saveData);
    
    let res = await API.saveData(ENTITYNAME.OttAsset, saveData);

    let logData = { event: res.message == "inserted" ? LOGEVENT.CREATE_ASSETS : LOGEVENT.UPDATE_ASSETS, module: MODULE.OTT_ASSETS, data: res.data, message: res.message };
    API.SaveLogs(logData);

    return res;

  };

  const handleMediaSelect = (dataList) => {

    let duplicate = [];
    let toAdd = [];

    if (dataList.length > 0) {
      dataList.map((x) => {
        if (selectedAttachedMedia.some(y => y.SID == x.SID)) {
          duplicate.push(x.Title);
        } else {
          toAdd.push(x);
        }
      })
    }

    if (duplicate.length > 0) {
      toast.info(`${duplicate.join(',')} are already exists`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    setSelectedAttachedMedia([...selectedAttachedMedia, ...toAdd]);
  }

  //call when save button clicks
  const saveMediaInIsShorts = async () => {
    console.log(selectedAttachedMedia)
    if (selectedAttachedMedia.length == 0) return;

    for (const element of selectedAttachedMedia) {
      let item = element;

      let attachedMedia = {
        _id: seasonEntity._id,
        SID: SID,
        Title: seasonEntity.Title,
        MediaCategory: seasonEntity.MediaCategory,
        entityName: 'season'
      }

      let response = await API.saveData(ENTITYNAME.MediaEpisode, { _id: item._id, Media: attachedMedia });

      if (!response.success) {
        console.log(response)
      }

    }

  }

  const MyAttachMediaCommandCell = (props) => (
    <div style={{ display: "flex" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteAttachedMedia(props.dataItem), () => { })} />
    </div>
  )

  const deleteAttachedMedia = async (dataItem) => {
    let filterMedia = selectedAttachedMedia.filter(x => x._id != dataItem._id);
    setSelectedAttachedMedia(filterMedia);

    let response = await API.saveData(ENTITYNAME.MediaEpisode, { _id: dataItem._id, Media: {} });
    if (!response.success) {
      console.log(response)
    }
  }

  const onArchiveEpisodes = async (data, archive) => {

    //for array
    if (data.length > 0) {

      let response = [];

      for (const element of data) {
        const item = element;
        let res = await API.saveData(ENTITYNAME.MediaEpisode, { _id: item._id, Archive: !archive });
        if (!res.success) {
          console.log(res)
        }
        // Log Capture
        let logData = { event: LOGEVENT.UPDATE_EPISODE, module: MODULE.EPISODE, data: {...item, Archive: !archive}, message: res.message };
        API.SaveLogs(logData);
        response.push(res);
      }
      loadEpisodes(seasonEntity._id, archive);
    }
  }

  const loadEpisodes = async (seasonID = seasonEntity._id, archive = false) => {

    let responseMediaEpisode = await API.getData(ENTITYNAME.MediaEpisode, { query: [['Season_id', '=', seasonID], ['Archive', '=', archive]] });
    if (!responseMediaEpisode.success) return;

    setEpisodes(responseMediaEpisode.data);

    return responseMediaEpisode.data;

  }

  return (<>
    <div className="myFlexContainer">
      <FixedSidebarWithIcons menuModel={sideFixMenu} />
      {openEpisodes && <CardList showMultiSelect={true} MenuModal={getMenu} containerName="Destination" close={() => setOpenEpisodes(false)} customeModelFields={customeModelFields} title={lang.season_cardlist_header} onAddClick={onShowHideAddEpisode}
        posterURL="https://play-lh.googleusercontent.com/LsmwRk16eEuZXxYLc-FGARu72Ji1qQq3Ow5d0aQ6tX2JE_yAFwRVx0Ubuv5rWuhNnSEa=w480-h960-rw"
        cardData={episodes.map((item) => { return { ...item, EpisodeNumber: "Episode: " + item.EpisodeNumber } })} showSearch={true} onEditDataItem={onEpisodeEdit} onDeleteDataItem={deleteEpisode} showTileView={true} inTileView={false} columnEntityName={ENTITYNAME.MediaEpisode}
        showPreferenceButton={true} onArchiveEpisodes={onArchiveEpisodes} loadEpisodes={loadEpisodes} showEpisodeArchiveCheckbox={true} archiveEpisode={archiveEpisode} setArchiveEpisode={setArchiveEpisode} enableSelection={true} showExport={true} showMultiVersioning={true} />}
      <div className="main-layout">
        <EditPageHeader title={`${lang.series_dialog_header} > ` + (seasonEntity.Title ? seasonEntity.Title : `${lang.new_button_dialog_header}`)} disableSave={disableSave} onSubmit={onSave} onCancel={() =>  navigate(-1) ? navigate(-1) : window.close()} />
        <SeasonDetailForm SID={seasonEntity.SID} setSeasonData={handleSetSeasonEntity} setSaveState={handleSetDisableSave} quickEdit={location.state.quickEdit} selectedSeries={location.state.selectedSeries} disableSelectSeries={location.state.disableSelectSeries ?? false} existingSeasons={location.state.existingSeason} />
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabNumber}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label={lang.meta_data_label} value="1" />
                <Tab label={lang.publishing_label} value="2" />
                <Tab label={lang.ott_assets_collection_header} value="3" />
                <Tab label={lang.attach_media_button_tooltip} value="4" />
              </TabList>
            </Box>
            <TabPanel value={"1"}>
              {isLoadedEdit && <SeasonMetaDataEditForm data={seasonMetaData} setMataData={setMataData} />}
            </TabPanel>
            <TabPanel value={"2"}>
              <MediaEpisodePublishingEditForm mediaCategory={mediaCategory.find((obj) => obj.SID == MEDIACATEGORIES.Season)} mediaEpisodeEntity={mediaEpisodeEntity} publishingSource={PUBLISHING_SOURCE.Season} data={seasonPublishings} handlePublishings={handleSeasonPublishings} handleRemovePublishingData={handleRemovePublishingData} />
            </TabPanel>
            <TabPanel value={"3"}>
              <div className="row mb-4" style={{ scrollbarWidth: "none", msOverflowStyle: 'none', overflow: 'auto', height: '63vh', boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "13px 0px 15px 5px" }}>
                <MediaEpisodeAssetEditForm source={'season'} sourceEntity={seasonEntity} entityname={ENTITYNAME.OttAsset} dataItem={assetDataItem} setDataEntity={setAssetEntity} setPotraitImageFiles={setPotraitImageFiles} setLandscapeImageFiles={setLandscapeImageFiles} />
              </div>
            </TabPanel>
            <TabPanel value={"4"}>
              <div className="row" style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "30px 20px 30px 20px" }}>
                <RoundButton icon={'link'} title={lang.attach_media_button_tooltip} onClick={() => setMediaOpen(true)} />
                <Grid data={selectedAttachedMedia} style={{ height: "30vh" }}>
                  <GridColumn cell={MyAttachMediaCommandCell} width={"40px"} locked={true} />
                  <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
                  <GridColumn field="Title" title={lang.title_column} editable={false} />
                </Grid>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
    {showAddEpisode && <AddNewEpisodesForm seasonData={seasonEntity} episodes={episodes} setEpisodes={handleSetingEpisode} cancelEdit={onShowHideAddEpisode} />}
    {mediaOpen && <IsShortMediaCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={handleMediaSelect} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setMediaOpen(false)} width={"50vw"} height={"60vh"} />}
  </>
  )
}

export default SeasonEditForm;