/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from '../../ConfirmAlert'
import SaveButton from "../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { utility } from "../../framework/utility/utilityProvider";
import moment from "moment";

function MediaEpisodeCensorshipDetailsEditForm(props) {
  const defaultDates = utility.getFirstAndLastDateOfMonth();
  let initialValue = {
    Classification : '',
    CutTcIn: '00:00:00:00',
    CutTcOut: '00:00:00:00',
    Duration:'00:00:00:00',
    DoneDate: defaultDates.FirstDate,
    ReferenceNo: "",
    Officer:"",
    Result:"",
    Remark: "",
    Cut:false,
    Archive:false
  }
  const [dataItem, setDataItem] = useState(initialValue);
  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
  selectedIndexRef.current = data;
   }
  const [i, setI] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [toggle, setToggle] = useState(false);
  const lang = useTranslation();

  useEffect(() => {
  }, []);

const onChange = async (e) => {
  var value = e.target.value;
  if (e.target.name == "CutTcIn" || e.target.name == "CutTcOut" || e.target.name == "Duration") value = utility.validateTimeString(value);

  setDataItem({ ...dataItem, [e.target.name]: value });

  if (e.target.name == "CutTcOut") {
    var cutTcOut = utility.convertStringWithFramesToMilliseconds(value);
    var cutTcIn = utility.convertStringWithFramesToMilliseconds(dataItem.CutTcIn);

    if (cutTcOut > cutTcIn) {
      var duration = utility.convertMilisecondsToStringWithFrames(cutTcOut - cutTcIn);
      setDataItem(old => { return { ...old, ["Duration"]: duration } })
    }

  }
  if (e.target.name == "Duration") {
    console.log(value);
    var duration = utility.convertStringWithFramesToMilliseconds(value);
    var CutTcIn = utility.convertStringWithFramesToMilliseconds(dataItem.CutTcIn);
    var cutTcOutRes = utility.convertMilisecondsToStringWithFrames(CutTcIn + duration);
    setDataItem(old => { return { ...old, ["CutTcOut"]: cutTcOutRes } })


  }
  if (e.target.name == "CutTcIn") {
    var cutTcOut = utility.convertStringWithFramesToMilliseconds(dataItem.CutTcOut);
    var cutTcIn = utility.convertStringWithFramesToMilliseconds(value);
    if (cutTcOut > cutTcIn) {
      var duration = utility.convertMilisecondsToStringWithFrames(cutTcOut - cutTcIn);
      setDataItem(old => { return { ...old, ["Duration"]: duration } })
    }
  }
};


  const isValid = () => {

    if (dataItem.CutTcIn == undefined || dataItem.CutTcIn.includes("_")) {
      toast.error(`${lang.please_enter_valid_value_cut_tcin_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.CutTcOut == undefined || dataItem.CutTcOut.includes("_") || dataItem.CutTcIn > dataItem.CutTcOut) {
      toast.error(`${lang.please_enter_valid_value_tcin_should_not_be_greater_than_tcout_media_episode_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Duration == undefined || dataItem.Duration.includes("_") || dataItem.Duration.includes("00:00:00:00")) {
      toast.error(`${lang.invalid_duration_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!utility.isValidDate(dataItem.DoneDate)) {
      toast.error(`${lang.please_select_from_date_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
 
    return true;
  }

  const addCensorshipDetail = (index) => {
    if (isValid()) {
      const dataNew = {
        ...dataItem,
        Classification : dataItem.Classification,
        CutTcIn: utility.convertStringWithFramesToMilliseconds(dataItem.CutTcIn ?? "00:00:00:00"),
        CutTcOut : utility.convertStringWithFramesToMilliseconds(dataItem.CutTcOut ?? "00:00:00:00"),
        Duration : utility.convertStringWithFramesToMilliseconds(dataItem.Duration ?? "00:00:00:00") , 
        DoneDate : (new Date(dataItem.DoneDate)).getTime(),
        ReferenceNo : dataItem.ReferenceNo,
        officer : dataItem.Officer,
        Result : dataItem.Result,
        Remark : dataItem.Remark,
        Cut : dataItem.Cut ?? false,
        Archive : dataItem.Archive ?? false
      }
      console.log(dataNew)
      props.setCensorshipDetailData([...props.data, dataNew])
      setI(i + 1);
      setDataItem(initialValue);
      clearData();
    }
  }

  const MyCommandCell = (props) => (
    <div className="mt-1" style={{ display: "flex" }}>
      <EditRoundButton onClick={() => editFormGrid(props)} />
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeCensorshipDetail(props.dataIndex), () => { })} />
    </div>
  )

  const editFormGrid = (data) => {
    
    let dataItem = data.dataItem;
    setSelectedIndexRef(data.dataIndex);
    setDataItem({...dataItem,
      DoneDate: moment(new Date(dataItem.DoneDate)).format('YYYY-MM-DD'),
      });
    setToggle(true);
    setEditMode(true);
    console.log(dataItem);
  }

  const removeCensorshipDetail = (index) => {
    if (props.data.length == 1) {
      props.setCensorshipDetailData([]);
      setI(i - 1);
      console.log(i);
      // clearData();
    }
    else {
      // console.log(index);
      props.setCensorshipDetailData([
        ...props.data.slice(0, index),
        ...props.data.slice(index + 1, props.data.length)
      ]);
      // clearData();
      setI(i - 1);
    }
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const handleSubmit = () => {
    if (isValid()) {
console.log(props.data)
    let local = [...props.data]
    local[selectedIndexRef.current] = dataItem;
    props.setCensorshipDetailData(local);

      setEditMode(false);
      clearData();
    }
  }

  const clearData = () => {
    setDataItem(initialValue);
    setEditMode(false);
    setToggle(false);
  }

  return (<div style={{height:"310px", boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "10px 20px 20px 20px" }}>
    <div className="row">
      <div className={"col-12"}>
        <AddRoundButton style={{marginBottom: '5px'}} onClick={() => setToggle(true)}></AddRoundButton>
        <Grid data={props.data} resizable={true} style={{ height: "26vh" }}>
          <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
          <GridColumn field="Classification" title={lang.classification_column} editable={false} />
          <GridColumn field="CutTcIn" title={lang.cut_tc_in_column} editable={false} />
          <GridColumn field="CutTcOut" title={lang.cut_tc_out_column} editable={false} />
          <GridColumn field="Duration" title={lang.duration_column} editable={false} />
          <GridColumn field="DoneDate" title={lang.done_date_column} cell={DateOnlyCell} editable={false} />
          <GridColumn field="ReferenceNo" title={lang.reference_no_column} editable={false} />
          <GridColumn field="Officer" title={lang.officer_column} editable={false} />
          <GridColumn field="Result" title={lang.result_column} editable={false} />
          <GridColumn field="Remark" title={lang.remark_column} editable={false} />
          <GridColumn field="Cut" title={lang.cut_column} editable={false} />
        </Grid>
      </div>
      {toggle &&
        <Dialog
          title={dataItem.Classification ? dataItem.Classification : `${lang.create_censorship_details_dialog_header}`}
          onClose={() => setToggle(false)}
          width={800}
          height={520}
        >
          <div className="row">
            <div className="col-12">
              <Form
                render={(formRenderProps) => (
                  <FormElement>
                    <div className="row mt-2">
                      <div className="marlr">
                        {!editMode && <SaveButton onClick={() => addCensorshipDetail()} />}
                        {editMode && <SaveButton onClick={() => { handleSubmit() }} />}
                        <CancelButton onClick={clearData} />
                      </div>
                    </div>
                    <div className=" mt-2" style={{ border: "groove 1px" }}>
                      <div className="row ml-1 mr-1">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">{lang.classification_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Classification" value={dataItem.Classification} onChange={(e) => onChange(e)} required />
                          </div>
                        </div>
                      </div>
                      <div className="row ml-1 mr-1">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="">{lang.cut_tc_in_label} </label>
                            <TimePickerWithFormat
                              name="CutTcIn"
                              value={dataItem.CutTcIn}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="">{lang.cut_tc_out_label}</label>
                            <TimePickerWithFormat
                              name="CutTcOut"
                              value={dataItem.CutTcOut}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="">{lang.duration_label} *</label>
                            <TimePickerWithFormat
                              name="Duration"
                              value={dataItem.Duration}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </div>
                        </div>
                        </div>
                        <div className="row ml-1 mr-1">                      
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.done_date_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="DoneDate"
                                value={dataItem.DoneDate}
                                onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.reference_no_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="ReferenceNo" value={dataItem.ReferenceNo} onChange={(e) => onChange(e)} required />
                          </div>
                        </div>
                      </div>

                      <div className="row mr-1 ml-1">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.officer_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Officer" value={dataItem.Officer} onChange={(e) => onChange(e)} required />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.result_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Result" value={dataItem.Result} onChange={(e) => onChange(e)} required />
                          </div>
                        </div>
                      </div>
                      <div className="row ml-1 mr-1">
                     
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.remark_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Remark" value={dataItem.Remark} onChange={(e) => onChange(e)} required />
                          </div>
                        </div>
                        <div className="col-6 mt-4">
                        <Field
                            style={{border: "1px solid grey"}}
                            name={"Cut"}
                            component={Checkbox}
                            label={lang.cut_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.Cut}
                          />
                        </div> 
                      </div>

                              
                      <div className="row mb-3">
                        <div className="col-5 ml-3">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"Archive"}
                            component={Checkbox}
                            label={lang.archive}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.Archive}
                          />
                        </div>
                      </div>
                    </div>
                  </FormElement>

                )} />
            </div>
          </div>
        </Dialog>}
    </div>
   </div>
  )
}

export default MediaEpisodeCensorshipDetailsEditForm