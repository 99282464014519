/* eslint-disable */
import React from "react";
import { DAYS, MONTH } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import { Menubar } from 'primereact/menubar';
import contextMenuItem from "../../../components/contextMenuItem";
export const PlaylistHeader = (props) => {

    const lang = useTranslation();
    const {
        date,
        selectedNode,
        nodesList,
        handleFilterDialog,
        onDateChange,
        onHeaderExpandChange,
        onCut,
        onCopy,
        onPaste,
        onDelete,
        onPlaylistRefresh,
    } = props


    const items = [
        {
            label: 'File',
            items: [
                {
                    icon: 'pi pi-plus',
                    label: 'New Playlist',
                    command: () => {
                        window.open("/home/Playlist", "_blank");
                    },
                    template: contextMenuItem,
                }
            ]
        },
        {
            label: 'Edit',
            items: [
                {
                    label: 'Cut',
                    icon: 'fa fa-cut',
                    shortcut: 'Ctrl + X',
                    command: () => {
                        onCut();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Copy',
                    icon: 'pi pi-copy',
                    shortcut: 'Ctrl + C',
                    command: () => {
                        onCopy();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Paste',
                    icon: 'fa fa-paste',
                    shortcut: 'Ctrl + V',
                    command: () => {
                        onPaste();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    shortcut: 'del',
                    command: () => {
                        onDelete();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Find and Replace',
                    icon: 'pi pi-globe',
                    shortcut: 'Ctrl + H',
                    command: () => {
                        // onClick
                    },
                    template: contextMenuItem,
                }

            ].filter(Boolean)
        },
        {
            label: 'View',
            items: [
                {
                    icon: 'pi pi-link',
                    label: 'Go To',
                    command: () => {
                        // onclick
                    },
                    shortcut: 'Ctrl + B',
                    template: contextMenuItem,
                },
                {
                    icon: 'pi pi-refresh',
                    label: 'Refresh Playlist',
                    shortcut: 'F5',
                    command: () => {
                        onPlaylistRefresh();
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Expand ",
                    icon: 'pi pi-arrows-v',
                    shortcut: 'Ctrl +',
                    command: () => {
                        onHeaderExpandChange({ target: { value: true } });
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Collapse ",
                    icon: 'pi pi-minus',
                    shortcut: 'Ctrl -',
                    command: () => {
                        onHeaderExpandChange({ target: { value: false } });
                    },
                    template: contextMenuItem,
                },
            ]
        },
        {
            label: 'Settings',
            items: [


                {
                    label: 'Playlist Preference',
                    icon: 'pi pi-cog',
                    command: () => {
                        // onclick
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Column Preference',
                    icon: 'pi pi-pause',
                    command: () => {
                        // onclick
                    },
                    template: contextMenuItem,
                },
            ]
        }
    ];

    return <>

        <div className="row mt-2 mb-1" style={{ fontFamily: "Roboto", width: "100%" }}>

            <div className="col-8" >
                <div className="flex-container" style={{ alignItems: "left" }}>
                    <div style={{ zIndex: 999 }}>
                        <h3 className="page-edit-title txt-color-bludeDark" style={{ margin: '0px 10px 0px 0px', fontSize: '18px' }}>
                            <i className="fa-regular fa-calendar-days" style={{ margin: '0px 5px 0px 0px' }}></i>{lang.playlist_page_breadcrumb}
                        </h3>
                        <Menubar style={{ height: "40px", zIndex: 999, background: "transparent", border: "none", marginLeft: "-8px", padding: '8px 8px 16px 0px' }} model={items} />
                    </div>
                </div>
            </div>

            <div className="col-2 pull-right">
                <input
                    type="search"
                    className="form-control shadow-inset-2 pt-0 pb-0 right ml-1"
                    id="search"
                    style={{ width: "200px" }}
                    aria-label="type 2 or more letters"
                    placeholder={lang.search_button_text}
                    onChange={props.onSearch}
                    onFocus={(e) => {
                        props.setOutSideFocus(true);
                    }}
                    onBlur={(e) => {
                        props.setOutSideFocus(false);
                    }}
                />
            </div>

            <div className="col" style={{ textAlign: "right", padding: '0px', margin: '0px' }}>
                <div className="row-reverse">
                    <div className="col">
                        <div className="row-reverse" style={{}}>
                            <span onClick={handleFilterDialog} style={{ fontFamily: "Roboto", fontWeight: "bold", fontSize: "18px", color: "#4b4d4d", cursor: "pointer" }} >{selectedNode?.Name} </span>
                        </div>
                        <div>
                            <span> <i className="fa-lg fa-solid fa-square-caret-left fa-md pt-1 mr-1 pointer" onClick={() => onDateChange(false)}></i> </span>
                            {/* Tuesday, January 5, 2022. */}
                            <span className="pointer" onClick={handleFilterDialog} style={{ fontFamily: "Roboto", fontSize: "16px", color: "#4b4d4d" }}>{date.getUTCDate()} {MONTH[date.getUTCMonth()]} {date.getUTCFullYear()}, {DAYS[date.getDay()]?.SubName}</span>
                            <span style={{ padding: '0px', margin: '0px', color: "#4b4d4d" }} />
                            <span> <i className="fa-lg fa-solid fa-square-caret-right fa-md pt-1 ml-1 pointer" onClick={() => onDateChange(true)}></i> </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}