/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import * as API from '../../framework/API/api'
import { DAYS, ENTITYNAME, PLATFORMTYPE, PUBLISHING_SOURCE } from "../../framework/constant/constant";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import moment from "moment";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import SaveButton from "../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { ConfirmDeleteAlert } from '../../ConfirmAlert'
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { utility } from "../../framework/utility/utilityProvider";
import { HrefCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { stringEnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";

function MediaEpisodePublishingEditForm(props) {
  
  const mediaEpisodePublishings = props?.data?.filter(x => x?.IsSystemCreated != true) ?? [];

  let systemDealRight = props?.data?.find(x => x?.IsSystemCreated) ?? {};

  const isSystemCreated = systemDealRight?.IsSystemCreated ?? false;

  const getSystemDealRightMessage = () => {
    let systemDealRightMessage = "Unlimited Rights ";
    if (systemDealRight?.Channel?.length > 0) {
      systemDealRightMessage = systemDealRight?.Channel?.map((item) => item?.FullChannelName).join(", ");
      systemDealRightMessage =  "Unlimited Channel Right for " + systemDealRightMessage  + " Channel(s)";
    }
    return systemDealRightMessage;
  }

  const showLinearTab = props?.showLinearTab ?? true;
  const showNonLinearTab = props?.showNonLinearTab ?? true;
  const isLongUI = props?.mediaCategory?.isLong ?? false;

  const [platform, setPlatform] = useState([]);
  const [channel, setChannel] = useState([]);
  const [region, setRegion] = useState([]);
  const [showChannelPlatform, setShowChannelPlatform] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [playTime, setPlayTime] = useState([]);
  const [mediaCategory, setMediaCategory] = useState([]);
  const lang = useTranslation();
  const [tabNumber, setTabNumber] = useState(showLinearTab ? "1" : "2");
  const [systemDealRightMessage, setSystemDealRightMessage] = useState("Unlimited Rights");
  const [isSystemCreatedCheck, setIsSystemCreatedCheck] = useState(false);

  const editIndex = useRef();
  let blankDataItem = {
        _id: undefined,
        SID: 0,
        ContractSID: 0,
        ContractNumber: '',
        PublishStartDate: moment(new Date()).format("YYYY-MM-DD"),
        PublishStartDateTime : '00:00:00:00',
        PublishEndDate: moment(new Date()).add(1, 'd').format("YYYY-MM-DD"),
        PublishEndDateTime : "23:59:59:24",
        TimeBound : false,
        PublishingSource: props.publishingSource,
        PlatformType: tabNumber == 1 ? PLATFORMTYPE[0] : PLATFORMTYPE[1],
        Platform: [],
        Licensor: {},
        Payee: {},
        Channel: [],
        Days: DAYS,
        Region: [],
        Amount: 0,
        Currency: {},
        IsUnlimited: true,
        Exhibitions: 0,
        Repeats: 0,
        PlayTimeNumber: 0,
        PlayTime: {},
        IsExclusive: false,
        ExclusiveStartDate: '',
        ExclusiveEndDate: '',
        Archive: false,
        MediaCategory: props.mediaCategory,
    }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [selectAllDays, setSelectAllDays] = useState(true);

  useEffect(() => {
    loadcombo();
    setSystemDealRightMessage(getSystemDealRightMessage());
  }, []);

  const loadcombo = async () => {
    let region = await API.getDataLookup(ENTITYNAME.Region, { sort: { Description: 1 } });
    setRegion(region.data);
    let platform = await API.getDataLookup(ENTITYNAME.Platform, { sort: { Description: 1 } });
    setPlatform(platform.data);
    let channelRes = await API.getValidChannels(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannel(channelRes.data);
    let playTime = await API.getDataLookup(ENTITYNAME.PlayTime, { sort: { Description: 1 } });
    setPlayTime(playTime.data);

    let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
    let mediaCategoryFiltered = mediaCategory.data.filter((item) => item.SID == props.mediaCategory.SID);
    setMediaCategory(mediaCategoryFiltered);
  }

  const onChange = (e) => {

    if (e.target.name == "TBA") {
      setDataItem({ ...dataItem, TBA: !dataItem.TBA });
    } else if (e.target.name == "TimeBound") {
      setDataItem({ ...dataItem, TimeBound: !dataItem.TimeBound });
    } else if (e.target.name == "IsUnlimited") { //need Some review
      setDataItem({
        ...dataItem,
        IsUnlimited: !dataItem.IsUnlimited,
        Exhibitions: 0,
        Repeats: 0,
        PlayTimeNumber: 0,
        PlayTime: {}
      });
    } else if (e.target.name == "IsExclusive") {
      setDataItem({
        ...dataItem,
        IsExclusive: !dataItem.IsExclusive,
        ExclusiveStartDate: '',
        ExclusiveEndDate: ''
      });
    }
    else if(e.target.name == "Days"){
      if(e.target.value.length == DAYS.length){
        setDataItem({...dataItem, Days: DAYS});
        setSelectAllDays(true);
      }
      else{
        setDataItem({...dataItem, Days: e.target.value});
        setSelectAllDays(false);
      }
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

  }

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
    setDataItem({ ...dataItem, PlatformType: PLATFORMTYPE[parseInt(newValue) - 1] });
  };

  const isValid = () => {

    if (!utility.isValidDate(dataItem.PublishStartDate)) {
      toast.error(`${lang.please_select_valid_start_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!utility.isValidDate(dataItem.PublishEndDate)) {
      toast.error(`${lang.please_select_valid_end_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(dataItem.PublishStartDate > dataItem.PublishEndDate){
      toast.error(`${lang.please_select_start_date_smaller_than_end_date_media_episode_publishing_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (!isLongUI) {
      if (!dataItem.Channel || dataItem.Channel.length === 0) {
        toast.error(`${lang.please_select_channel_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if(!dataItem.Days || dataItem.Days.length == 0){
        toast.error(`${lang.please_select_atleast_one_days_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }
    else {
      if (Object.keys(dataItem.PlatformType).length === 0) {
        toast.error(`${lang.please_select_platform_type_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      else if (dataItem.PlatformType.Description === "Linear") {
        if (dataItem.Channel.length === 0) {
          toast.error(`${lang.please_select_channel_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        if (dataItem.IsUnlimited == false) {
          if (dataItem.Exhibitions < 1) {
            toast.error(`${lang.exhibition_should_not_be_zero_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
          if (dataItem.Repeats < 1) {
            toast.error(`${lang.repeat_should_not_be_zero_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
          if (dataItem.PlayTimeNumber < 1) {
            toast.error(`${lang.play_time_number_should_not_be_zero_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
          if (Object.keys(dataItem.PlayTime).length === 0) {
            toast.error(`${lang.please_select_play_time_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
        }
        if(dataItem.IsExclusive){
          if(!utility.isValidDate(dataItem.ExclusiveStartDate)){
            toast.error(`${lang.please_select_exclusive_start_date_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
          if(!utility.isValidDate(dataItem.ExclusiveEndDate)){
            toast.error(`${lang.please_select_exclusive_end_date_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
        }
        else return true;
      }
      else if (dataItem.PlatformType.Description === "Non-Linear") {
        if (dataItem.Platform.length === 0) {
          toast.error(`${lang.please_select_platform_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        else return true;
      }
    }
    return true;
  }

  const editFormGrid = (data) => {

    let index = mediaEpisodePublishings.findIndex((x) => x == data );

    if(data?.IsSystemCreated){
      let authorizeChannel = utility.isUserAuthorizeChannelRights(data.Channel);
      if(!authorizeChannel){
        toast.error(`${lang.you_are_not_authorized_to_edit_this_channel_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
    }

    if (props?.publishingSource != PUBLISHING_SOURCE.Series && data.PublishingSource == PUBLISHING_SOURCE.Series){
      toast.error(`${lang.you_are_not_authorized_to_edit_please_go_to_parent_series_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if (props?.publishingSource != PUBLISHING_SOURCE.Season && data.PublishingSource == PUBLISHING_SOURCE.Season){
      toast.error(`${lang.you_are_not_authorized_to_edit_please_go_to_series_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if (!props.mediaCategory.isLong || (data.PublishingSource == props.publishingSource) || data?.IsSystemCreated) 
    {
      setToggle(true);
      setEditMode(true);
      editIndex.current = index;

      let daysArray = data.Days ? data.Days.split(",").map((item) => DAYS.find((x) => x.ID == item)) : [];

      setDataItem({
        ...data,
        PublishStartDate: moment(new Date(data.PublishStartDate)).format('YYYY-MM-DD'),
        PublishStartDateTime : data.PublishStartDateTime ? utility.convertMilisecondsToStringWithFrames(data.PublishStartDateTime) : '00:00:00:00',
        PublishEndDate: moment(new Date(data.PublishEndDate)).format('YYYY-MM-DD'),
        PublishEndDateTime : data.PublishEndDateTime ? utility.convertMilisecondsToStringWithFrames(data.PublishEndDateTime): "23:59:59:24",
        ExclusiveStartDate: data.IsExclusive ? moment(new Date(data.ExclusiveStartDate)).format('YYYY-MM-DD') : '',
        ExclusiveEndDate: data.IsExclusive ? moment(new Date(data.ExclusiveEndDate)).format('YYYY-MM-DD') : '',
        Days: daysArray,
      })
      setSelectAllDays(daysArray.length == DAYS.length ? true : false);
      if (data.PlatformType.Description == "Non-Linear") {
        setShowChannelPlatform(false);
      }
      else {
        setShowChannelPlatform(true);
      }
    } else {
      toast.error("Selected Deal Right Can Be Edited From " + data.PublishingSource + " only.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const removePublishing = (data) => {

    let index = mediaEpisodePublishings.findIndex((x) => x == data);

    if (props?.publishingSource != PUBLISHING_SOURCE.Series && data.PublishingSource == PUBLISHING_SOURCE.Series){
      toast.error(`${lang.you_delete_this_deal_right_right_from_parent_series_only_media_episode_publishing_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if (props?.publishingSource != PUBLISHING_SOURCE.Season && data.PublishingSource == PUBLISHING_SOURCE.Season){
      toast.error(`${lang.you_delete_this_deal_right_right_from_series_only_media_episode_publishing_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if (data.PublishingSource != PUBLISHING_SOURCE.Contract) 
    {
      if(data._id)props.handleRemovePublishingData(data);
      
      props.handlePublishings([
        ...mediaEpisodePublishings.slice(0, index),
        ...mediaEpisodePublishings.slice(index + 1, mediaEpisodePublishings.length)
      ]);
      toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else 
    {
      toast.error(`${lang.you_delete_this_deal_right_from_contract_only_media_episode_publishing_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onAddPublishing = () => {

    let publishingObject = {
      ...dataItem,
      _id: undefined,
      PublishStartDate: new Date(dataItem.PublishStartDate).getTime(),
      PublishStartDateTime : dataItem.TimeBound ? utility.convertStringWithFramesToMilliseconds(dataItem.PublishStartDateTime) : utility.convertStringWithFramesToMilliseconds('00:00:00:00') ,
      PublishEndDate: new Date(dataItem.PublishEndDate).getTime(),
      PublishEndDateTime : dataItem.TimeBound ? utility.convertStringWithFramesToMilliseconds(dataItem.PublishEndDateTime) : utility.convertStringWithFramesToMilliseconds("23:59:59:24") ,
      ExclusiveStartDate: dataItem.IsExclusive ? new Date(dataItem.ExclusiveStartDate).getTime() : 0,
      ExclusiveEndDate: dataItem.IsExclusive ? new Date(dataItem.ExclusiveEndDate).getTime() : 0,
      Days: dataItem.Days && dataItem?.Days?.map((item) => item.ID).join(","),
      Channel: dataItem.Channel && dataItem.Channel.map((item) => {
        return {
          _id: item._id,
          SID: item.SID,
          FullChannelName: item.FullChannelName,
        }
      }),
    }
    setSelectAllDays(true);
    console.log(publishingObject);

    if(isLongUI){
      delete publishingObject.TimeBound
      delete publishingObject.PublishStartDateTime
      delete publishingObject.PublishEndDateTime
    }

    if (isValid()) //add validation
    {
      props.handlePublishings([...mediaEpisodePublishings, publishingObject])
      clearData();
    }
  }

  const editPublishing = () => {
    console.log("here in edit pub");
    // mediaEpisodePublishings[dataItem.index] = dataItem

    let publishingObject = {
      ...dataItem,
      PublishStartDate: new Date(dataItem.PublishStartDate).getTime(),
      PublishEndDate: new Date(dataItem.PublishEndDate).getTime(),
      PublishStartDateTime : dataItem.TimeBound ? utility.convertStringWithFramesToMilliseconds(dataItem.PublishStartDateTime) : utility.convertStringWithFramesToMilliseconds('00:00:00:00') ,
      PublishEndDateTime : dataItem.TimeBound ? utility.convertStringWithFramesToMilliseconds(dataItem.PublishEndDateTime) : utility.convertStringWithFramesToMilliseconds("23:59:59:24") ,
      ExclusiveStartDate: dataItem.IsExclusive ? new Date(dataItem.ExclusiveStartDate).getTime() : 0,
      ExclusiveEndDate: dataItem.IsExclusive ? new Date(dataItem.ExclusiveEndDate).getTime() : 0,
      Days: dataItem.Days && dataItem.Days.map((item) => item.ID).join(","),
      Channel: dataItem?.Channel && dataItem.Channel?.map((item) => {
        return {
          _id: item._id,
          SID: item.SID,
          FullChannelName: item.FullChannelName,
        }
      }),
      isSystemCreated: isSystemCreated ?? false
    }

    if(isLongUI){
      delete publishingObject.TimeBound
      delete publishingObject.PublishStartDateTime
      delete publishingObject.PublishEndDateTime
    }

    if (isValid()) //add validation
    {
      // itemsLeft
      const newData = [ ...mediaEpisodePublishings.slice(0, editIndex.current),publishingObject, ...mediaEpisodePublishings.slice(editIndex.current + 1, mediaEpisodePublishings.length)]

      props.handlePublishings(newData);
      if(isSystemCreated){
        let getChannelName = publishingObject?.Channel?.map((item) => item?.FullChannelName).join(", ");
        let updateMessage =  "Unlimited Channel Right for " + getChannelName  + " Channel(s)";
        setSystemDealRightMessage(updateMessage);
      }
      clearData();
    }
  }

  const clearData = () => {
    setDataItem(blankDataItem);
    setEditMode(false);
    setToggle(false);
    setSelectAllDays(true);
    setIsSystemCreatedCheck(false);
    // props.refresh();
  }

  const MyCommandCell = (props) => (
    <div style={{ display: "flex"}} className="mt-1" >
      <EditRoundButton onClick={() => editFormGrid(props.dataItem)} />
      {/* <RoundButton icon={'external-link'} onClick={()=>window.open(`/home/Contract/${props.dataItem.ContractSID}`, "_blank")}/> */}
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removePublishing(props.dataItem), () => { })} />
    </div>
  )

  const allDaySelect = (e) => {
    if(e.target.checked){
      setDataItem({...dataItem, Days: DAYS});
      setSelectAllDays(true);
    }
    else{
      setDataItem({...dataItem, Days: []});
      setSelectAllDays(false);
    }
  }

  return (<>
    <div className="row" style={{ display: 'flex', boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px"}}>
      <div className={"col-12"}>
        <Box sx={{ typography: 'body1' }}>
          <TabContext value={tabNumber}>
            <Box sx={{display: 'flex', borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                {showLinearTab && <Tab label={lang.linear_label} value="1" />}
                {isLongUI && showNonLinearTab && <Tab label={lang.non_linear_label} value={"2"} />}
              </TabList>
            </Box>
            {showLinearTab && <TabPanel value={"1"}>
              <div className="row">
                <div className="col-12" style={{width:'56vw'}}>
              <AddRoundButton onClick={() => setToggle(true)} />
              {isSystemCreated && mediaEpisodePublishings.length == 0 && <RoundButton title={lang.add_channel_rights_button_tooltip} icon="caret-square-o-right" style={{float: "right"}} onClick={() => {editFormGrid(systemDealRight); setIsSystemCreatedCheck(true);}} />}
              <Grid data={mediaEpisodePublishings.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[0]?.Description)} style={{ height: "30vh", marginTop: '5px',display: 'flex' }}>
                <GridNoRecords> {systemDealRightMessage} </GridNoRecords>
                <GridColumn cell={MyCommandCell} width={"70px"} />
                <GridColumn field="Channel" format="FullChannelName" cell={ArrayCell} title={lang.channel_column} width={"200px"} />
                <GridColumn field='PublishingSource' title={lang.source_column} width={"100px"} />
                {isLongUI && <GridColumn field='ContractNumber' title={lang.contract_number_column} cell={HrefCell} width={"100px"} />}
                <GridColumn field="PublishStartDate" title={lang.start_date_column} cell={DateOnlyCell} width={"100px"} />
                <GridColumn field="PublishEndDate" title={lang.end_date_column} cell={DateOnlyCell} width={"100px"} />
                {isLongUI && <GridColumn field="IsUnlimited" title={lang.is_unlimited_column} cell={CheckboxCell} width={"80px"} />}
                {isLongUI && <GridColumn field="Exhibitions" title={lang.exhibition_column} width={"100px"} />}
                {isLongUI && <GridColumn field="Repeats" title={lang.repeat_column} width={"100px"} />}
                {isLongUI && <GridColumn field="PlayTimeNumber" title={lang.play_time_number_column} width={"100px"} />}
                {isLongUI && <GridColumn field="PlayTime.Description" title={lang.playTime_column} width={"100px"} />}
                {isLongUI && <GridColumn field="IsExclusive" title={lang.is_exclusive_column} cell={CheckboxCell} width={"80px"} />}
                {isLongUI && <GridColumn field="ExclusiveStartDate" title={lang.exclusive_start_date_column} cell={DateOnlyCell} width={"100px"} />}
                {isLongUI && <GridColumn field="ExclusiveEndDate" title={lang.exclusive_end_date_column} cell={DateOnlyCell} width={"100px"} />}
                {!isLongUI && <GridColumn field="TBA" title={lang.tba_column} editor="boolean" editable={false} cell={CheckboxCell} width={"80px"} />}
                {!isLongUI && <GridColumn field="Days" title={lang.days_column} cell={(props)=>stringEnumCell(props, DAYS , "SubName")} width={"180px"} />}
                <GridColumn field="addBy.name" title={'Added By'} width={'125px'} />
                <GridColumn field="modBy.name" title={'Modified By'} width={'125px'} />
              </Grid>
              </div>
              </div>
            </TabPanel>}
            {isLongUI && showNonLinearTab && <TabPanel value={"2"}>
              <AddRoundButton onClick={() => setToggle(true)}></AddRoundButton>
              <Grid data={mediaEpisodePublishings.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[1]?.Description)} style={{ height: "30vh", marginTop: '5px' }}>
                <GridNoRecords> {lang.unlimited_rights_grid} </GridNoRecords>
                <GridColumn cell={MyCommandCell} width={"70px"} locked={true}/>
                <GridColumn field="Platform" format="Description" cell={ArrayCell} title={lang.platform_column} width={"200px"}/>
                <GridColumn field='PublishingSource' title={lang.source_column} />
                <GridColumn field="PublishStartDate" title={lang.start_date_column} cell={DateOnlyCell} width={"100px"}/>
                <GridColumn field="PublishEndDate" title={lang.end_date_column} cell={DateOnlyCell} width={"100px"}/>
                <GridColumn field="addBy.name" title={'Added By'} width={'125px'} />
                <GridColumn field="modBy.name" title={'Modified By'} width={'125px'} />
              </Grid>
            </TabPanel>}
          </TabContext>
        </Box>
      </div>
      {toggle &&
        <Dialog
          title={lang.publishing_dialog_header}
          onClose={clearData}
          width={isLongUI ? "1000px" : "700px"}
        >
          <div className="row">
            <div className="col-12">
              {!editMode && <SaveButton onClick={() => onAddPublishing()} />}
              {editMode && <SaveButton onClick={() => { editPublishing() }} />}
              <CancelButton name={lang.cancel_button_text} btnColor={'danger'} onClick={clearData} />
              <div className="row" style={{ margin: '1px' }}>
                <div className="col-12 mt-4" >
                  {isLongUI && <div className="row">
                    <div className={isLongUI ? "col-12" : "col-6"}>
                      <div className="form-group">
                        <label htmlFor="TabView">{lang.platform_type_label} *</label><br />
                        <DropDownList
                          style={{ backgroundColor: "white" }}
                          data={PLATFORMTYPE}
                          name="PlatformType"
                          textField="Description"
                          dataItemKey="SID"
                          value={dataItem.PlatformType}
                          onChange={onChange}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      {!isLongUI && <div className="form-group">
                        <label htmlFor="">{lang.contract_number_label} *</label>
                        <input
                          style={{ height: "35px" }}
                          type="input"
                          className="form-control form-control-sm"
                          name="ContractNumber"
                          value={dataItem.ContractNumber}
                          onChange={(e) => onChange(e)}
                        />
                      </div>}
                    </div>
                  </div>}

                  <div className="row">
                    {dataItem.PlatformType.Description == PLATFORMTYPE[0].Description ? (<>
                      <div className="col-6 form-group">
                        <label htmlFor="TabView">{lang.channel_label} *</label><br />
                        <MultiSelect
                          style={{
                            backgroundColor: "white",
                          }}
                          data={channel}
                          name="Channel"
                          textField="FullChannelName"
                          dataItemKey="_id"
                          value={dataItem.Channel}
                          onChange={onChange}
                        />
                      </div>
                      {!isLongUI && <div className="col-6">
                        <label htmlFor="">{lang.days_label} *</label> 
                        <span className="float-right">{lang.select_all_label}
                        <input 
                          type={"checkbox"} 
                          name={"SelectAll"}
                          className="ml-1"
                          onChange={allDaySelect}
                          checked={selectAllDays}
                          value={selectAllDays}
                        />
                        </span>
                        <MultiSelect
                          style={{
                            backgroundColor: "white",
                          }}
                          data={DAYS}
                          name="Days"
                          textField="Name"
                          dataItemKey="ID"
                          value={dataItem.Days}
                          onChange={onChange}
                        />
                      </div>}  </>) : (<div className="col-6">
                      <div className="form-group">
                        <label htmlFor="TabView">{lang.platform_label} *</label><br />
                        <MultiSelect
                          style={{
                            backgroundColor: "white",
                          }}
                          data={platform}
                          name="Platform"
                          textField="Description"
                          dataItemKey="_id"
                          value={dataItem.Platform}
                          onChange={onChange}
                        />
                      </div>
                    </div>)}

                    {isLongUI && <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="TabView">{lang.region_label}</label><br />
                        <MultiSelect
                          style={{
                            backgroundColor: "white",
                          }}
                          textField="Description"
                          dataItemKey="_id"
                          data={region}
                          name="Region"
                          value={dataItem.Region}
                          onChange={onChange}
                          disabled={isSystemCreatedCheck}
                        />

                      </div>
                    </div>}
                  </div>
                  <div className="row">
                    <div className={!isLongUI ? "col-3" : "col-6"}>
                      <div className="form-group">
                        <label htmlFor="">{lang.start_date_label} *</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="PublishStartDate"
                          value={dataItem.PublishStartDate}
                          onChange={(e) => onChange(e)}
                          disabled={isSystemCreatedCheck}
                        />
                      </div>
                    </div>

                    <div className={!isLongUI ? "col-3" : "col-6"}>
                      <div className="form-group">
                        <label htmlFor="">{lang.end_Date_label} *</label>
                        <input
                          disabled={dataItem.TBA || isSystemCreatedCheck}
                          type="date"
                          className="form-control form-control-sm"
                          name="PublishEndDate"
                          value={dataItem.PublishEndDate}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      {!isLongUI && <div className="form-group" style={{ marginTop: "20px" }}>
                        <label style={{ paddingRight: "10px" }}>{lang.tba_label}</label>
                        <input type={"checkbox"} name={"TBA"} style={{ marginTop: "10px" }}
                          onChange={(e) => onChange(e)}
                          value={dataItem.TBA}
                          checked={dataItem.TBA}  
                        />
                      </div>}
                    </div>
                    <div className="col-3">
                      {!isLongUI && <div className="form-group" style={{ marginTop: "20px" }}>
                        <label style={{ paddingRight: "10px" }}>{lang.time_bound_label}</label>
                        <input type={"checkbox"} name={"TimeBound"} style={{ marginTop: "10px" }}
                          onChange={(e) => onChange(e)}
                          value={dataItem.TimeBound}
                          checked={dataItem.TimeBound}  
                        />
                      </div>}
                    </div>
                  </div>
                  {dataItem.TimeBound && <div className="row">
                    <div className={"col-6"}>
                      <div className="form-group">
                        <label htmlFor="">{lang.from_time_label} *</label>
                        <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="PublishStartDateTime"
                        value={dataItem.PublishStartDateTime}
                        onChange={(e) => onChange(e)}
                    />
                      </div>
                    </div>

                    <div className={"col-6"}>
                      <div className="form-group">
                        <label htmlFor="">{lang.to_time_label} *</label>
                        <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="PublishEndDateTime"
                        value={dataItem.PublishEndDateTime}
                        onChange={(e) => onChange(e)}
                    />
                      </div>
                    </div>
                  </div>}
                  {dataItem.PlatformType.Description == PLATFORMTYPE[0].Description && isLongUI && <div className="row form-group">
                    <div className="col-6">
                      <input type={"checkbox"} name={"IsUnlimited"}
                        onChange={(e) => onChange(e)}
                        value={dataItem.IsUnlimited}
                        checked={isSystemCreatedCheck ? true : dataItem.IsUnlimited}
                        disabled={isSystemCreatedCheck}
                      />
                      <label className="pl-1">{lang.is_unlimited_label}</label>
                    </div>
                    <div className="col-6">
                      <input type={"checkbox"} name={"IsExclusive"}
                        onChange={(e) => onChange(e)}
                        value={dataItem.IsExclusive}
                        checked={dataItem.IsExclusive}
                        disabled={isSystemCreatedCheck}
                      />
                      <label className="pl-1">{lang.exclusive_label}</label>
                    </div>
                  </div>}

                  <div className="row">
                    <div className="col-6">
                      {dataItem.PlatformType.Description == PLATFORMTYPE[0].Description && isLongUI && !dataItem.IsUnlimited && <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.exhibition_label} *</label>
                            <input
                              disabled={dataItem.IsUnlimited}
                              style={{ height: "35px" }}
                              type="number"
                              className="form-control form-control-sm"
                              name="Exhibitions"
                              value={dataItem.Exhibitions}
                              onChange={(e) => onChange(e)}
                            />
                          </div>

                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.repeat_label} *</label>
                            <input
                              disabled={dataItem.IsUnlimited}
                              style={{ height: "35px" }}
                              type="number"
                              className="form-control form-control-sm"
                              name="Repeats"
                              value={dataItem.Repeats}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.play_time_number_label} *</label>
                            <input
                              disabled={dataItem.IsUnlimited}
                              style={{ height: "35px" }}
                              type="number"
                              className="form-control form-control-sm"
                              name="PlayTimeNumber"
                              value={dataItem.PlayTimeNumber}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.playTime_label} *</label><br />
                            <DropDownList
                              disabled={dataItem.IsUnlimited}
                              style={{ backgroundColor: "white" }}
                              data={playTime}
                              name="PlayTime"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.PlayTime}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>}
                    </div>
                    <div className="col-6">
                      <div className="row">
                        {dataItem.IsExclusive && <div className="col-6">
                          <label htmlFor="">{lang.start_date_label} *</label>
                          <input type={'date'} name={"ExclusiveStartDate"} value={dataItem.ExclusiveStartDate} onChange={onChange} max={dataItem.ExclusiveEndDate} className="form-control-sm"
                            style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px' }} label={lang.start_date_label}
                          />
                        </div>}

                        {dataItem.IsExclusive && <div className="col-6">
                          <label htmlFor="">{lang.end_Date_label} *</label>
                          <input type={'date'} name={"ExclusiveEndDate"} onChange={onChange} value={dataItem.ExclusiveEndDate} min={dataItem.ExclusiveStartDate} className="form-control-sm"
                            style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px' }} label={lang.end_Date_label}
                          />
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>}
    </div>
  </>)
}

export default MediaEpisodePublishingEditForm
