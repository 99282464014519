/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { AUTOMATION, DAYS, ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE, MONTH, OFFSET, PROGRAMSCHEDULEDRAFT_SAVETYPE, SCHEDULETYPE } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import { utility } from "../../../framework/utility/utilityProvider";

import { Menubar } from 'primereact/menubar';
import { UserPreference } from "../../userPreference/UserPreference";
import CheckListPreference from "../../CheckList/CheckListPreference";
import contextMenuItem from "../../../components/contextMenuItem";
import * as API from "../../../framework/API/api"; 
import OpenScheduleDraftDialog from "./OpenScheduleDraftDialog";
import { toast } from "react-toastify";

export const ScheduleHeaderNew = (props) => {
    const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
    const applicationConfigurationValue = utility.getValue(LOCALSTORAGE_KEY.applicationConfiguration);
    const enableScheduleInsertLiveEvent = applicationConfigurationValue ? (applicationConfigurationValue.find(x => x.Key == 'EnableScheduleInsertLiveEvent')?.Value ?? false) : false;
    const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
    const offsetTime = offSetHours * 3600000;
    const lang = useTranslation();
    const {
        date,
        selectedChannel,
        channelList,
        handleFilterDialog,
        onDateChange,
        onScheduleRefresh,
        onDeleteAllFooter,
        onMatchPreviousDayEndTime,
        onSchedulePreference,
        onDeleteAllSecondary,
        onAppleStyleSheet,
        onShowGlobalReplacement,
        onSetDoOnCheckList,
        onExportSchedule,
        onExportAnnouncement,
        statusInfo,
        onFillUnderOverRun,
        scheduleTimeInfo,
        schedulerData,
        onAutomaticBreakDistribution,
        selectedState,
        lock,
        onScheduleLock,
        lockStatus,
        onAutoFillBookedCommercial,
        onReturnSlotDateTimeToPlannedNominalTime,
        showBreakOnly,
        setShowBreakOnly,
        onAutoPlotPromo,
        clientInfo,
        onResetAutoFillPlaylistChildren,
        setShowGoToSchedulePopup,
        onHeaderExpandChange,
        onCut,
        onCopy,
        onPaste,
        onDelete,
        deleteSelectedSecondaryEvent,
        showSecondaryEventOverviewDialogProp,
        showInsertBreakPopup,
        onRevertVirtualSegmentation,
        handleLiveEventPopup,
        handleScheduleDraftPopup,
        loadDraftdata,
        setIsDraftMode,
        isDraftMode,
        onOpenPlanning
    } = props

    const date_ttc_milisecond = date.getTime();//   utility.getLocalDateTimeToUtcMiliseconds(date)

    const [showPreferences, _setShowPreferences] = useState(false);
    const [draftData, setDraftData] = useState(null);
    const showPreferencesRef = useRef(false);
    const setShowPreferences = (value) => {
        showPreferencesRef.current = value;
        _setShowPreferences(value);
    }

    const [showCheckListPreference, setShowCheckListPreference] = useState(false);
    const [showOpenDraftPopup,setShowOpenDraftPopup] = useState(false);
    

    useEffect(() => {
    }, []);

    const otherIconStyles = { textAlign: "center", fontSize: "1.12em", color: "#444746", size: "0.6em", cursor: "pointer" };

    const onExport = (automation, doSftp = false) => {

        if (selectedChannel.isCheckListMandatory) {
            onSetDoOnCheckList();
            return;
        }

        onExportSchedule(automation, selectedChannel, doSftp);

    }

    const handleShowLogs = () => {
        const path = ('/home/AuditLog' + "?module=" + MODULE.SCHEDULING + "&sloatDateTime=" + date?.getTime() + "&channel=" + selectedChannel?.SID + "&title=" + selectedChannel?.FullChannelName)
        window.open(path, "MyTargetWindowName");
    }

    const onCloseUserPrefence = (isSave) => {
        setShowPreferences(!showPreferences);
        console.log(isSave);
        if (isSave == true) {
            onScheduleRefresh();
        }
    }

    const handlePublishDraftData = async () => {

        
        //VALIDATION
        const currentDateMillisec = utility.getLocalDateTimeToUtcMiliseconds(new Date());

        if(currentDateMillisec >= draftData.ScheduleDate){
            toast.info(lang.restore_schedule_draft_validation_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }


        let payload = { 
            data : draftData
        }

        let draftPublishRes = await API.publishProgramScheduleDraft(payload);

        if(draftPublishRes.success) {
            setIsDraftMode(false);
            setDraftData(null);
            onScheduleRefresh();
            toast.success(draftPublishRes.message, {
                position: toast.POSITION.TOP_RIGHT,
            });

            //CAPTURE LOG HERE
            let logData = { 
                event: LOGEVENT.RESTORE_SCHEDULE_DRAFT, 
                module: MODULE.SCHEDULING, 
                data: draftData, 
                message: `Schedule Draft Restore from ${draftData.SaveType == PROGRAMSCHEDULEDRAFT_SAVETYPE.BOTH ? 'database/file' : draftData.SaveType == PROGRAMSCHEDULEDRAFT_SAVETYPE.FILE ? 'file' : 'database'}` 
            };
     
            API.SaveLogs(logData);

            
        } else {
            toast.error(draftPublishRes.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
        }

    }

    const handleOpenScheduleDraftPopup = () => {
        setShowOpenDraftPopup(!showOpenDraftPopup);
    }

    const items = [
        {
            label: 'File',
            items: [
                {
                    icon: 'pi pi-plus',
                    label: 'New Schedule',
                    command: () => {
                        window.open("/home/Scheduling", "_blank");
                    },
                    template: contextMenuItem,
                },
                {
                    icon: 'pi pi-folder-open',
                    label: 'Open Draft',
                    command: async () => {
                        handleOpenScheduleDraftPopup();
                    },
                    template: contextMenuItem,
                },
                {
                    icon: 'pi pi-save',
                    label: 'Save As Draft',
                    command: () => {
                        handleScheduleDraftPopup();
                    },
                    template: contextMenuItem,
                },
                {
                    label: lock ? 'Unlock Schedule' : 'Lock Schedule',
                    icon: lock ? 'fa fa-lock fa-lg ' : 'fa fa-lock-open',
                    iconColor: lockStatus.isSelfLocked ? 'green' : lock ? '#B70404' : null,
                    title: lock && lang.locked_by_label + lockStatus.lockedBy.name,
                    command: () => {
                        onScheduleLock();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Open Planning',
                    icon: 'pi pi-desktop',
                    shortcut: 'F12',
                    command: () => {
                        onOpenPlanning();
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Show Logs",
                    icon: 'pi pi-database',
                    command: () => {
                        handleShowLogs();
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Export",
                    icon: 'pi pi-download',
                    items: [
                        {
                            label: 'Export Playlist',
                            icon: 'pi pi-download',
                            command: () => {
                                onExport(selectedChannel.Automation.SID);
                            },
                            template: contextMenuItem,
                        },
                        {
                            label: 'Export SFTP Playlist',
                            icon: 'pi pi-download',
                            command: () => {
                                onExport(selectedChannel.Automation.SID, true);
                            },
                            template: contextMenuItem,
                        },
                        {
                            label: 'Download PDF',
                            icon: 'pi pi-file-pdf',
                            command: () => {
                                onExport(AUTOMATION.PDF)
                            },
                            template: contextMenuItem,
                        },
                        {
                            label: 'Export Excel',
                            icon: 'pi pi-file-excel',
                            command: () => {
                                onExport(AUTOMATION.EXCEL)
                            },
                            template: contextMenuItem,
                        },
                        {
                            label: 'Export Announcement',
                            icon: 'pi pi-download',
                            command: () => {
                                onExportAnnouncement();
                            },
                            template: contextMenuItem,
                        },
                        clientInfo?.ClientName?.toLowerCase() == 'kbc' && {
                            label: 'Export Jazler Commercial',
                            icon: 'pi pi-download',
                            command: () => {
                                onExport(AUTOMATION.JAZLERCOMMERCIALBREAK)
                            },
                            template: contextMenuItem,
                        },
                    ]
                },

            ]
        },
        {
            label: 'Edit',
            items: [
                {
                    label: 'Cut',
                    icon: 'fa fa-cut',
                    shortcut: 'Ctrl + X',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onCut();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Copy',
                    icon: 'pi pi-copy',
                    shortcut: 'Ctrl + C',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onCopy();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Paste',
                    icon: 'fa fa-paste',
                    shortcut: 'Ctrl + V',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onPaste();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    shortcut: 'del',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onDelete();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Insert Break',
                    icon: 'pi pi-envelope',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: (e) => {
                        showInsertBreakPopup(e);
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Find and Replace',
                    icon: 'pi pi-globe',
                    shortcut: 'Ctrl + H',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onShowGlobalReplacement();
                    },
                    template: contextMenuItem,
                },
                enableScheduleInsertLiveEvent && {
                    label: 'Insert Live Event',
                    icon: 'pi pi-eye',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        handleLiveEventPopup();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Revert Virtual Segmentation',
                    icon: 'pi pi-box',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onRevertVirtualSegmentation();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Delete All Footer',
                    icon: 'pi pi-trash',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onDeleteAllFooter();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Match Previous Day End Time',
                    icon: 'pi pi-clock',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onMatchPreviousDayEndTime(scheduleTimeInfo);
                    },
                    template: contextMenuItem,
                },

            ].filter(Boolean)
        },
        {
            label: 'View',
            items: [
                {
                    icon: 'pi pi-link',
                    label: 'Go To',
                    command: () => {
                        setShowGoToSchedulePopup(true);
                    },
                    shortcut: 'Ctrl + B',
                    template: contextMenuItem,
                },
                {
                    icon: 'pi pi-refresh',
                    label: 'Refresh Schedule',
                    disabled: isDraftMode,
                    shortcut: 'F5',
                    command: () => {
                        onScheduleRefresh();
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Show Header Only",
                    icon: 'pi pi-eye',
                    command: () => {
                        // onShowHeaderOnly();
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Show Breaks Only",
                    icon: showBreakOnly ? 'pi pi-eye-slash' : 'pi pi-eye',
                    iconColor: showBreakOnly ? '#B70404' : 'black',
                    command: () => {
                        setShowBreakOnly(!showBreakOnly)
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Expand ",
                    icon: 'pi pi-arrows-v',
                    shortcut: 'Ctrl +',
                    command: () => {
                        onHeaderExpandChange({ target: { value: true } });
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Collapse ",
                    icon: 'pi pi-minus',
                    shortcut: 'Ctrl -',
                    command: () => {
                        onHeaderExpandChange({ target: { value: false } });
                    },
                    template: contextMenuItem,
                },
            ]
        },
        {
            label: 'Secondary Events',
            items: [
                {
                    label: 'Delete All',
                    icon: 'pi pi-trash',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onDeleteAllSecondary();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Delete Selected',
                    icon: 'pi pi-trash',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        deleteSelectedSecondaryEvent();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'SE Overview',
                    icon: 'pi pi-book',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        showSecondaryEventOverviewDialogProp();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Apply Style Sheet',
                    icon: 'pi pi-palette',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onAppleStyleSheet();
                    },
                    template: contextMenuItem,
                },
            ]
        },
        {
            label: 'Run',
            items: [
                {
                    label: 'Auto Fill Promo',
                    icon: 'pi pi-angle-double-up',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onAutoPlotPromo();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Auto Fill Booked Spots',
                    icon: 'pi pi-video',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onAutoFillBookedCommercial();
                    },
                    template: contextMenuItem,
                },
                {
                    label: ' Fill Playlist',
                    icon: 'pi pi-list',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onFillUnderOverRun(scheduleTimeInfo);
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Reset Auto Fill',
                    icon: 'pi pi-undo',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onResetAutoFillPlaylistChildren();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Reset Header To Nominal',
                    icon: 'pi pi-clock',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onReturnSlotDateTimeToPlannedNominalTime();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Create Automatic Breaks',
                    icon: 'pi pi-desktop',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onAutomaticBreakDistribution();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Check Lists',
                    icon: 'pi pi-check-square',
                    disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader,
                    command: () => {
                        onSetDoOnCheckList();
                    },
                    template: contextMenuItem,
                },
            ]
        },
        {
            label: 'Settings',
            items: [


                {
                    label: 'Schedule Preference',
                    icon: 'pi pi-cog',
                    command: () => {
                        onSchedulePreference();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Column Preference',
                    icon: 'pi pi-pause',
                    command: () => {
                        onCloseUserPrefence();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Checklist Preference',
                    icon: 'pi pi-check-circle',
                    command: () => {
                        setShowCheckListPreference(true);
                    },
                    template: contextMenuItem,
                },
            ]
        },
        {
            icon: lock ? 'fa fa-lock fa-lg ' : 'fa fa-lock-open',
            iconColor: lockStatus.isSelfLocked ? 'green' : lock ? '#B70404' : null,
            title: lock && lang.locked_by_label + lockStatus.lockedBy.name,
            command: () => {
                onScheduleLock();
            },
            template: contextMenuItem,
        },
        isDraftMode && {
            label: 'Exit Draft Mode',
            color: "red",
            command: () => {
                setIsDraftMode(false);
                setDraftData(null);
                onScheduleRefresh();
            },
            template: contextMenuItem,
        },
        isDraftMode && {
            label: 'Restore',
            color: "green",
            command: async() => {

                await handlePublishDraftData()
                
            },
            template: contextMenuItem,
        }
    ];

    return <>

        <div className="row mt-2 mb-3" style={{ fontFamily: "Roboto" , width: "100%" }}>

            <div className="col-8" >
                <div className="flex-container" style={{ alignItems: "left" }}>
                    <div style={{ zIndex: 100 }}>
                        <h3 className="page-edit-title txt-color-bludeDark" style={{ margin: '0px 10px 0px 0px', fontSize: '18px' }}>
                            <i className="fa-regular fa-calendar-days" style={{ margin: '0px 5px 0px 0px' }}></i>Schedule<span style={{ color: "green", fontSize: "13px" }}>{isDraftMode && "- Draft Mode"}</span>
                        </h3>
                        <Menubar style={{ height: "40px", zIndex: 100, background: "transparent", border: "none", marginLeft: "-8px", padding: '8px 8px 16px 0px' }} model={items} />
                    </div>
                    {/* uncomment this section*/}
                    {/* <div title={lang.refresh_button_tooltip} className="iconBase">
                        <RefreshIcon style={otherIconStyles} onClick={onScheduleRefresh} />
                    </div> */}
                    {/* uncomment this section*/}
                    {/* <div title={lang.export_playlist_button_tooltip} className="iconBase">
                        <DownloadIcon style={otherIconStyles} onClick={() => {
                            onExport(selectedChannel.Automation.SID)

                        }} />
                    </div>
                    {clientInfo?.ClientName?.toLowerCase() == 'kbc' && <div title={lang.export_jazzler_automation_playlist} className="iconBase">
                        <DownloadForOfflineIcon style={otherIconStyles} onClick={() => {
                            onExport(AUTOMATION.JAZLERCOMMERCIALBREAK)
                        }} />
                    </div>}
                    <div title={lang.export_and_sftp_playlist_button_tooltip} className="iconBase">
                        <UploadIcon style={otherIconStyles} onClick={() => {
                            onExport(selectedChannel.Automation.SID, true)
                        }} />
                    </div> */}

                    {/* <div title={lang.export_planet_cast_button_tooltip} className="iconBase">
                            <AssignmentReturnedIcon style={otherIconStyles} onClick={() => onExport(selectedChannel.AutomationSID)} /> 
                        </div> */}

                    {/* uncomment this section*/}
                    {/* <div title={lang.download_pdf_button_tooltip} className="iconBase">
                        <PdfIcon style={otherIconStyles} onClick={() => onExport(AUTOMATION.PDF)} />
                    </div>

                    <div title={'Excel Export'} className="iconBase">
                        <DisabledByDefaultIcon style={otherIconStyles} onClick={() => onExport(AUTOMATION.EXCEL)} />
                    </div> */}

                    {/* onClick={() => onExport(AUTOMATION.AMAGI_AS_RUN)} className="iconBase fa fa-file-excel pb-2" style={{ ...otherIconStyles, display: 'flex', alignItems: 'center' }} */}
                    {/* <div title={lang.export_csv_button_tooltip} className="iconBase">
                        <ArticleIcon style={otherIconStyles} onClick={() => onExport(AUTOMATION.AMAGI_AS_RUN)} />
                    </div> */}

                    {/* uncomment this section*/}
                    {/* <div title={lang.open_planning_button_tooltip} className="iconBase">
                        <OpenIcon style={otherIconStyles} onClick={onOpenPlanning} />
                    </div> */}

                    {/* <div title={lang.delete_all_footer_button_tooltip} className="iconBase">
                        <DeleteFooterIcon style={otherIconStyles} onClick={onDeleteAllFooter} />
                    </div> */}

                    {/* <div title={lang.delete_All_secondary_events_button_tooltip} className="iconBase">
                        <DeleteAllSecondaryEventIcon style={otherIconStyles} onClick={onDeleteAllSecondary} />
                    </div> */}

                    {/* <div title={lang.apply_style_sheet_button_tooltip} className="iconBase">
                        <ApplyStyleSheetIcon style={otherIconStyles} onClick={onAppleStyleSheet} />
                    </div> */}

                    {/* <div title={lang.global_replacement_button_tooltip} className="iconBase">
                        <ChangeCircle style={otherIconStyles} onClick={onShowGlobalReplacement} />
                    </div> */}

                    {/* <div title={lang.check_list_button_tooltip} className="iconBase">
                        <AssignmentTurnedInIcon style={otherIconStyles} onClick={onSetDoOnCheckList} />
                    </div> */}

                    {/* <div title={lang.fill_under_run_button_tooltip} className="iconBase">
                        <FactCheckIcon style={otherIconStyles} onClick={onFillUnderOverRun} />
                    </div> */}

                    {/* <div title={lang.resetautofill_button_tooltip} className="iconBase">
                        <ResetAutoFillIcon style={otherIconStyles} onClick={onResetAutoFillPlaylistChildren} />
                    </div> */}

                    {/* <div title={lang.schedule_automatic_break_tooltip} className="iconBase">
                        <VideoSettingsIcon style={otherIconStyles} onClick={onAutomaticBreakDistribution} />
                    </div> */}

                    {/* <div title={lang.schedule_preference_button_tooltip} className="iconBase">
                        <SettingsSuggestIcon style={otherIconStyles} onClick={onSchedulePreference} />
                    </div> */}

                    {/* <div title={lang.schedule_autofill_button_tooltip} className="iconBase">
                        <FormatColorFill style={otherIconStyles} onClick={onAutoFillBookedCommercial} />
                    </div> */}

                    {/* <div title={lang.schedule_reset_all_header_to_nominal_start_time_button_tooltip} className="iconBase">
                        <ReturnToNominalTimeIcon style={otherIconStyles} onClick={onReturnSlotDateTimeToPlannedNominalTime} />
                    </div> */}

                    {/* <div title={lang.auto_plot_interstitial_label} className="iconBase">
                        <LocalDrinkIcon style={otherIconStyles} onClick={onAutoPlotPromo} />
                    </div> */}

                    {/* <div title={lang.export_announcement_tooltip} className="iconBase">
                        <CampaignIcon style={otherIconStyles} onClick={() => {
                            onExportAnnouncement()
                        }} />
                    </div> */}

                    {/* <div title={showBreakOnly ? lang.show_all_label : lang.show_break_only_label} className="iconBase" >
                        <i style={{ ...otherIconStyles, fontSize: "1em", size: "0.4em", color: showBreakOnly ? 'green' : 'black', transform: "rotate(90deg)" }} className={'fa fa-sliders'} onClick={() => setShowBreakOnly(!showBreakOnly)} />
                    </div> */}

                    {/* <div title={lang.show_logs_label} className="iconBase" >
                        <i style={{ ...otherIconStyles, fontSize: "0.9em", size: "0.4em", }} className={'fa fa-external-link'} onClick={handleShowLogs} />
                    </div> */}
                </div>

                <div className="headerPostion">
                    <span className="headerText p-1" >Start Time : </span> 
                    <span className="headerText p-1" style={(scheduleTimeInfo.StartDateTime != scheduleTimeInfo.PreviousScheduleEndDateTime) ? { color : 'red', marginLeft: '-15px'} : { marginLeft: '-15px'}}>{scheduleTimeInfo.StartDateTime}</span>
                    <span className="headerText p-1">End Time : {scheduleTimeInfo.EndDateTime}</span>
                    {!isDraftMode && <span className="headerText p-1">Previous Day End Time : {scheduleTimeInfo.PreviousScheduleEndDateTime}</span>}
                </div>
            </div>

            <div className="col-2 pull-right">
                <input
                    type="search"
                    className="form-control shadow-inset-2 pt-0 pb-0 right ml-1"
                    id="search"
                    style={{ width: "200px" }}
                    aria-label="type 2 or more letters"
                    placeholder={lang.search_button_text}
                    onChange={props.onSearch}
                    onFocus={(e) => {
                        props.setOutSideFocus(true);
                    }}
                    onBlur={(e) => {
                        props.setOutSideFocus(false);
                    }}
                />
            </div>
            <div className="col" style={{ textAlign: "right", padding: '0px', margin: '0px' }}>


                <div className="row-reverse">
                {isDraftMode ?
                    <div className="col">
                            <div className="row-reverse" style={{}}>
                                <span style={{ fontFamily: "Roboto", fontWeight: "bold", fontSize: "18px", color: "#4b4d4d" }} >{draftData?.Channel?.FullChannelName ?? ''} </span>
                            </div>
                            <div>
                                {/* Tuesday, January 5, 2022. */}
                                <span style={{ fontFamily: "Roboto", fontSize: "16px", color: "#4b4d4d" }}>{new Date(draftData.ScheduleDate).getUTCDate()} {MONTH[new Date(draftData.ScheduleDate).getUTCMonth()]} {new Date(draftData.ScheduleDate).getUTCFullYear()}, {DAYS[new Date(draftData.ScheduleDate).getDay()].SubName}</span>
                            </div>
                        </div> : <div className="col">
                        <div className="row-reverse" style={{}}>
                            <span onClick={handleFilterDialog} style={{ fontFamily: "Roboto", fontWeight: "bold", fontSize: "18px", color: "#4b4d4d", cursor: "pointer" }} >{selectedChannel.name} </span>
                        </div>
                        <div>
                            <span> <i className="fa-lg fa-solid fa-square-caret-left fa-md pt-1 mr-1 pointer" onClick={() => onDateChange(false)}></i> </span>
                            {/* Tuesday, January 5, 2022. */}
                            <span className="pointer" onClick={handleFilterDialog} style={{ fontFamily: "Roboto", fontSize: "16px", color: "#4b4d4d" }}>{date.getUTCDate()} {MONTH[date.getUTCMonth()]} {date.getUTCFullYear()}, {DAYS[date.getDay()].SubName}</span>
                            <span style={{ padding: '0px', margin: '0px', color: "#4b4d4d" }} />
                            <span> <i className="fa-lg fa-solid fa-square-caret-right fa-md pt-1 ml-1 pointer" onClick={() => onDateChange(true)}></i> </span>
                        </div>


                    </div>}
                </div>

            </div>

        </div>

        {showPreferences && <UserPreference entityName={ENTITYNAME.SCHEDULE_MAIN_GRID} handleClose={onCloseUserPrefence} />}
        {showCheckListPreference && <CheckListPreference closeForm={() => setShowCheckListPreference(false)} />}
        {showOpenDraftPopup && <OpenScheduleDraftDialog loadDraftdata = {loadDraftdata} setIsDraftMode = {setIsDraftMode} setDraftData={setDraftData} onClose = {handleOpenScheduleDraftPopup} channelList={channelList} selectedChannel = {selectedChannel} date={date} />}


        {/* horizontal Divider        */}
        {/* <div className="row mb-3" style={{ margin: "0px 0px 0px 0px", width: "99%", borderBottom: '1px solid black', borderRadius: '5px!important' }} >
        </div> */}

    </>
}




