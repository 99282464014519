/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import * as API from "../../framework/API/api";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import MediaEpisodePublishingEditForm from "../MediaLibrary/MediaEpisodePublishingEditForm";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { ENTITYNAME, LOGEVENT, MEDIACATEGORIES, MODULE, PUBLISHING_SOURCE } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import moment from "moment";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import ContractCollectionSelection from "../Contract/ContractCollectionSelection";
import InfoRoundButton from "../../components/InfoRoundButton";
import { ContentEditForm } from "../masters/ContentEditForm";
import { GenreEditForm } from "../masters/GenreEditForm";
import { ConfirmAlert } from "../../ConfirmAlert";
import MediaEpisodeMataDataEditForm from "../MediaLibrary/MediaEpisodeMataDataEditForm";
import SpotifyEditForm from "../MediaLibrary/SpotifyEditForm";

export const MediaEpisodeInterstitialEditForm = (props) => {
  const navigate = useNavigate();
  const lang = useTranslation();
  const loc = useLocation();
  const { SID } = useParams();
  const [mediaEpisodeEntity, setMediaEpisodeEntity] = useState({});
  const [mediaEpisodePublishings, setMediaEpisodePublishings] = useState([]);
  const [tabNumber, setTabNumber] = React.useState("1");
  const [disableSave, setDisableSave] = useState(SID > 0 ? false : true);
  const [hideManualId, setHideManualID] = useState(true);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [content, setContent] = useState([]);
  const [mediaCategory, setMediaCategory] = useState([]);
  const [mediaCategoryType, setMediaCategoryType] = useState([]);
  const [genre, setGenre] = useState([]);
  const [restrictedGenre, setRestrictedGenre] = useState([]);
  const [versions, setVersions] = useState([]);
  const [variance, setVariance] = useState([]);
  const [openImageBank, setOpenImageBank] = useState(false);
  const [displayDuration, setDisplayDuration] = useState(0);
  const [removedMedia, setRemovedMedia] = useState([]);
  const [updatedMedia, setUpdatedMedia] = useState([]);
  const [filterMediaCategoryType, setFilterMediaCategoryType] = useState([]);
  const [filterContent, setFilterContent] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState(
    "https://media.comicbook.com/files/img/default-movie.png"
  );
  //on create new entity and edit quickEdit is false but on quickEdit click quickEdit is true
  const [openForm, setFormOpen] = useState(
    props.SID > 0 ? props.quickEdit : !props.quickEdit
  );
  const [mediaEpisodeDescription, setMediaEpisodeDescription] = useState("");
  const [isManualAssetId, setIsManualAssetId] = useState(false);

  const [mediaOpen, setMediaOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({ Title: "" });
  const [source, setSource] = useState('');
  const [oldDuration, setOldDuration] = useState(0);
  const defaultFormState = {
    Content: false,
    Genre: false,
    RestrictedGenre: false,
  }
  const [mediaEpisodeMetaData, setMediaEpisodeMetaData] = useState({
    PGRating: {},
    CensorRating: {},
    MediaTechnicalPrototype: {},
    Languages: [],
    Keywords: '',
    ProductionYear: "",
    Synopsis: [],
    CastAndCrew: [],
    MamID: '',
  });
  const [showEditForm, setShowEditForm] = useState(defaultFormState);
  const [showSpotifyForm, setShowSpotifyForm] = useState(false);

  let disbaled = loc?.state?.copy ? false : SID > 0 ? true : false;
  // const [disbaled] = useState(SID > 0)



  let blankDataItem = {
    AssetId: "",
    Title: "",
    Content: {},
    Description: "",
    MediaCategory: {},
    MediaCategoryType: {},
    Version: {},
    Genres: [],
    RestrictedGenres: [],
    Variance: {},
    TcIn: '00:00:00:00',
    TcOut: '00:00:00:00',
    Duration: "00:00:00:00",
    EpisodeNumber: 0,
    Brand: {},
    BroadcasterId: "",
    Product: {},
    IsBreak: false,
    Artist: "",
    TXReady: false,
    Archive: false,
  };

  const [dataItem, setDataItem] = useState(blankDataItem);

  useEffect(() => {
    loadCombo();
    setMediaEpisode();
    if (SID > 0) {
      loadEditData();
      setMediaEpisode();
      setHideManualID(loc?.state?.copy ? true : false);
    }
  }, []);

  const setMediaEpisode = async () => {
    var mediaCategoryData = await API.getData(ENTITYNAME.MediaCategory, { sort: { SID: 1 } });

    if (props.mediaCategory == undefined) {
      setMediaEpisodeDescription('Break');
      return
    }

    var mediaCategory = mediaCategoryData.data.find((item) => item.SID == props.mediaCategory);
    setMediaEpisodeDescription(mediaCategory.Description);
  }

  const loadCombo = async () => {
    var content = await API.getDataLookup(ENTITYNAME.Content, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    var filteredContent = props.IsBreak ? content.data : content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == props.mediaCategory)
    setContent(filteredContent);
    setFilterContent(filteredContent);

    var mediaCategoryData = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    var filterMedia = mediaCategoryData.data.filter((obj) => obj.isLong === false);
    setMediaCategory(filterMedia);

    if (SID == 0) {
      var selectedMediaCategory = filterMedia.find((obj) => obj.SID == props.mediaCategory);
      blankDataItem.MediaCategory = selectedMediaCategory;
    }


    var mediaCategoryType = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    var mediaCategoryTypeFiltered = props.IsBreak ? mediaCategoryType.data : mediaCategoryType.data.filter((item) => item.MediaCategorySID == props.mediaCategory);
    setMediaCategoryType(mediaCategoryTypeFiltered);
    setFilterMediaCategoryType(mediaCategoryTypeFiltered);

    if (SID == 0 && mediaCategoryTypeFiltered.length > 0) {
      blankDataItem.MediaCategoryType = mediaCategoryTypeFiltered[0];
    }


    var genres = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
    setGenre(genres.data);

    var versions = await API.getDataLookup(ENTITYNAME.Version, { sort: { Name: 1 } });
    setVersions(versions.data);

    let varianceRes = await API.getDataLookup(ENTITYNAME.Variance, { sort: { Description: 1 } });
    setVariance(varianceRes.data)

    var brand = await API.getDataLookup(ENTITYNAME.Brand, { sort: { Name: 1 } });
    setBrands(brand.data);

    var product = await API.getDataLookup(ENTITYNAME.Product, { sort: { Name: 1 } });
    setProducts(product.data);

    var restrictedgenres = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
    setRestrictedGenre(restrictedgenres.data)


    if (SID == 0) {

      setDataItem({
        ...dataItem,
        Genres: Object.keys(utility.getDefaultItem(genres.data)).length > 0 ? [utility.getDefaultItem(genres.data)] : [],
        Version: props.mediaCategory == MEDIACATEGORIES.Interstitial ? utility.getDefaultItem(versions.data) : {},
        Variance: utility.getDefaultItem(varianceRes.data),
        Brand: props.mediaCategory == MEDIACATEGORIES.Commercial ? utility.getDefaultItem(brand.data) : {},
        Product: props.mediaCategory == MEDIACATEGORIES.Commercial ? utility.getDefaultItem(product.data) : {},

      })
    }
  };
  // console.log(dataItem)

  const loadEditData = async () => {
    var res = await API.getEntity(ENTITYNAME.MediaEpisode, parseInt(SID));
    console.log(loc.state)
    if (res.success) {
      setOldDuration(res.data.Duration);
      setDataItem({
        ...blankDataItem,
        ...res.data,
        Title: loc?.state?.copy ? "Copy of " + res.data.Title : res.data.Title,
        Description: loc?.state?.copy ? "Copy of " + res.data.Description : res.data.Description,
        AssetId: loc?.state?.copy ? "" : res.data.AssetId,
        ["Duration"]: utility.convertMilisecondsToStringWithFrames(
          res.data.Duration
        ),
        Variance: res.data.Variance ?? {},
        Content: loc?.state?.copy ? {} : res.data.Content,
        ["TcIn"]: utility.convertMilisecondsToStringWithFrames(res.data.TcIn),
        ["TcOut"]: utility.convertMilisecondsToStringWithFrames(res.data.TcOut),
      });
      setMediaEpisodeEntity(res.data);
      setMediaEpisodeMetaData(res.data.MetaData ?? {})
      setSelectedMedia(res.data.Media ?? { Title: '' });

      var tempData = [];
      if (res.data.Publishings.length > 0) {
        tempData = res.data.Publishings.map((obj, index) => {
          return {
            ...obj,
            index: index
          }
        })
      } else {
        tempData = res.data.Publishings
      }

      setMediaEpisodePublishings(tempData ?? []);
      setSelectedImageUrl(res.data.ImageUrl);
      setDisplayDuration(Math.round(res.data.Duration / 60000));
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const onChange = async (e) => {

    var value = e.target.value;
    if (e.target.name == "TcIn" || e.target.name == "TcOut" || e.target.name == "Duration") value = utility.validateTimeString(value);

    setDataItem({ ...dataItem, [e.target.name]: value });

    if (e.target.name == "MediaCategory") {
      var mediaCategoryTypeFiltered = mediaCategoryType.filter((item) => item.MediaCategorySID == value.SID);
      setFilterMediaCategoryType(mediaCategoryTypeFiltered);
      setDataItem({ ...dataItem, [e.target.name]: value, Content: {}, MediaCategoryType: {} });

    }
    if (e.target.name == "MediaCategoryType" && props.IsBreak) {
      var filteredContent = content.filter((item) => item.MediaCategoryType.MediaCategorySID == value.SID)
      setFilterContent(filteredContent);
    }

    if (e.target.name === "IsManualAssetId") {
      value ? setIsManualAssetId(true) : setIsManualAssetId(false);
    }
    if (e.target.name == "TXReady") {
      setDataItem({ ...dataItem, TXReady: !dataItem.TXReady });
      console.log(!dataItem.TXReady)
    }
    if (e.target.name === "Content") {
      //check
      const updatedContent = await API.getAndSaveNextNumber(
        value.SID,
        { ...dataItem.Content, isSaveNextnumber: false }
      );
      if (!updatedContent.success) return;
      console.log(!updatedContent.success);
      setDataItem(() => ({
        ...dataItem,
        [e.target.name]: value,
        AssetId: updatedContent.data.HouseNumber,
      }));
    }

    if (e.target.name == "TcOut") {
      var tcOut = utility.convertStringWithFramesToMilliseconds(value);
      var tcIn = utility.convertStringWithFramesToMilliseconds(dataItem.TcIn);

      if (tcOut > tcIn) {
        var duration = utility.convertMilisecondsToStringWithFrames(tcOut - tcIn);
        setDataItem(old => { return { ...old, ["Duration"]: duration } })
      }

    }
    if (e.target.name == "Duration") {
      console.log(value);
      var duration = utility.convertStringWithFramesToMilliseconds(value);
      var tcIn = utility.convertStringWithFramesToMilliseconds(dataItem.TcIn);
      var tcOutRes = utility.convertMilisecondsToStringWithFrames(tcIn + duration);


      setDataItem(old => { return { ...old, ["TcOut"]: tcOutRes } })


    }
    if (e.target.name == "TcIn") {
      var tcOut = utility.convertStringWithFramesToMilliseconds(dataItem.TcOut);
      var tcIn = utility.convertStringWithFramesToMilliseconds(value);

      if (tcOut > tcIn) {
        var duration = utility.convertMilisecondsToStringWithFrames(tcOut - tcIn);
        setDataItem(old => { return { ...old, ["Duration"]: duration } })
      }
    }
  };

  const isValidToSave = async () => {


    if (dataItem.Title == undefined || dataItem.Title === "") {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (!disbaled && (dataItem.Content == undefined || Object.keys(dataItem.Content).length === 0)) {
      toast.error(`${lang.please_select_content_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Description == undefined || dataItem.Description === "") {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (props.mediaCategory == MEDIACATEGORIES.Commercial && (dataItem.Brand == undefined || Object.keys(dataItem.Brand).length === 0)) {
      toast.error(`${lang.please_select_brand_media_episode_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (props.mediaCategory == MEDIACATEGORIES.Commercial && (dataItem.Product == undefined || Object.keys(dataItem.Product).length === 0)) {
      toast.error(`${lang.please_select_product_media_episode_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    // if (props.mediaCategory == MEDIACATEGORIES.Music && (dataItem.Artist == undefined || dataItem.Artist === "")) {
    //   toast.error(`${lang.please_enter_artist_error_message}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }

    if (dataItem.TcIn == undefined || dataItem.TcIn.includes("_")) {
      toast.error(`${lang.please_enter_value_media_episode_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.TcOut == undefined || dataItem.TcOut.includes("_") || dataItem.TcIn > dataItem.TcOut) {
      toast.error(`${lang.please_enter_valid_value_tcin_should_not_be_greater_than_tcout_media_episode_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Duration == undefined || dataItem.Duration.includes("_") || dataItem.Duration.includes("00:00:00:00")) {
      toast.error(`${lang.invalid_duration_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (props.mediaCategory == MEDIACATEGORIES.Commercial && SID > 0 && oldDuration != utility.convertStringWithFramesToMilliseconds(dataItem.Duration)) {

      var canChangeCommercialDuration = await API.canChangeCommercialDuration(dataItem);
      console.log(canChangeCommercialDuration)
      if (canChangeCommercialDuration.success && !canChangeCommercialDuration.canChange) {
        toast.error(`${lang.commercial_used_in_booking_cannot_change_duration_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

    }

    if (props.mediaCategory == MEDIACATEGORIES.Music && (mediaEpisodeMetaData.CastAndCrew && mediaEpisodeMetaData.CastAndCrew.length == 0)) {
      toast.error(`${lang.please_enter_cast_and_crew_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  };

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const handlePublishingData = (data) => {
    setMediaEpisodePublishings(data);
  };

  // const handleAddNewPublishingData = (dataItem) => {
  //   setMediaEpisodePublishings(old => [...old, dataItem]);
  //   setUpdatedMedia(old => [...old, dataItem]);
  // }

  // const handleEditPublishingData = (dataItem) => {
  //   setMediaEpisodePublishings(old => 
  //     {
  //       old[dataItem.index] = dataItem;
  //       return [...old];
  //     });
  //   setUpdatedMedia(old => [...old, dataItem]);
  // }

  // const handleRemovePublishingData = (removedDataItem) => {
  //   console.log(removedDataItem);
  //   setMediaEpisodePublishings(old => old.filter(x => x != removedDataItem));
  //   setRemovedMedia(old => [...old, removedDataItem]);
  // }



  const handleSetDisableSave = (value) => {
    setDisableSave(value);
  };

  const setSelectedImage = (dataList) => {
    setSelectedImageUrl(() => dataList[0].ImageUrl);
    setDataItem({ ...dataItem, ["ImageUrl"]: selectedImageUrl });
  };

  const onSaveData = async (isValid, logMsg) => {

    //IF MANUAL ASSET ID IS GIVEN
    var assetID = dataItem.IsManualAssetId && parseInt(SID) == 0 ? dataItem.ManualAssetId : dataItem.AssetId;


    // CHECK ON ADD ONLY AND IS MANUAL ASSETID IS TRUE
    if (parseInt(SID) == 0 && dataItem.IsManualAssetId) {

      var IsValidAssetId = await isValidAssetId();

      if (IsValidAssetId) {
        toast.error(assetID + `${lang.already_exists_global_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

    }

    if (isValid) {

      // check
      if ((!dataItem._id || loc?.state?.copy) && !dataItem.IsManualAssetId) {
        //fresh AssetID
        const updatedContent = await API.getAndSaveNextNumber(
          dataItem.Content.SID,
          { ...dataItem.Content, isSaveNextnumber: true }
        );
        assetID = updatedContent.data.HouseNumber;
        if (!updatedContent.success) {
          toast.error(`${lang.error_while_generating_house_number_media_episode_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        }
      }

      let saveData = {
        ...dataItem,
        SID: loc?.state?.copy ? 0 : parseInt(SID),
        AssetId: assetID,
        Title: dataItem.Title,
        Content: dataItem.Content,
        Description: dataItem.Description,
        MediaCategory: props.IsBreak ? dataItem.MediaCategory : mediaCategory.find((obj) => obj.SID == props.mediaCategory),
        MediaCategoryType: dataItem.MediaCategoryType ? dataItem.MediaCategoryType : mediaCategoryType.find((obj) => obj.SID == props.mediaCategory),
        Version: dataItem.Version,
        Variance: { _id: dataItem.Variance._id, SID: dataItem.Variance.SID, Description: dataItem.Variance.Description },
        Genres: dataItem?.Genres.length > 0 ? dataItem?.Genres?.map((x) => {
          return { _id: x._id, SID: x.SID, Description: x.Description }
        }) : [],
        TcIn: utility.convertStringWithFramesToMilliseconds(dataItem.TcIn ?? "00:00:00:00"),
        TcOut: utility.convertStringWithFramesToMilliseconds(dataItem.TcOut ?? "00:00:00:00"),
        Duration: utility.convertStringWithFramesToMilliseconds(dataItem.Duration ?? "00:00:00:00"),
        ReleaseDate: new Date(moment(dataItem.ReleaseDate).format("YYYY-MM-DD HH:mm:ss")).getTime() ?? 0,
        ImageUrl: selectedImageUrl,
        RestrictedGenres: dataItem?.RestrictedGenres?.map((x) => {
          return { _id: x._id, SID: x.SID, Description: x.Description }
        }) ?? [],
        Archive: dataItem.Archive ?? false,
        Publishings: mediaEpisodePublishings,
        IsBreak: props.IsBreak,
        TXReady: dataItem.TXReady ? true : false,
      };

      delete saveData.IsManualAssetId
      delete saveData.ManualAssetId

      if (props.mediaCategory == MEDIACATEGORIES.Music) {
        saveData.MetaData = mediaEpisodeMetaData
      }

      loc?.state?.copy && delete saveData._id;
      console.log(saveData);

      const response = await API.saveData(ENTITYNAME.MediaEpisode, saveData);

      if (!response.success) {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      } else {

        if (props.mediaCategory == MEDIACATEGORIES.Interstitial) {
          let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_INTERSTITIAL : LOGEVENT.UPDATE_INTERSTITIAL, module: MODULE.MEDIA_EPISODE_INTERSTITIAL, data: response.data, message: logMsg ? logMsg : response.message };
          API.SaveLogs(logData);
        } else if (props.mediaCategory == MEDIACATEGORIES.Commercial) {
          let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_COMMERCIAL : LOGEVENT.UPDATE_COMMERCIAL, module: MODULE.MEDIA_EPISODE_COMMERCIAL, data: response.data, message: logMsg ? logMsg : response.message };
          API.SaveLogs(logData);
        } else if (props.mediaCategory == MEDIACATEGORIES.Filler) {
          let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_FILLER : LOGEVENT.UPDATE_FILLER, module: MODULE.MEDIA_EPISODE_FILLER, data: response.data, message: logMsg ? logMsg : response.message };
          API.SaveLogs(logData);
        } else if (props.mediaCategory == MEDIACATEGORIES.Music) {
          let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_MUSIC : LOGEVENT.UPDATE_MUSIC, module: MODULE.MEDIA_EPISODE_MUSIC, data: response.data, message: logMsg ? logMsg : response.message };
          API.SaveLogs(logData);
        } else {
          let logData = { event: saveData.SID == 0 ? LOGEVENT.MEDIA_EPISODE_CREATE : LOGEVENT.MEDIA_EPISODE_UPDATE, module: MODULE.MEDIA_EPISODE_BREAK, data: response.data, message: logMsg ? logMsg : response.message };
          API.SaveLogs(logData);
        }

        setFormOpen(false);
        handleSetDisableSave(false);
        navigate(-1);
      }

    } else {
      handleSetDisableSave(true);
    }

  }

  const handleSaveData = async () => {

    var isValid = await isValidToSave();
    // console.log(isValid);
    if (!loc?.state?.copy && parseInt(SID) != 0 && dataItem.Archive) {
      let archiveRes = await API.canArchiveMediaEpisode(dataItem?._id);
      if (archiveRes.success) {
        ConfirmAlert(
          () => {
            onSaveData(isValid, `${dataItem.AssetId} is${archiveRes.message} when archived`)
          },
          () => { return; },
          "Confirm",
          `${archiveRes.message}. It will remain Scheduled. Do you still want to Archive?`
        )
      } else {
        onSaveData(isValid);
      }
    } else {
      onSaveData(isValid);
    }
  };

  const isValidAssetId = async () => {
    var query = [["AssetId", "=", dataItem.ManualAssetId]];
    var res = await API.getData(ENTITYNAME.MediaEpisode, { query: query });
    return res.data.length > 0;
  }

  const openMedia = (e) => {
    if (Object.keys(selectedMedia).length > 1) {
      window.open(`/home/MediaEpisode/${selectedMedia.MediaCategory.Description}/${selectedMedia.SID}`, { state: { copy: false } })
    } else {
      console.log('Select Media');
      toast.info(`${lang.select_media_collection_header}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const handleMediaSelect = (dataList, src) => {
    setSelectedMedia(dataList[0]);
    setSource(src);

    var tempSource = src;

    if (src.Description != 'Series' && src.Description != 'Season') {
      tempSource = { Description: 'mediaepisode' }
    }

    setDataItem({
      ...dataItem,
      Media: { _id: dataList[0]._id, SID: dataList[0].SID, Title: dataList[0].Title, MediaCategory: dataList[0].MediaCategory, entityName: tempSource.Description.toLowerCase() }
    })

  }

  const deleteSelectedMedia = () => {
    setSelectedMedia({ Title: "" });
    setDataItem({ ...dataItem, Media: {} })
  }

  const onCreateMasterClick = (key) => {
    setShowEditForm({
      ...defaultFormState,
      [key]: true
    });
  }

  const handleSetMusicData = async (data) => {
    // id: item.id,
    // Title: item.name,
    // duration: item.duration_ms,
    // explicit: item.explicit,
    // artists: item.artists.map((artist) => ({
    //     name: artist.name,
    // })),
    // Poster: item.album.images.map(p => ({ OttPosterType: { SID: OTT_POSTER_TYPE.Poster }, Url: p.url })),
    // releaseDate: item.album.release_date,
    setDataItem({
      ...dataItem,
      spotifyId: data.id,
      Title: data.Title,
      Description: data.Title,
      Content: filterContent.length > 0 ? filterContent[0] : [],
      Duration: utility.convertMilisecondsToStringWithFrames(data.duration),
    });
    let castTypeRes = await API.getDataLookup(ENTITYNAME.CastType, { sort: { Description: 1 }, query: ["Archive", "!=", true] });
    setMediaEpisodeMetaData({
      ...mediaEpisodeMetaData,
      CastAndCrew: castTypeRes.success ? data.artists.map((artist) => ({
        Name: artist.name,
        CastType: castTypeRes.data[0]
      })) : [],
      ProductionYear: moment(data.releaseDate, 'YYYY-MM-DD').format('YYYY')
    });
  }

  return (
    <>
      <div className="row m-1">
        <div className="col-md-12">
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <EditPageHeader title={(mediaEpisodeEntity.Title ? ` ${mediaEpisodeDescription} -> ${mediaEpisodeEntity.Title}` : ` Create -> ${mediaEpisodeDescription}`)} onSubmit={handleSaveData} onCancel={() => navigate(-1)} />
                <div className="row mt-3">
                  {SID > 0 ? <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="">{lang.asset_id_label} *</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="AssetId"
                        value={dataItem.AssetId}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div> : <div className="col-4">
                    <label style={{ float: "left", marginRight: '5px' }} >{lang.asset_id_label + " :"}</label>
                    <span className='float-right cursor-pointer' style={{
                      backgroundColor: 'gray',
                      color: 'white',
                      padding: '0px 6px',
                      border: '1px solid #ced4da',
                      fontSize: '10px'

                    }} onClick={() => { setShowSpotifyForm(true) }}>
                      Spotify
                    </span>
                    <span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="ManualAssetId"
                        disabled={isManualAssetId ? false : true}
                        value={isManualAssetId ? dataItem.ManualAssetId : dataItem.AssetId}
                        onChange={(e) => onChange(e)}
                      />
                    </span>
                  </div>}

                  {hideManualId &&
                    <div className="col-2">
                      <Field
                        name={"IsManualAssetId"}
                        component={Checkbox}
                        label={lang.manual_assetid_label}
                        onChange={(e) => onChange(e)}
                        checked={dataItem.IsManualAssetId ? true : false}
                      />
                    </div>
                  }
                </div>
                <div className="row mt-2">
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="">{lang.title_label} *</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="Title"
                        value={dataItem.Title}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="">{lang.description_label} *</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="Description"
                        value={dataItem.Description}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  {!disbaled && <> <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="TabView">{lang.content_label} *</label>
                      <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                      <DropDownList
                        data={filterContent}
                        name="Content"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Content}
                        onChange={onChange}
                        disabled={disbaled}
                      />

                    </div>
                  </div>
                    <div className="col-1" style={{ padding: '0px' }}>
                      <button type="button" title='Create Content' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                        style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Content') }}><i className="fa-solid fa-plus fa-xs"></i>
                      </button>
                    </div></>}
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="">{lang.media_category_label}</label>
                      <DropDownList
                        data={mediaCategory}
                        style={{ backgroundColor: 'white' }}
                        name="MediaCategory"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.MediaCategory}
                        onChange={onChange}
                        disabled={props.IsBreak ? false : true}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="TabView">{lang.media_category_type_label}</label>
                      <DropDownList
                        data={filterMediaCategoryType}
                        style={{ backgroundColor: 'white' }}
                        name="MediaCategoryType"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.MediaCategoryType}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="">{lang.genre_label}</label>
                      <MultiSelect
                        data={genre}
                        name={"Genres"}
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Genres}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="col-1" style={{ padding: '0px' }}>
                    <button type="button" title='Create Genre' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                      style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Genre') }}><i className="fa-solid fa-plus fa-xs"></i>
                    </button>
                  </div>

                </div>
                {/* {props.mediaCategory == MEDIACATEGORIES.Music && <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="">{lang.artist_label} *</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="Artist"
                        value={dataItem.Artist}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                </div>} */}
                <div className="row mt-2">
                  {props.mediaCategory == MEDIACATEGORIES.Interstitial &&
                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="">{lang.version_label}</label>
                        <DropDownList
                          data={versions}
                          style={{ backgroundColor: 'white' }}
                          name="Version"
                          textField="Name"
                          dataItemKey="_id"
                          value={dataItem.Version}
                          onChange={onChange}
                        />
                      </div>
                    </div>}
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="">{lang.variance_label}</label>
                      <DropDownList
                        data={variance}
                        style={{ backgroundColor: 'white' }}
                        name="Variance"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Variance}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group ">
                      <label htmlFor="">{lang.restricted_genre_label}</label>
                      <MultiSelect
                        data={restrictedGenre}
                        name={"RestrictedGenres"}
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.RestrictedGenres}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                {props.mediaCategory == MEDIACATEGORIES.Commercial &&
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="">{lang.broadcaster_id_label}</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="BroadcasterId"
                              value={dataItem.BroadcasterId}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.brand_label} *</label>
                            <DropDownList
                              data={brands}
                              style={{ backgroundColor: 'white' }}
                              name="Brand"
                              textField="Name"
                              dataItemKey="_id"
                              value={dataItem.Brand}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.product_label} *</label>
                            <DropDownList
                              data={products}
                              style={{ backgroundColor: 'white' }}
                              name="Product"
                              textField="Name"
                              dataItemKey="_id"
                              value={dataItem.Product}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                <div className="row">
                  <div className="col-2">
                    <div className="form-group">
                      <label htmlFor="">{lang.tc_in_without_timecode_label}</label>
                      <TimePickerWithFormat
                        name="TcIn"
                        value={dataItem.TcIn}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label htmlFor="">{lang.tc_out_without_timecode_label}</label>
                      <TimePickerWithFormat
                        name="TcOut"
                        value={dataItem.TcOut}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label htmlFor="">{lang.duration_without_timecode_label} *</label>
                      <TimePickerWithFormat
                        name="Duration"
                        value={dataItem.Duration}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-4" >
                    <div className="form-group">
                      <label htmlFor="">{lang.media_label}</label>
                      <input
                        name={"Media"}
                        className="form-control form-control-sm"
                        type={'text'}
                        value={selectedMedia.Title}
                        disabled={true}
                        onChange={() => ""}
                      />
                    </div>
                  </div>
                  <button type="button" className="ml-2 k-button k-button-md k-rounded-md k-button-solid k-button-solid-success"
                    style={{ height: "30px", marginTop: "20px" }} title={lang.attach_media_button_tooltip} onClick={(e) => { setMediaOpen(true); e.preventDefault(); }}>
                    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                  </button>
                  {/* button to show media */}
                  <button type="button" title={lang.show_media_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning"
                    style={{ height: "30px", marginTop: "20px", marginLeft: '4px' }} onClick={(e) => { e.preventDefault(); openMedia() }}>
                    <i className="fa-solid fa-eye"></i>
                  </button>
                  <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-info"
                    style={{ height: "30px", marginTop: "20px", marginLeft: '4px' }} title={lang.delete_button_tooltip} onClick={(e) => { deleteSelectedMedia(); e.preventDefault() }}>
                    <i className="fa-solid fa-trash"></i>
                  </button>
                  {/* <div className="col" style={{ padding: '0px',marginLeft: '4px' }}>
                    <button type="button" title={lang.create_genre_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                      style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Genre') }}><i className="fa-solid fa-plus fa-xs"></i>
                    </button>
                  </div> */}

                </div>
                <div className="row">
                  {props.mediaCategory != MEDIACATEGORIES.Filler &&
                    <div className="col-2">
                      <input type="checkbox" name={"TXReady"} value={dataItem.TXReady} checked={dataItem.TXReady} onChange={onChange} /> {lang.tx_ready_label}
                    </div>}
                  <div className="form-check">
                    <Field
                      name={"Archive"}
                      component={Checkbox}
                      label={lang.archive}
                      onChange={(e) => onChange(e)}
                      checked={dataItem.Archive ? true : false}
                    />
                  </div>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>

      {openImageBank && (
        <CollectionSelection
          entityname={ENTITYNAME.OttPosters}
          closeForm={() => setOpenImageBank(!openImageBank)}
          setDataList={setSelectedImage}
          mode={"single"}
          width={"50vw"}
          height={"60vh"}
        />
      )}
      <div className="row">
        <div className="col-12">
          {" "}
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example" >
                  <Tab label={lang.publishing_label} value="1" />
                  {props.mediaCategory == MEDIACATEGORIES.Music && <Tab label={lang.meta_data_label} value="2" />}
                </TabList>
              </Box>
              <TabPanel value={"1"}>
                <MediaEpisodePublishingEditForm
                  publishingSource={PUBLISHING_SOURCE.MediaEpisode}
                  mediaEpisodeEntity={mediaEpisodeEntity}
                  mediaCategory={mediaCategory.find((obj) => obj.SID == props.mediaCategory) ?? { isLong: false }}
                  data={mediaEpisodePublishings}
                  handlePublishings={handlePublishingData}
                />
              </TabPanel>
              {props.mediaCategory == MEDIACATEGORIES.Music && <TabPanel value={"2"}>
                <MediaEpisodeMataDataEditForm mediaData={mediaEpisodeEntity} SID={SID} metaData={mediaEpisodeMetaData} getMetaData={(data) => { setMediaEpisodeMetaData(data) }} expand={() => { }} isFromMusicForm={true} />
              </TabPanel>}
            </TabContext>
          </Box>
        </div>
      </div>
      {mediaOpen && <ContractCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={handleMediaSelect} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setMediaOpen(false)} mode={'single'} width={"50vw"} height={"66vh"} />}

      {showEditForm.Content && <ContentEditForm item={{ SID: 0 }} cancelEdit={() => setShowEditForm(false)} refresh={() => loadCombo()} filterMediaCategory={props.mediaCategory} />}
      {showEditForm.Genre && <GenreEditForm item={{ SID: 0 }} cancelEdit={() => setShowEditForm(false)} refresh={() => loadCombo()} />}
      {showSpotifyForm && <SpotifyEditForm cancelEdit={() => setShowSpotifyForm(false)} setMusicData={handleSetMusicData} />}
    </>
  );
};
