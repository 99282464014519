/* eslint-disable */
import React, { useEffect } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { utility } from "../../framework/utility/utilityProvider";
import { LOCALSTORAGE_KEY, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../../framework/constant/constant";
import { useState } from "react";
import useSocket from "../../framework/socket/useSocket";
import Avatar from "../../components/Avatar";
import { useTranslation } from "../../locale/useTranslation";



const ScheduleFooter = (props) => {
    const [activteUsers, setActivteUsers] = useState([]);
    let user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const lang = useTranslation();
    const {
        statusMessage,
        statusInfo,
        expandToggle,
        onHeaderExpandChange,
        selectedState, //scheduler main Grid
        copyPasteDataItem,
        selectedHeader,
        selectedStateDurationCalculation
    } = props

    const [isMinified, setIsMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified))

    window.addEventListener('isMinified', () => {
        setIsMinified(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
    });

    //for activate user;
    useEffect(() => {

        socket.emit(SOCKET_EVENTS.onSocketUsers, { action: SOCKET_ACTION.ADD_ACTIVE_USER, module: MODULE.SCHEDULING, data: { name: user.name, _id: user._id, color: user.color, selectedScheduleDate: statusInfo.selectedScheduleDate, selectedChannel: statusInfo.selectedChannel } });

        return () => {
            socket.emit(SOCKET_EVENTS.onSocketUsers, { action: SOCKET_ACTION.REMOVE_ACTIVE_USER, module: MODULE.SCHEDULING, data: { name: user.name, _id: user._id } });
        }

    }, [statusInfo]);

    //socket
    const socket = useSocket(SOCKET_EVENTS.onSocketUsers, (socketData) => {

        if (socketData.module != MODULE.SCHEDULING) return; //no need to filter active user from module 

        switch (socketData.action) {
            case SOCKET_ACTION.ADD_ACTIVE_USER:
            case SOCKET_ACTION.REMOVE_ACTIVE_USER:
                let scheduleActiveUsers = socketData.data.filter((item) => item.module == MODULE.SCHEDULING && item.user.selectedChannel.SID == statusInfo.selectedChannel.SID && item.user.selectedScheduleDate == statusInfo.selectedScheduleDate)
                if (scheduleActiveUsers.length > 0) setActivteUsers(scheduleActiveUsers);
                break;
            default:
                break;
        }
    });


    return <div className={isMinified ? "scheduleFooter scheduleFooterMinified" : 'scheduleFooter'}>
        <div className="row mr-2">

            <div className="col-9" >
                <div className="flex-container" style={{ alignItems: "left", justifyContent: "left" }}>

                    <div className="ml-1">
                        {activteUsers?.map((item) => {
                            return <Avatar data={item} />
                        })}
                    </div>

                    {/* <div title="Parent Count" className="iconBase2">
                        <FamilyRestroomIcon style={IconsStyle("#ffffff")} /><span className="footerText">{scheduleInfo.parentCount}</span>
                    </div>

                    <div title="Commercial Count" className="iconBase2" >
                        <LiveTvIcon style={IconsStyle("#ffffff")} /><span className="footerText">{scheduleInfo.commercialCount}</span>
                    </div>

                    <div title="Under and Over Run Count" className="iconBase2">
                        <Error style={IconsStyle("#ffffff")} /><span style={{ color: "white", fontWeight: "400", fontSize: "12px" }}>{scheduleInfo.overRunUnderRunCount}</span>
                    </div> */}

                    <div title={lang.expand_collapse_headers_label} className="iconBase2" >
                        <Switch
                            size={"small"}
                            onLabel={"Clpd"}
                            offLabel={"Expd"}
                            checked={expandToggle}
                            defaultChecked={false}
                            onChange={onHeaderExpandChange}
                        />
                    </div>

                    <div title={lang.parent_label} className="iconBase2"  >
                        <i className="fa fa-person" style={{ color: "white" }} />&nbsp;
                        <span className="footerText">{statusInfo.parentCount}</span>
                    </div>
                    <div title={lang.commercial_label} className="iconBase2" >
                        <i className="fa fa-tv fa-sm" style={{ color: "white" }} />&nbsp;
                        <span className="footerText">{statusInfo.commercialCount}</span>
                    </div>
                    <div title={lang.overrun_underrun_label} className="iconBase2" >
                        <i className="fa fa-up-long fa-2xs" style={{ color: "white" }} />&nbsp;<i className="fa fa-down-long fa-2xs" style={{ color: "white" }} />&nbsp;
                        <span className="footerText">{statusInfo.overRunUnderRunCount}</span>
                    </div>

                    <div title={lang.selected_count_label} className="iconBase2" >
                        <span className="footerText">{selectedState.length}&nbsp;{lang.selected_label}</span>
                    </div>

                    <div title={lang.copy_count_label} className="iconBase2" >
                        <span className="footerText">{copyPasteDataItem}&nbsp;{lang.copied_label}</span>
                    </div>



                    {selectedStateDurationCalculation.Total > 0 && <div title={lang.copy_count_label} className="iconBase2" >
                        <span className="footerText">{'Total : '}&nbsp;{utility.convertMilisecondsToStringWithFrames(selectedStateDurationCalculation.Total)}</span>
                    </div>}

                    {selectedStateDurationCalculation.Segment > 0 && <div title={lang.copy_count_label} className="iconBase2" >
                        <span className="footerText">{'Segment : '}&nbsp;{utility.convertMilisecondsToStringWithFrames(selectedStateDurationCalculation.Segment)}</span>
                    </div>}

                    {selectedStateDurationCalculation.Commercial > 0 && <div title={lang.copy_count_label} className="iconBase2" >
                        <span className="footerText">{'Commercial : '}&nbsp;{utility.convertMilisecondsToStringWithFrames(selectedStateDurationCalculation.Commercial)}</span>
                    </div>}

                    {selectedStateDurationCalculation.Promo > 0 && <div title={lang.copy_count_label} className="iconBase2" >
                        <span className="footerText">{'Promo : '}&nbsp;{utility.convertMilisecondsToStringWithFrames(selectedStateDurationCalculation.Promo)}</span>
                    </div>}

                    {selectedStateDurationCalculation.Filler > 0 && <div title={lang.copy_count_label} className="iconBase2" >
                        <span className="footerText">{'Filler : '}&nbsp;{utility.convertMilisecondsToStringWithFrames(selectedStateDurationCalculation.Filler)}</span>
                    </div>}

                    {selectedStateDurationCalculation.Music > 0 && <div title={lang.copy_count_label} className="iconBase2" >
                        <span className="footerText">{'Music : '}&nbsp;{utility.convertMilisecondsToStringWithFrames(selectedStateDurationCalculation.Music)}</span>
                    </div>}

                    {/* <div title="Copy Count" className="iconBase2" >
                        <span className="footerText">{cutPasteDataItem}&nbsp;Cut</span>
                    </div> */}

                    {selectedHeader?.mediaEpisode && <div title={lang.selected_header_label} className="iconBase2" >
                        <span className="footerText">{lang.selected_header_label} :&nbsp;{selectedHeader.mediaEpisode.Title}</span>
                    </div>}

                    {/* <div title="Copy Count" className="iconBase2" >
                        <CopyAll style={IconsStyle("#ffffff")} /><span className="footerText">{selectedStateForMediaLibrary.length}</span>
                    </div> */}


                </div>
            </div>

            <div className="col-3 mt-2" style={{ textAlign: "right" }}>
                <div className="row">
                    <div className="col">
                        <span className="footerText">{statusMessage}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default ScheduleFooter;