/* eslint-disable */
import { Dialog } from '@progress/kendo-react-dialogs'
import React, { useState, useEffect } from "react";
import { Field, Form,FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox} from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';

function InvoiceContentEditForm(props) {

    const [channel, setChannel] = useState([]);
    const lang=useTranslation();

    const blankDataItem = { ...props.item,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Channel: props.item.Channel?? {},
        Prefix: props.item.Prefix ?? "",
        Suffix: props.item.Suffix ??"",
        NextNumber: props.item.NextNumber ??"",
        NumberLength: props.item.NumberLength ??"",
    }

    useEffect(() => {
        loadcombo();
    }, [])
    
    const [dataItem, setDataItem] = useState(blankDataItem);

    const loadcombo = async() =>{
        let ChannelRes = await API.getDataLookup(ENTITYNAME.Channel,{sort: { Name: 1 }});
        setChannel(ChannelRes.data);
    }

    const onChangeForm = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid=()=>{

        if(dataItem.Name=="" || dataItem.Name==undefined){
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
                });
            return false;
        }
        if(Object.keys(dataItem.Channel).length==0){
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
                });
            return false;
        }
        if(dataItem.Prefix=="" || dataItem.Prefix==undefined){
            toast.error(`${lang.please_enter_prefix_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.Suffix=="" || dataItem.Suffix==undefined){
            toast.error(`${lang.please_enter_suffix_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        } 
        if(dataItem.NextNumber=="" || dataItem.NextNumber==undefined){
            toast.error(`${lang.please_enter_next_number_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        } 
        if(dataItem.NumberLength=="" || dataItem.NumberLength==undefined){
            toast.error(`${lang.please_enter_number_length_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }


    const handleSubmit = async () => {

        if(isValid()){
            const saveData = {                
                _id:dataItem._id,
                SID:dataItem.SID,
                Name:dataItem.Name,
                Channel: {
                    _id:dataItem.Channel._id,
                    SID:dataItem.Channel.SID,
                    FullChannelName: dataItem.Channel.FullChannelName,
                  },
                Prefix:dataItem.Prefix,
                Suffix:dataItem.Suffix,
                NextNumber:dataItem.NextNumber,
                NumberLength: dataItem.NumberLength ,
                Archive: dataItem.Archive ?? false,
                checkDuplicate:true,
                query:[["Name", "=", dataItem.Name]]

            }
            let res = await API.saveData(ENTITYNAME.InvoiceContent, saveData);
            if(res.success){
                props.refresh();
                props.cancelEdit();
                utility.deleteLocalStorageItem('invoicecontent');
                return;
            }
            else{
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
        }
    }


    const cancelEdit = () => {
        props.cancelEdit();
    }

    return (
        <>
         <Dialog
            title={ props.item.SID > 0 ? props.item.Name : `${lang.create_invoice_content_dialog_header}`}
            onClose={cancelEdit}
            width={"740px"}
        >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false}/>
        <Form
            onSubmit={handleSubmit}
            initialValues={dataItem}
            render={(formRenderProps) => (
            <FormElement>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='row mt-2'>
                            <div className='col-6'>
                            <label>{lang.name_label} *</label>
                                <input
                                    name={"Name"}
                                    type = "text" 
                                    style={{border : 'solid lightgrey 1px' , height : "35px"}}
                                    onChange={onChangeForm}
                                    value={dataItem.Name}
                                />
                            </div>
                            <div className='col-6'>
                                <label htmlFor="TabView">{lang.channel_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={channel}
                                    name="Channel"
                                    textField="FullChannelName"
                                    dataItemKey="_id"
                                    value={dataItem.Channel}
                                    onChange={onChangeForm}
                                />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <label>{lang.prefix_label} *</label>
                                <input
                                    name={"Prefix"}
                                    type = "text" 
                                    style={{border : 'solid lightgrey 1px' , height : "35px"}}
                                    onChange={onChangeForm}
                                    value={dataItem.Prefix}
                                />
                            </div>
                            <div className='col-6'>
                            <label>{lang.suffix_label} *</label>
                            <input
                                name={"Suffix"}
                                type = "text" 
                                style={{border : 'solid lightgrey 1px' , height : "35px"}}
                                onChange={onChangeForm}
                                value={dataItem.Suffix}
                            />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-6'>
                            <label>{lang.next_number_label} *</label>
                            <input
                                className="pl-3"
                                name={"NextNumber"}
                                type = "number" 
                                style={{border : 'solid lightgrey 1px' , width:"100%",height : "35px"}}
                                onChange={onChangeForm}
                                value={dataItem.NextNumber}
                            />
                            </div>
                            <div className='col-6'>
                            <label>{lang.number_length_label} *</label>
                                <input
                                    className="pl-3"
                                    name={"NumberLength"}
                                    type = "number" 
                                    style={{border : 'solid lightgrey 1px' ,width:"100%", height : "35px"}}
                                    onChange={onChangeForm}
                                    value={dataItem.NumberLength}
                                /> 
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className="col-6">
                            <Field
                                name={"Archive"}
                                component={Checkbox}
                                label={lang.archive}
                                onChange={onChangeForm}
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </FormElement>
            )}
        />
        </Dialog>
        </>
      )
}
export default InvoiceContentEditForm;