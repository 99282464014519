/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "../../locale/useTranslation";
import DynamicDropdown from "../../framework/forms/helpercomponents/dropdown/DynamicDropdown";

function SeasonMetaDataEditForm(props) {

  const [dataItem, setDataItem] = useState(props?.data ?? {});
  const [genre, setGenre] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [pgRatings, setPGRatings] = useState([])
  const lang = useTranslation();

  useEffect(() => {
    loadcombo();
  }, []);

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    props.setMataData({ ...dataItem, [e.target.name]: e.target.value });

  }

  const loadcombo = async () => {
    let genre = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
    setGenre(genre.data);
    let languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
    setLanguages(languages.data);
    let pgRes = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
    setPGRatings(pgRes.data);
  }

  return (
    <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "5px 10px 10px 10px" }}>
      <div className="row">
        <div className="col-12">
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <div className="row mt-2">
                  <div className="marlr">
                  </div>
                </div>
                <div className="mt-2" style={{ border: "groove 1px" }}>
                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-12 mt-2">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.genre_label}</label>
                            <MultiSelect
                              data={genre}
                              textField="Description"
                              value={dataItem.Genres}
                              onChange={onChange}
                              name={"Genres"}
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.language_label}</label>
                            <MultiSelect
                              data={languages}
                              textField="Description"
                              value={dataItem.Languages}
                              onChange={onChange}
                              name={"Languages"}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.pg_rating_label}</label><br />
                            {/* old */}
                            {/* <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={pgRatings}
                              name="PGRating"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.PGRating}
                              onChange={onChange}
                            /> */}
                            {/* new */}

                            <DynamicDropdown
                              // data={pgRatings}
                              name="PGRating"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.PGRating}
                              onChange={onChange}
                              enityname={ENTITYNAME.PGRating}
                            // defaultValue={dataItem.PGRating}
                            // onChange={(value) => {
                            //   setDataItem({ ...dataItem, PGRating: value })
                            //   props.setMataData({ ...dataItem, PGRating: value });
                            // }}
                            />
                          </div>
                        </div>


                        <div className='col-6'>
                          <div className="form-group">
                            <label htmlFor="">{lang.keywords_label}</label>
                            <input name="Keywords" type="text" className="form-control form-control-sm" value={dataItem.Keywords} onChange={onChange} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )} />
        </div>

      </div>
    </div>
  )
}

export default SeasonMetaDataEditForm;