export default (item) => (
    <a className="flex align-items-center p-menuitem-link" style={{
        // disable
        pointerEvents: item?.disabled ? "none" : "auto",
    }} title={item?.title} onClick={item.command}>
        <span style={{ color: item?.disabled ? 'grey' : item?.iconColor ?? "black" }} className={item.icon + ' pr-1'} />
        <span style={{ color: item?.disabled ? "grey" : item?.color ?? "black", ...item?.style }} className="mx-0">{item.label}</span>
        {item.shortcut && <span style={{
            color: "grey", fontWeight: "bold", fontSize: "0.8em", letterSpacing: "1px"
        }} className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
    </a>
);