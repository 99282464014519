/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api"
import { ENTITYNAME } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";


export const ContentEditForm = (props) => {

  const [mediaCategoryType, setMediaCategoryType] = useState([]);
  const lang = useTranslation();

  const blankDataItem = {
    SID : props.item.SID ?? 0,
    MediaCategoryType: props.item.MediaCategoryType ?? {},
    Description: props.item.copy ? 'copy of ' + props.item.Description : props.item.Description ?? '',
    NextNumber: props.item.NextNumber ?? 0,
    Suffix: props.item.Suffix ?? '',
    Prefix: props.item.Prefix ?? '',
    Length: props.item.Length ?? 0,
    Archive: props.item.Archive ?? false,
    IsDefault: props.item.IsDefault ?? false,
  }

  const [dataItem, setDataItem] = useState(blankDataItem);


  useEffect(() => {
    loadcombo();
  }, [])


  const loadcombo = async () => {

    var mediaCategoryTypeRes = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 } });
    let filtered = mediaCategoryTypeRes.data
    if(props.filterMediaCategory){
      filtered = mediaCategoryTypeRes.data.filter((x) => x.MediaCategorySID == props.filterMediaCategory)
    }
    setMediaCategoryType(filtered);
  }

  //function to check the validation in form
  function validationCheck() {

    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.MediaCategoryType).length == 0) {
      toast.error(`${lang.please_select_media_category_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Prefix == "" || dataItem.Prefix == undefined) {
      toast.error(`${lang.please_enter_prefix_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Suffix == "" || dataItem.Suffix == undefined) {
      toast.error(`${lang.please_enter_suffix_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.NextNumber <= 0 ||dataItem.NextNumber == "" || dataItem.NextNumber == undefined) {
      toast.error(`${lang.please_enter_next_number_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Length <= 0 ||dataItem.Length == "" || dataItem.Length == undefined) {
      toast.error(`${lang.please_enter_number_length_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {

    if (validationCheck()) {
      const saveData = {
        SID: dataItem.SID ?? 0,
        MediaCategoryType: {
          _id : dataItem.MediaCategoryType._id ,
          SID : dataItem.MediaCategoryType.SID,
          Description : dataItem.MediaCategoryType.Description,
          MediaCategorySID : dataItem.MediaCategoryType.MediaCategorySID
        },
        Description: dataItem.Description,
        Suffix: dataItem.Suffix,
        Prefix: dataItem.Prefix,
        NextNumber: parseInt(dataItem.NextNumber),
        Length:  parseInt(dataItem.Length),
        Archive: dataItem.Archive ?? false,
        IsDefault: dataItem.IsDefault ?? false,
      }


      console.log(saveData);

      let res = await API.saveData(ENTITYNAME.Content, saveData);
      if(res.success){
          props.cancelEdit();
          props.refresh();
          utility.deleteLocalStorageItem(ENTITYNAME.Content);
          return;
      }else{
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  const onChangeForm = (e) => {

    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else if (e.target.name == "IsDefault") {
      setDataItem({ ...dataItem, IsDefault: !dataItem.IsDefault });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

  }

  return (
      <Dialog
        title={props.item.SID > 0 ? props.item.Description : "Create Content"}
        onClose={props.cancelEdit}
        width={"770px"}
      >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
        <Form
          initialValues={dataItem}
          render={(formRenderProps) => (
            <FormElement>
              <div className="row mt-2">
                <div className="col-12">

                  <div className="row">
                    <div className="col-6">
                      <label>{lang.description_label} *</label>
                      <input
                        name={"Description"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChangeForm}
                        value={dataItem.Description}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.media_category_type_label} *</label>
                      <DropDownList
                        style={{ backgroundColor: 'white' }}
                        name={"MediaCategoryType"}
                        textField={"Description"}
                        value={dataItem.MediaCategoryType}
                        data={mediaCategoryType}
                        onChange={onChangeForm}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-6">
                      <label>{lang.prefix_label} *</label>
                      <input
                        name={"Prefix"}
                        type="text"
                        className="form-control form-control-sm"
                        value={dataItem.Prefix}
                        onChange={onChangeForm}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.suffix_label} *</label>
                      <input
                        name={"Suffix"}
                        type="text"
                        className="form-control form-control-sm"
                        value={dataItem.Suffix}
                        onChange={onChangeForm}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6" >
                      <label>{lang.next_number_label} *</label>
                      <input
                        name="NextNumber"
                        className="form-control form-control-sm"
                        value={dataItem.NextNumber}
                        type="number"
                        onChange={onChangeForm}
                        min={0}
                      />
                    </div>
                    <div className="col-6" >
                      <label>{lang.number_length_label} *</label>
                      <input
                        name="Length"
                        className="form-control form-control-sm"
                        value={dataItem.Length}
                        type="number"
                        onChange={onChangeForm}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="row mt-2 mb-1">
                    <div className="col-2" >
                      <input type={"checkbox"} name={"Archive"} style={{ marginTop: "10px" }}
                        onChange={(e) => onChangeForm(e)}
                        value={dataItem.Archive}
                        checked={dataItem.Archive} />
                      <label className="ml-1">{lang.archive}</label>
                    </div>
                    <div className="col-2" >
                      <input type={"checkbox"} name={"IsDefault"} style={{ marginTop: "10px" }}
                        onChange={(e) => onChangeForm(e)}
                        value={dataItem.IsDefault}
                        checked={dataItem.IsDefault} />
                      <label className="ml-1">{lang.is_default_label}</label>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </Dialog>)
};