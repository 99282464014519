import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import * as API from '../../framework/API/api'
import { utility } from "../../framework/utility/utilityProvider";
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, LOGIN_WARNING_MESSAGE, MODULE, PRODUCTS, ROLETYPE } from "../../framework/constant/constant";
import { PlanningHelper } from "../Planning/helper/PlanningHelper";
import { ConfirmAlertOk } from "../../ConfirmAlert";
import { useTranslation } from "../../locale/useTranslation";

const Login = () => {
    const navigate = useNavigate();
    const lang = useTranslation();
    const [showError, setShowError] = useState(false);
    const [version, setVersion] = useState('');
    const productsEnumArray = Object.keys(PRODUCTS).map((x) => PRODUCTS[x])
    const companyRef = useRef({});
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');

    useEffect(() => {
        utility.clearLocalStorage();
        loadAppVersion();
        loadCompanyData();
    }, [])

    const loadCompanyData = async () => {
        let company = await API.getApplicationConfiguration();
        console.log(company)
        if (company.success) {
            let lang = company.data?.Language;
            utility.setValue(LOCALSTORAGE_KEY.USER_LANGUAGE, lang);
            utility.setValue(LOCALSTORAGE_KEY.COMPANY, company.data);
            companyRef.current = company.data
            // setting first session timeout
            let sessiontimeout = (new Date()).getTime() + (company.data.SessionTimeOut ?? 3600000);
            utility.setValue(LOCALSTORAGE_KEY.sessiontimeout, sessiontimeout);
        }
    }

    const setApplicationConfiguration = async () => {
        let applicationConfiguration = await API.getEntitiesWithSearch(ENTITYNAME.ApplicationConfiguration);
        if (applicationConfiguration.success) {
            utility.setValue(LOCALSTORAGE_KEY.applicationConfiguration, applicationConfiguration.data);
        } else {
            console.log("Application Configuration not set ");
            console.log(applicationConfiguration.message);
        }
    }

    const loadAppVersion = async () => {

        let version = utility.getValue(LOCALSTORAGE_KEY.applicationVersion);
        if (version != null) {
            setVersion(version);
            return;
        }

        let versionRes = await API.getAppVersion();
        if (versionRes.success && Object.keys(versionRes.data).length > 0) {
            setVersion(versionRes.data.Version);
        }
    }

    const handleLoginClick = async (e) => {
        e.preventDefault();

        if (!navigator.onLine) {
            // Show an alert or take appropriate action when there is no internet connection
            ConfirmAlertOk(
                () => { },
                lang.warning_label,
                lang.no_internet_connection_please_check_your_connection_and_try_again_label,
            )
            return;
        }

        const username = e.target.username.value;
        const password = e.target.password.value;

        let response = await API.login(username, password);

        if (response.success) {

            if (!companyRef.current || !companyRef.current.Products || companyRef.current.Products.length == 0 || !productsEnumArray.some((x) => companyRef.current.Products.includes(x))) {
                ConfirmAlertOk(
                    () => { },
                    lang.warning_label,
                    lang.there_is_no_product_assigned_to_your_company_kindly_contact_team_BMS_label,
                )
                return;
            }

            if (response.data.Module.length == 0 && (response.data?.RoleType?.ID ?? ROLETYPE.User) != ROLETYPE.SuperAdmin) {
                ConfirmAlertOk(
                    () => { },
                    lang.warning_label,
                    lang.you_dont_have_any_module_access_please_contact_your_admin_label,
                )
                return;
            }

            let userData = {
                _id: response.data._id,
                SID: response.data.SID,
                name: response.data.name,
                Email: response.data.Email,
                username: response.data.username,
            }

            utility.setValue(LOCALSTORAGE_KEY.token, response.token);
            utility.setAccessToken(response.token);
            utility.setValue(LOCALSTORAGE_KEY.userData, { ...response.data, color: utility.getRandomColor() })
            utility.setValue(LOCALSTORAGE_KEY.user_id, response.data._id)
            await PlanningHelper.loadPlanningPrefrence();
            await setApplicationConfiguration();
            utility.setValue(LOCALSTORAGE_KEY.applicationVersion, version);
            let logData = { event: LOGEVENT.LOG_IN, module: MODULE.LOGIN, data: userData, message: LOGEVENT.LOG_IN };
            API.SaveLogs(logData);
            setShowError(false)

            let allModules = await API.getDataLookup(ENTITYNAME.Module);
            if (allModules.success) {
                utility.setValue(LOCALSTORAGE_KEY.ALLMODULES, allModules?.data ?? []);
            }

            // deleting user activity log on successful login
            API.deleteLogHistory();

            navigate('/home')


        } else {
            setShowError(true)
        }

    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return <div className="container">
        <div className="panel panel-default" style={{ border: 'solid 2px gray', borderRadius: 5, position: 'fixed', left: '47%', top: '40%', backgroundColor: 'white', zIndex: 100, marginTop: '-200px', width: 400, marginLeft: '-200px' }}>
            <div className="panel-heading">
                <div className="panel-title text-center "><span> <img style={{ height: "5rem" }} src={require("../../img/BossStudioLogo.png")} alt="SmartAdmin" /> </span></div>
            </div>

            <div className="panel-body">
                <div className="well no-padding">
                    <form name="form" className="smart-form client-form" onSubmit={handleLoginClick}>
                        <header>
                            {lang.sign_in_label}
                        </header>
                        <fieldset>
                            <section>
                                <label className="label">{lang.username_login_label}</label>
                                <label className="input">
                                    <i className="icon-append fa fa-user" />
                                    {/*<input type="text" name="email">*/}
                                    <input placeholder="BMS or abc@gmail.com" type="text" name="username" id="username" className="form-control" ng-model="username" required tabIndex={1} />
                                    <b className="tooltip tooltip-top-right"><i className="fa fa-user txt-color-teal" /> {lang.please_enter_user_name_login_label}</b>
                                </label>
                            </section>
                            <section>
                                <label className="label">{lang.password_login_label}</label>
                                <label className="input">
                                    <i className="icon-append fa fa-lock" />
                                    <i style={{ marginRight: '10%', borderLeftWidth: 'none', borderLeftStyle: 'none'}} className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}  onClick={(e)=>{ e.preventDefault(); handleTogglePassword();}}/>
                                    <input
                                        placeholder="********"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        id="password"
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{paddingRight: '70px'}}
                                        required
                                        tabIndex={2}
                                    />
                                    <b className="tooltip tooltip-top-right">
                                        <i className="fa fa-lock txt-color-teal" />
                                        {lang.enter_your_password_login_label}
                                    </b>
                                </label>
                            </section>

                        </fieldset>
                        <footer>
                            {showError && <div ng-show="error" className="alert alert-danger">
                                <a href="# " className="close" data-dismiss="alert"><span></span></a>{LOGIN_WARNING_MESSAGE}
                            </div>}
                            <span className="float-left mt-4">{version}</span>
                            <button type="submit" ng-disabled="form.$invalid || dataloading" className="btn btn-danger" ng-click="login()">{lang.login_lable}</button>
                            {/* <img ng-if="dataLoading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> */}
                            {/* <button type="submit" class="btn btn-primary">
                        Sign in
                    </button> */}
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    </div>

}

export default Login