/* eslint-disable */
import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { toast } from "react-toastify";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { ENTITYNAME } from "../../framework/constant/constant";

const ImportFaqFile = (props) => {

  const [dataItem, setDataItem] = useState({
    fileupload: "",
  });
  const lang = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [file, setFile] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(`${lang.select_file_label}`);
  const fileRef = useRef(null);
  const [showLoading, setShowLoading] = useState(false);

  const onChange = (e) => {
    if (e.target.name == "fileupload") {
      setFile(e.target.files[0]);
      setSelectedFile(`${lang.file_selected_label}`);
      setDataItem({ ...dataItem, File: e.target.files[0].name });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const onLoad = async () => {
    if (fileRef.current.files.length == 0) {
      toast.error(`${lang.please_select_file_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var extCheck = fileRef.current.files[0].name.split('.').pop().toLowerCase();

    if(extCheck != "xlsx" && extCheck != "csv" && extCheck != "xls"){
      toast.error(`${lang.invalid_file_format_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setShowLoading(true);
    var res = await API.readFile(fileRef.current.files[0]);

    console.log(res);
    if(res.success){
      const temp = [];
      res.data.data.forEach((rowData) => {
          const obj = {
            FaqCategory: rowData[0],
            Question: rowData[1],
            Answer: rowData[2],
          };
          temp.push(obj);
      });
  
      console.log(temp);
      setGridData(temp);
      setShowLoading(false);
    }else{
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowLoading(false);
      return;
    }

  }

  const onImport = async () => {

    if (gridData == undefined || gridData.length == 0) {
      toast.error(`${lang.please_load_first_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var res = await API.saveFAQ(gridData);
    console.log(res);
    if(res.success){
      toast.info(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      props.onClose();
      props.refresh();
      utility.deleteLocalStorageItem(ENTITYNAME.Faq)
      utility.deleteLocalStorageItem(ENTITYNAME.FaqCategory)
    }else{
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }


  };

  return (
    <Dialog
    title={lang.import_file_label}
    onClose={props.onClose}
    width="60vw"
  >
    <div className="flex-container">
      <div className="martb mar">
            <input
              name="File"
              type="text"
              style={{width: "150px"}}
              className="form-control form-control-sm"
              value={dataItem.File}
              onChange={onChange}
              disabled={true}
            />
          <label
            className="custom-file-updated-label ml-2"
            style={{ margin: "0px", height: "26.5px" }}
          >
            <input
              ref={fileRef}
              type="file"
              name="fileupload"
              className="custom-file-updated-input"
              hidden="hidden"
              id="fileupload"
              onChange={onChange}
            />
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </label>
          <ActionButton
            name={lang.load_button_text}
            style={{ padding: "5px 12px" }}
            onClick={onLoad}
          />
          <ActionButton
            name={lang.import_file_label}
            style={{ padding: "5px 12px" }}
            onClick={onImport}
            btnColor={"warning"}
          />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        {!showLoading ? <Grid data={gridData} style={{ height: "46vh" }}>
          <GridColumn field="FaqCategory" title={lang.faq_type_column} />
          <GridColumn field="Question" title={lang.question_column} />
          <GridColumn field="Answer" title={lang.answer_column} />
        </Grid>
          :
          <div className="ml-1 mt-2" style={{ height: "46vh" }}>
            <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
          </div>}
      </div>
    </div>
    </Dialog>
  );
};

export default ImportFaqFile;