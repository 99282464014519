import React, { useEffect, useRef, useState } from "react";
import { ENTITYNAME, FONT_WEIGHT, LOGEVENT, LOGOPOSITION, MODULE, OVERLAYTYPE, POSITION, SHAPETYPE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ImageBrowser } from "../../framework/forms/helpercomponents/selectFile/ImageBrowser";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import Preview from "../platformLayout/tv/homePage/components/Preview";
import { Field } from "@progress/kendo-react-form";

const OverlayEditForm = (props) => {

  const { SID } = useParams();
  const loc = useLocation();
  const lang = useTranslation();
  const navigate = useNavigate();
  const [fontWeights, setFontWeights] = useState([])
  const [fontFamilys, setFontFamilys] = useState([])
  const [imageSelected, setImageSelected] = useState(false);
  const [imageFiles, setImageFiles] = useState(new Set());
  const [previewData, setPreviewData] = useState([])

  const [tabNumber, setTabNumber] = React.useState("0");
  const [dataItem, setDataItem] = useState({
    SID: SID,
    OverlayType: {},
    ImageUrl: '',
    Shape: {},
    ShapeHeight: 100,
    ShapeWidth: 100,
    BackgroundColor: '',
    BorderRadius: '',
    Text: '',
    TextMargin: '',
    Position: {},
    FontSize: 0,
    FontWeight: {},
    FontFamily: {},
    FontColor: '#F7A227',
    Archive: false
  })

  useEffect(() => {
    pageRender();
  }, []);

  const pageRender = async () => {
    await loadcombo();
    if (SID > 0) {
      await loadEditData();
    }
  }

  const loadcombo = async () => {
    let fontWeightRes = Object.keys(FONT_WEIGHT).map(x => {
      return { _id: FONT_WEIGHT[x], Description: x }
    })
    setFontWeights(fontWeightRes)
    console.log(fontWeightRes)
    let fontFamilyRes = await API.getDataLookup(ENTITYNAME.FontFamily);
    setFontFamilys(fontFamilyRes.data, fontFamilyRes.data);

    if (SID > 0) {
      loadEditData(fontWeightRes);
    }

  }

  const loadEditData = async (fontWeightRes) => {
    console.log(fontWeightRes)
    const res = await API.getEntity(ENTITYNAME.DigitalSignOverlay, parseInt(SID));
    console.log(res.data)
    if (res.success) {
      setDataItem({
        ...dataItem,
        ...res.data,
        //FontWeight: fontWeightRes.find(X => X._id == res.data.FontWeight) ?? { _id: 4, Description: "w400" }
      })
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else if (e.target.name == 'ImageUrl' && e.target?.files) {
      if (e.target.files != undefined) {
        setImageFiles(e.target.files[0]);
      } else {
        setImageFiles(new Set());
      }
    }
    else if (e.target.name == "FontSize") {
      setDataItem({ ...dataItem, FontSize: e.target.value });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

  }

  const isValid = () => {

    if (dataItem.Shape == undefined || dataItem.Shape == "") {
      toast.error(`${lang.please_enter_shape_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ShapeHeight > 100 || dataItem.ShapeH < 1) {
      toast.error(`${lang.width_percentage_should_be_greater_than_zero_and_less_than_hundred_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ShapeWidth > 100 || dataItem.ShapeWidth < 1) {
      toast.error(`${lang.width_percentage_should_be_greater_than_zero_and_less_than_hundred_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true
  }

  const handleSubmit = async () => {

    if (isValid()) {
      let imageUrl = dataItem.ImageUrl;
      if (imageFiles.size > 0) {
        let resUrl = await API.upladImage(imageFiles);
        if (!resUrl.success) {
          toast.error(resUrl.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        }
        imageUrl = resUrl.data;
        dataItem.ImageUrl = imageUrl;
      }
      let localData = {
        ...dataItem,
        SID: loc.state?.copy ? 0 : parseInt(dataItem.SID),
        OverlayType: dataItem.OverlayType,
        ImageUrl: imageUrl,
        Shape: dataItem.Shape,
        ShapeHeight: dataItem.ShapeHeight,
        ShapeWidth: dataItem.ShapeWidth,
        BackgroundColor: dataItem.BackgroundColor,
        BorderRadius: dataItem.BorderRadius,
        Text: dataItem.Text,
        TextMargin: dataItem.TextMargin,
        Position: dataItem.Position,
        FontSize: dataItem.FontSize,
        FontWeight: dataItem.FontWeight,
        FontFamily: dataItem.FontFamily,
        FontColor: dataItem.FontColor,
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Shape", "=", dataItem.Shape]]
      }
      console.log(localData);
      let res = await API.saveData(ENTITYNAME.DigitalSignOverlay, localData);

      console.log(res);
      if (res.success) {
        navigate(-1);
        utility.deleteLocalStorageItem(ENTITYNAME.DigitalSignOverlay);
        return;
      }
      else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  const handleLogoImageSelect = (dataList) => {
    setDataItem({ ...dataItem, ImageUrl: dataList[0].ImageUrl });
  }


  const refreshTheme = () => {

    if (isValid()) {
      let preview = {
        ...dataItem,
        OverlayType: dataItem.OverlayType,
        ImageUrl: dataItem.ImageUrl,
        Shape: dataItem.Shape,
        ShapeHeight: dataItem.ShapeHeight,
        ShapeWidth: dataItem.ShapeWidth,
        BackgroundColor: dataItem.BackgroundColor,
        BorderRadius: dataItem.BorderRadius,
        Text: dataItem.Text,
        TextMargin: dataItem.TextMargin,
        Position: dataItem.Position,
        FontSize: dataItem.FontSize ?? 0,
        FontWeight: dataItem.FontWeight,
        FontFamily: dataItem.FontFamily,
        FontColor: dataItem.FontColor,
        Archive: dataItem.Archive ?? false
      }
      setPreviewData(preview)
      console.log(previewData)
    }
  }


  return (<>
    <div className="row m-1" >
      <EditPageHeader title={SID > 0 ? `${lang.digital_sign_board_overlay_editpage_header}  -> ` + ' ' + dataItem.Shape.Description : `${lang.digital_sign_board_overlay_editpage_header}  -> ` + ' ' + 'New'} onSubmit={handleSubmit} onCancel={() => navigate(-1)} />
      <div className="col-6 mb-2 ">
        <div className="row mt-2">
          <div className="col-6">
            <label >{lang.overlay_type_label}</label>
          </div>
          <div className="col">
            <DropDownList
              style={{ backgroundColor: "white" }}
              data={Object.keys(OVERLAYTYPE).map((x) => {
                return {
                  _id: OVERLAYTYPE[x], Description: x
                }
              })}
              name="OverlayType"
              textField="Description"
              dataItemKey="_id"
              value={dataItem.OverlayType}
              onChange={onChange}
            />

          </div>
        </div>
        {dataItem.OverlayType.Description == "Image" && <div className="row mt-2">
          <div className="col-6">
            <label>{lang.image_url_label} *</label>
          </div>
          <div className="col-6">
            <ImageBrowser name={'ImageUrl'} value={dataItem.ImageUrl} onChange={onchange} setImageSelected={setImageSelected} hidePosterLibrary={true} setSelectedImage={handleLogoImageSelect} />
          </div>
        </div>}
        {dataItem.OverlayType.Description == "Shape" && <div className="row mt-2">
          <div className="col-6">
            <label >{lang.shape_label}</label>
          </div>
          <div className="col-6">
            <DropDownList
              style={{ backgroundColor: "white" }}
              data={Object.keys(SHAPETYPE).map((x) => {
                return {
                  _id: SHAPETYPE[x], Description: x
                }
              })}
              name="Shape"
              textField="Description"
              dataItemKey="_id"
              value={dataItem.Shape}
              onChange={onChange}
            />

          </div>
        </div>}
        {dataItem.OverlayType.Description == "Shape" && <div className="row mt-2">
          <div className="col-6">
            <label >{lang.height_label}</label>
          </div>
          <div className="col-5">
            <input
              type="number"
              name="ShapeHeight"
              className="form-control form-control-sm"
              value={dataItem.ShapeHeight}
              onChange={onChange}
            />
          </div>
          <div>
            <label><b>%</b></label>
          </div>
        </div>}
        {dataItem.OverlayType.Description == "Shape" && <div className="row mt-2">
          <div className="col-6">
            <label >{lang.width_label}</label>
          </div>
          <div className="col-5">
            <input
              type="number"
              name="ShapeWidth"
              className="form-control form-control-sm"
              value={dataItem.ShapeWidth}
              onChange={onChange}
            />
          </div>
          <div>
            <label><b>%</b></label>
          </div>
        </div>}
        {dataItem.OverlayType.Description == "Shape" && <div className="row mt-2">
          <div className="col-6">
            <label >{lang.background_color_label}</label>
          </div>
          <div className="col">
            <input
              type="color"
              name="BackgroundColor"
              className="cursor-pointer"
              value={dataItem.BackgroundColor}
              onChange={onChange}
            />
            <span className="ml-2">{dataItem?.FontColor}</span>
          </div>
        </div>}
        {dataItem.OverlayType.Description == "Shape" && <div className="row mt-2">
          <div className="col-6">
            <label >{lang.border_radius_label}</label>
          </div>
          <div className="col">
            <input
              type="number"
              name="BorderRadius"
              className="form-control form-control-sm"
              value={dataItem.BorderRadius}
              onChange={onChange}
            />
          </div>
        </div>}

        <div className="row mt-2">
          <div className="col-6">
            <label >{lang.text_label}</label>
          </div>
          <div className="col-6">
            <textarea
              name={"Text"}
              type="text"
              className="form-control form-control-sm"
              onChange={onChange}
              value={dataItem.Text}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <label >{lang.margin_lrtb_text_label}</label>
          </div>
          <div className="col-6">
            <input
              name={"TextMargin"}
              type="text"
              className="form-control form-control-sm"
              onChange={onChange}
              value={dataItem.TextMargin}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6"><label>{lang.position_label} *</label></div>
          <div className="col-6">
            <DropDownList
              style={{ backgroundColor: "white" }}
              data={Object.keys(POSITION).map((x) => {
                return {
                  _id: POSITION[x], Description: x
                }
              })}
              name="Position"
              textField="Description"
              dataItemKey="_id"
              value={dataItem.Position}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6"><label>{lang.font_size_label} *</label></div>
          <div className="col-6">
            <input
              type="number"
              name="FontSize"
              className="form-control form-control-sm"
              value={dataItem.FontSize}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6"><label>{lang.font_weights_label} *</label></div>
          <div className="col-6">
            <DropDownList
              data={fontWeights}
              name="FontWeight"
              style={{ backgroundColor: "white" }}
              textField='Description'
              dataItemKey='_id'
              value={dataItem.FontWeight}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6"><label>{lang.font_family_label} *</label></div>
          <div className="col-6">
            <DropDownList
              data={fontFamilys}
              name="FontFamily"
              style={{ backgroundColor: "white" }}
              textField='Description'
              dataItemKey='_id'
              value={dataItem.FontFamily}
              onChange={onChange}
            />
          </div>

        </div>
        <div className="row mt-2">
          <div className="col-6">
            <label >{lang.font_color_label}</label>
          </div>
          <div className="col">
            <input
              type="color"
              name="FontColor"
              className="cursor-pointer"
              value={dataItem.FontColor}
              onChange={onChange}
            />
            <span className="ml-2">{dataItem?.FontColor}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <label >{lang.archived_label}</label>
          </div>
          <div className="col">
            <input
              type="checkbox"
              name="Archive"
              value={dataItem.Archive}
              checked={dataItem.Archive}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      {/* <div className="col-1" style={{ marginTop: '60px' }}>
        {dataItem.OverlayType.Description == "Image" && <img src={dataItem?.ImageUrl} width="150px" />}
      </div> */}
      <div className="col-4 mt-4">
        <div className="row"><span className="col-1">
          <i title={lang.refresh_theme_label} className="fa fa-arrows-rotate img-circle cursor-pointer" onClick={refreshTheme}></i>
        </span> </div>
        <div className="preview-container" style={{
          BackgroundColor: "#000", border: "15px solid #333", borderRadius: "20Px",
          boxShadow: "0 0 30px rgba(0, 0, 0, 0.8)", overflow: "hidden", width: "600px", height: "400px", position: "relative"
        }}>
          {dataItem.OverlayType.Description == "Image" && <div style={{
            textAlign: previewData.Position?.Description == "TopLeft" || previewData.Position?.Description == "CenterLeft" || previewData.Position?.Description == "BottomLeft" ? "left" : previewData.Position?.Description == "TopCenter" || previewData.Position?.Description == "BottomCenter" ? "center" : "right",
            marginTop: previewData.Position?.Description == "CenterLeft" || previewData.Position?.Description == "CenterRight" ? "150Px" : previewData.Position?.Description == "BottomLeft" || previewData.Position?.Description == "BottomCenter" || previewData.Position?.Description == "BottomRight" ? "330px" : "0px"
          }}>
            <img src={dataItem?.ImageUrl} width="120px" />
          </div>}
          {dataItem.Shape.Description == "Rectangle" && dataItem.OverlayType.Description == "Shape" && <div style={{ marginLeft: ((100 - previewData.ShapeWidth) / 2) + "%", border: "2px solid blue", borderRadius: previewData.BorderRadius + "px", color: "black", height: previewData?.ShapeHeight + "%", width: previewData?.ShapeWidth + "%", backgroundColor: dataItem.BackgroundColor }}>
            <div className="" style={{ margin: previewData?.TextMargin, fontSize: previewData.FontSize + "px", color: previewData.FontColor, fontWeight: previewData.fontWeight?.Description, fontFamily: previewData.FontFamily?.Description }}>{previewData.Text}</div>
          </div>}
          {dataItem.Shape.Description == "Square" && dataItem.OverlayType.Description == "Shape" && <div style={{ marginLeft: ((100 - previewData.ShapeWidth) / 2) + "%", border: "2px solid blue", borderRadius: previewData.BorderRadius + "px", color: "black", height: previewData?.ShapeHeight + "%", width: previewData?.ShapeWidth + "%", backgroundColor: previewData?.BackgroundColor }}>

            <div className="" style={{ margin: previewData?.TextMargin, fontSize: previewData?.FontSize + "px", color: previewData.FontColor, fontWeight: previewData.fontWeight?.Description, fontFamily: previewData.FontFamily?.Description }}>{previewData.Text}</div>
          </div>
          }
        </div>
      </div>
    </div >
  </>)
}
export default OverlayEditForm