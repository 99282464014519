import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useEffect, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import { useTranslation } from '../../locale/useTranslation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME } from '../../framework/constant/constant';
import * as API from '../../framework/API/api'
import { toast } from 'react-toastify';

const MediaFastSegmentationPopup = (props) => {

    // console.log(props.mediaEpisodeEntity)
    // console.log(props.programScheduleEntity)

    const lang = useTranslation();
    const [txMode, setTxMode] = useState([]);
    const [dataItem, setDataItem] = useState({
        TXMode: {},
        Duration: '',
        MediaEpisode: props.mediaEpisodeEntity
    })

    useEffect(() => {
        loadcombo()
    },[])

    const loadcombo = async () => {
        let txmodeRes = await API.getDataLookup(ENTITYNAME.TXMode, {sort: { Name: 1 }});
        setTxMode(txmodeRes.data);  
        setDataItem({ ...dataItem,TXMode : txmodeRes.data.find((x) => x.SID == 1)})
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
    
        if(dataItem.Duration==undefined || dataItem.Duration.trim() == ""){
          toast.error(`${lang.please_enter_duration}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
    
        let durationSumInMilliSec = dataItem.Duration.split(',').reduce((acc, o) => acc + parseInt(o), 0) * 60000;
        if(durationSumInMilliSec > props.mediaEpisodeEntity.Duration){
            toast.error(`${lang.sum_of_entered_duration_should_be_less_then_or_equal_to_media_duration}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
        if(!dataItem.TXMode || Object.keys(dataItem.TXMode).length==0){    
          toast.error(`${lang.please_select_tx_mode_segment_type_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });                        
          return false;
        }
        
        return true;
    
      }

    const onCreateSegment = async() => {

        if(!isValid()) return;

        let payload = {
            txmode: dataItem.TXMode,
            duration: dataItem.Duration.split(','),
            mediaEpisodeId: dataItem.MediaEpisode._id,
            source : props.source,
            parentProgramSchedule: props.programScheduleEntity
        }

        let response = await API.createFastSegments(payload);
        if(response.success){
            props.refreshSegments()
            props.onClose()
            setDataItem({ 
                TXMode: {},
                Duration: '',
                MediaEpisode: props.mediaEpisodeEntity 
            })
            console.log(response.data)
        }else{
            // props.onClose()
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            console.log(response)
        }
    }

    const onClose = () => {
        props.onClose();
        setDataItem({ TXMode: {}, Duration: '',  MediaEpisode: props.mediaEpisodeEntity })
    }


    return (
        <>
            <Dialog
                title={`${props.mediaEpisodeEntity.Title} Fast Segmentation`}
                onClose={onClose}
                width={'500px'}
            >
                <EditPageHeader title={""} onSubmit={onCreateSegment} onCancel={onClose} showTitle={false} />
                <div className="row mt-1">
                    <div className="col-6">
                        <label htmlFor="TabView">{'Duration'} *</label>
                        <div className="form-group">
                            <input className="form-control form-control-sm" type="text" name="Duration" onChange={onChange} value={dataItem.Duration} />
                            <p className="text-muted" style={{ fontSize: "9px",margin : '0px' }}>{`Example : 10,12,8 will create segment of 3 of these durations in minutes respectively`}</p>
                        </div>
                    </div>
                    <div className="col-6 form-group">
                        <label htmlFor="TabView">{lang.tx_mode_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={txMode}
                            name="TXMode"
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.TXMode}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </Dialog>
        </>

    )
}

export default MediaFastSegmentationPopup