import 'material-symbols';

export const IconCell = (props) => {
  const field = props.field || "";
  const width = props.width ?? "50px";
  var value = props.dataItem[field];
  value = value.includes('http') ? 'disabled_by_default' : value
  var iconStyle = {fontSize: '30px', width: width}

  return <td>
    <span style={iconStyle} className="material-symbols-outlined ml-2">{value && value}</span>
  </td>;
};